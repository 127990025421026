import { Box, FormControl, FormLabel, Text, useToast } from '@chakra-ui/react';
import { Select, SingleValue } from 'chakra-react-select';
import {
  clearRoleCacheFilters,
  RoleTaxonomySettingOption,
  useRoleTaxonomySetting,
} from '@revelio/filtering';

import { useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';

export const AdminRoleTaxonomyOverrideSelect = () => {
  const [handleSave, setHandleSave] = useState<() => void>(
    () => () => undefined
  );
  const {
    canOverrideValue,
    personalOverrideOptionValue,
    personalOverrideOptions,
    setPersonalOverrideOption,
  } = useRoleTaxonomySetting();
  const [taxonomyValue, setTaxonomyValue] = useState<
    RoleTaxonomySettingOption | undefined
  >(personalOverrideOptionValue);
  const toast = useToast();
  const {
    formState: { isSubmitting },
  } = useFormContext();

  useEffect(() => {
    if (isSubmitting) {
      handleSave();
    }
  }, [handleSave, isSubmitting]);

  const handleOption = (option: SingleValue<RoleTaxonomySettingOption>) => {
    setTaxonomyValue(option as RoleTaxonomySettingOption);
    setHandleSave(() => () => {
      setPersonalOverrideOption(option as RoleTaxonomySettingOption);
      clearRoleCacheFilters();
      toast({
        title: 'Taxonomy set on this browser',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    });
  };

  return (
    canOverrideValue && (
      <FormControl id="custom_role_taxonomy">
        <FormLabel fontSize="sm" fontWeight="semibold">
          Role Taxonomy Admin Override
        </FormLabel>

        <Box>
          <Select
            value={taxonomyValue}
            options={personalOverrideOptions}
            placeholder="Select role taxonomy setting"
            onChange={handleOption}
          />
        </Box>
        <Text fontSize="sm" color="gray.600">
          This setting overrides the taxonomy set in the client group and only
          affects this browser.{' '}
        </Text>
      </FormControl>
    )
  );
};
