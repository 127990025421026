import React, { useEffect, useState, useRef, useMemo } from 'react';
import {
  Flex,
  Button,
  Box,
  Icon,
  Divider,
  useDisclosure,
} from '@chakra-ui/react';
import { SingleValue } from 'chakra-react-select';
import mixpanel from 'mixpanel-browser';
import { difference, get, isEqual } from 'lodash';
import { FiSliders } from 'react-icons/fi';
import { TalentDiscoveryFilterMenu } from '../talent-discovery-filter-menu/talent-discovery-filter-menu';
import { DateRangeValue } from '../date-range/helpers';
import {
  FilterPopover,
  FilterPopoverProps,
} from '../filter-popover/filter-popover';
import { useActiveFiltersV2 } from '../../engine/filters.engine';
import { TourClasses, UserFilterTrackingEvents, Views } from '@revelio/core';
import { getWorkflow } from './workflows.config';
import {
  defaultDateVal,
  defaultOPVal,
  screenerDefaults,
  mapSelectableFilters,
} from './helpers.config';
import {
  HandlerConfig,
  SelectedRef,
  SubmitRefHandle,
  TempEmpTypes,
  TempSelections,
} from './types';
import { getActiveSetId } from '../../engine/filters.storedset';
import {
  FilterTreeItemData,
  KeywordSelections,
  ScreenerRef,
  TDStateConfig,
} from '../collection';
import {
  AnyFilter,
  DateValSelectFilter,
  DefaultDates,
  FilterMenuItemOrConfig,
  ISelectFilter,
  LocalSelectionCategories,
  OPSelectFilter,
  SelectionCategories,
} from '../../engine/filters.model';
import { allFilters } from './filters.config';
import { ViewTypes } from '../../data-api/data-api.model';
import { isSubmitDisabled } from './utils/isSubmitDisabled';
import { FilterTypeSelect } from './filter-type-select/filter-type-select';
import { TreeFilters } from './tree-filters/tree-filters';
import { DateFilters } from './date-filters/date-filters';
import { SkillFilterApi } from '../talent-discovery-filter-api';
import { KeywordFilters, TextFilters } from './keyword-filters/keyword-filters';
import { FilterKeywordSelection } from '../talent-discovery-filter-menu/td-paste-select/TDPasteSelect';
import { useAllowedFilterMenuItems } from '../../engine/filters.hooks';
import {
  NodeModalStateProvider,
  useNodeModalState,
} from './node-modal-state.context';

const PopoverWithContext = ({ children, ...props }: FilterPopoverProps) => {
  const { nodeModalOpen } = useNodeModalState();

  return (
    <FilterPopover {...props} nodeModalOpen={nodeModalOpen}>
      {children}
    </FilterPopover>
  );
};
export interface FilterMenuProps {
  title: string;
  view?: Views;
  filters: FilterMenuItemOrConfig[];
  defaultIsOpen?: boolean;
  /**
   * The composed FilterPopover component uses the useOutsideClick
   * hook to handle closing the menu on outside click when closeOnBlur
   * is false. If closeOnBlur is true, then the handler passed to
   * useOutsideClick is disabled.
   */
  closeOnBlur?: boolean;
  limit?: number;
  triggerElement?: React.ReactNode;
  isSingleFilter?: boolean;
  defaultFilter?: SelectionCategories[] | SelectionCategories;
  resetSelectOnOpen?: boolean;
  selectMenuOpenDefault?: boolean;
  focusInput?: boolean;
  lastMonth?: string;
  expandRoots?: boolean;
  offsetParent?: (SelectionCategories | LocalSelectionCategories)[];
  filtersToDisable?: SelectionCategories[];
  fil?: AnyFilter;
  hideSelect?: boolean;
  executeOnOpen?: () => void;
  endDateDefaultFilterName?: DefaultDates;
  viewIdForDefault?: string;
  // TODO: Okay for now as a quick fix, but need to think of a
  // better way to pass this function down
  fetchSubsidiaries?: any;
  selectListHeight?: number;
  isDisabled?: boolean;
  viewType?: ViewTypes;
  addSkillFilterChip?: SkillFilterApi['addFilterChip'];
  tdFilterChipIndex?: number;
  updateSkillFilterChip?: SkillFilterApi['updateFilterChip'];
  filterTreeItemData?: FilterTreeItemData;
  filterKeywordSelection?: FilterKeywordSelection;
}

export const FILTER_MENU_ID = 'filter-menu-popover';
export function FilterMenu({
  title,
  view = Views.OVERVIEW,
  viewType,
  filters,
  defaultIsOpen,
  /**
   * closeOnBlur is false by default, so outside clicks are being handled
   * with the handler function passed to the useOutsideClick hook
   * within the composed FilterPopover component.
   */
  closeOnBlur = false,
  limit,
  triggerElement,
  defaultFilter,
  resetSelectOnOpen = true,
  selectMenuOpenDefault,
  focusInput,
  lastMonth,
  expandRoots = false,
  offsetParent = [],
  filtersToDisable = [],
  fil,
  executeOnOpen,
  hideSelect = false,
  endDateDefaultFilterName,
  viewIdForDefault,
  fetchSubsidiaries,
  selectListHeight,
  isDisabled,
  addSkillFilterChip,
  tdFilterChipIndex,
  updateSkillFilterChip,
  filterTreeItemData,
  filterKeywordSelection,
}: FilterMenuProps) {
  // const skillFilterApi = talentDiscoveryFilterApi?.skillFilterApi;
  const allowedFilters = useAllowedFilterMenuItems(filters);

  // array of filters for the select menu
  const { mappedFilters: selectableFilters } = mapSelectableFilters(
    allowedFilters,
    filtersToDisable,
    limit
  );

  // active filters state
  const [activeFilters] = useActiveFiltersV2();

  const filterNames = selectableFilters.map((item: any) => item.filterName);

  const isTD = view === Views.TALENT_DISCOVERY;

  const filteredAllFilters = !isTD
    ? allFilters.filter((filterObj) => {
        return !filterObj.filter.some((item: any) =>
          filterNames.includes(item)
        );
      })
    : [];

  type ViewTypeFilters = Partial<{
    [key in ViewTypes]: typeof filteredAllFilters;
  }>;

  const viewTypeFilters: ViewTypeFilters = {
    [ViewTypes.GEO]: filteredAllFilters.filter(
      (filterObj) => filterObj.value !== SelectionCategories.REGION
    ),
    [ViewTypes.ROLE]: filteredAllFilters.filter(
      (filterObj) => filterObj.value !== SelectionCategories.JOB_CATEGORY
    ),
  };

  const finalFiltered = (
    viewTypeFilters[viewType as ViewTypes] || filteredAllFilters
  ).filter((filter) => !filter.isDisabled);

  const groupedOptions = [
    ...selectableFilters,
    ...(finalFiltered.length
      ? [
          {
            label: <Divider />,
            options: finalFiltered,
          },
        ]
      : []),
  ] as ReturnType<typeof mapSelectableFilters>['mappedFilters'];

  // control for category filter select
  const [selectValue, setSelectValue] = useState<
    ISelectFilter | null | undefined
  >(undefined);

  // state for determining submit button text
  const [submitButtonText, setSubmitButtonText] = useState<'Add' | 'Update'>(
    'Add'
  );

  // control for currently selected options
  const [tempSelections, setTempSelections] = useState<TempSelections>({});

  const [keywords, setKeywords] = useState<KeywordSelections>({});

  // control for date range
  const [dateRangeValue, setDateRangeValue] = useState<DateRangeValue>();
  const [dateRangeFullValue, setDateRangeFullValue] =
    useState<DateRangeValue>();

  // control for snapshot date
  const [selectedSnapshotDate, setSelectedSnapshotDate] = useState<Date>();

  // control for operator select field
  const [opValue, setOpValue] = useState<OPSelectFilter>(defaultOPVal);

  // control for screener number range input
  const [startValue, setStartValue] = useState<number | undefined>(undefined);
  const [endValue, setEndValue] = useState<number | undefined>(undefined);
  const [dateRangeError, setDateRangeError] = useState('');

  const [dateValue, setDateValue] =
    useState<DateValSelectFilter>(defaultDateVal);

  const [companyValue, setCompanyValue] = useState<string[]>([]);

  // control for screener employee type selections
  const [tempEmpTypes, setTempEmpTypes] = useState<TempEmpTypes>({});

  // ref to access inner functions of Tree
  const submitRef = useRef<SubmitRefHandle>({});

  const [dateToggle, setDateToggle] = useState<boolean>(false);

  const [employeeToggle, setEmployeeToggle] = useState<boolean>(false);

  // update menu based on select choice

  const initialFocusRef = useRef(null);

  const [TDState, setTDState] = useState<TDStateConfig>({});

  const workflowHandlerConfig: HandlerConfig = {
    submitRef,
    selectValue,
    tempEmpTypes,
    activeFilters,
    tempSelections,
    view,
    endDateDefaultFilterName,
    companyValue,
    setCompanyValue,
    opValue,
    setOpValue,
    dateValue,
    setDateValue,
    startValue,
    setStartValue,
    endValue,
    setEndValue,
    dateRangeValue,
    setDateRangeValue,
    dateRangeFullValue,
    setDateRangeFullValue,
    selectedSnapshotDate,
    setSelectedSnapshotDate,
    TDState,
    setTDState,
    fetchSubsidiaries,
    addSkillFilter: addSkillFilterChip,
    tdFilterChipIndex,
    updateSkillFilter: updateSkillFilterChip,
    keywordSelections: keywords,
    setKeywordSelections: setKeywords,
  };
  /**
   * handles filter menu select change
   *
   * @param selected
   *
   * @returns void
   */
  const handleFilterSelectChange = (selected: SingleValue<ISelectFilter>) => {
    setTempSelections({});
    setKeywords({});

    setSubmitDisabled(false);

    if (view === Views.TALENT_DISCOVERY) {
      setTDState({});
    }

    setSelectValue(selected);

    if (selectValue?.filterName === SelectionCategories.DATE_RANGE) {
      setDateRangeValue(undefined);
    } else if (
      selectValue?.filterName === SelectionCategories.DATE_RANGE_FULL
    ) {
      setDateRangeFullValue(undefined);
    } else {
      const selectedRef: SelectedRef | undefined | null =
        selectValue && submitRef.current[selectValue.filterName];

      if (selectedRef && 'handleClearSelections' in selectedRef.value) {
        selectedRef.value.handleClearSelections();
      }
    }
  };

  /**
   * clears current filter menu selections
   *
   * @returns void
   */
  const handleClearSelections = () => {
    if (selectValue) {
      const key =
        view === Views.TALENT_DISCOVERY
          ? Views.TALENT_DISCOVERY
          : (selectValue.filterName as SelectionCategories);
      getWorkflow(key).clear(workflowHandlerConfig);
    }
  };

  /**
   * Performs actions on menu open
   *
   * @returns void
   */
  const openHandler = () => {
    if (resetSelectOnOpen) {
      setSelectValue(undefined);
    }

    executeOnOpen?.();
  };

  /**
   * submits current filter menu selections
   *
   * @returns void
   */
  const handleFilterSubmit = (selections?: TempSelections) => {
    const currentSelections = selections || tempSelections;
    const config = {
      ...workflowHandlerConfig,
      tempSelections: currentSelections,
    };

    try {
      if (view === Views.TALENT_DISCOVERY) {
        getWorkflow(Views.TALENT_DISCOVERY).submit(config);

        setTDState({});

        return;
      }

      if (selectValue) {
        getWorkflow(selectValue.filterName as SelectionCategories).submit(
          config
        );

        setTempSelections({});

        mixpanel.track(UserFilterTrackingEvents.PAGE_FILTER, {
          page: getActiveSetId(),
          filter_names: selectValue.filterName,
        });
      }
    } catch (err) {
      console.error('Submission Error: ', err);
    }
  };

  /**
   * reset startValue and endValue state on menu close.
   * Applies to Layoff and Screener pages
   *
   * @returns void
   */
  const onScreenerClose = () => {
    if (
      (view === Views.LAYOFF || view === Views.SCREENER) &&
      startValue !== undefined &&
      endValue !== undefined &&
      startValue > endValue
    ) {
      // clear start and end value to clear error
      setStartValue(undefined);
      setEndValue(undefined);
    }
  };

  useEffect(() => {
    const isDefaultNested = Array.isArray(defaultFilter);

    const initialVal = selectableFilters.find((f: any) => {
      return isDefaultNested && Array.isArray(f.value)
        ? difference(f.value, defaultFilter)
        : f.filterName === defaultFilter;
    });

    if (initialVal && !isEqual(selectValue, initialVal)) {
      setSelectValue(initialVal);
    }
  }, [defaultFilter, selectValue, selectableFilters]);

  const showActionMenu = useMemo(() => {
    return selectValue?.filterName === SelectionCategories.SAVED_FILTER_SET;
  }, [selectValue?.filterName]);

  useEffect(() => {
    let active;

    if (selectValue) {
      active = activeFilters?.find((f: any) => f.id === selectValue.filterName);
    }

    setSubmitButtonText(active ? 'Update' : 'Add');
  }, [selectValue, activeFilters]);

  const [submitDisabled, setSubmitDisabled] = useState(false);

  const isPopoverSubmitDisabled = isSubmitDisabled({
    selectValue,
    dateRangeError,
    view,
    dateValue,
    opValue,
    startValue,
    endValue,
    submitDisabled,
  });

  const handleTriggerOnClose = () => {
    setDateRangeValue(undefined);
    setSelectedSnapshotDate(undefined);
    onScreenerClose();
  };

  const [hasClosed, setHasClosed] = useState<boolean>(true);

  const submitOnEnter = !(
    selectValue?.filterName &&
    TextFilters.includes(selectValue.filterName as SelectionCategories)
  );

  const showScreenerRef =
    selectValue &&
    [
      SelectionCategories.HEADCOUNT,
      SelectionCategories.HIRING_RATE,
      SelectionCategories.ATTRITION_RATE,
      SelectionCategories.GROWTH_RATE,
      SelectionCategories.AVERAGE_TENURE,
      SelectionCategories.AVERAGE_SALARY,
      SelectionCategories.COMPANY_CLEANED,
      SelectionCategories.COUNT,
    ].includes(selectValue?.filterName as SelectionCategories);

  const externalControl = useDisclosure();

  return (
    <NodeModalStateProvider>
      <PopoverWithContext
        externalControl={externalControl}
        hideDepracatedCtas={
          [
            LocalSelectionCategories.PROVIDER,
            LocalSelectionCategories.METRIC_MODE,
            SelectionCategories.SAVED_FILTER_SET,
          ].includes(selectValue?.filterName as SelectionCategories) ||
          ((selectValue?.filterName === SelectionCategories.RICS_K10 ||
            selectValue?.filterName === SelectionCategories.COMPANY) &&
            view !== Views.TALENT_DISCOVERY)
        } // ctas are handled by filter
        id={FILTER_MENU_ID} // this is important for talent discovery keywords filter menu portal
        testId="filter-menu-popover"
        title={title}
        lazy={true}
        menuWidth={360}
        menuPlacement="bottom-start"
        openHandler={openHandler}
        triggerOnClose={handleTriggerOnClose}
        closeOnBlur={closeOnBlur}
        closeOnOutsideClick={true}
        defaultIsOpen={defaultIsOpen}
        submitButtonText={submitButtonText}
        isSubmitDisabled={isPopoverSubmitDisabled}
        isDisabled={!!triggerElement && isDisabled}
        triggerElement={
          triggerElement || (
            <Box className={TourClasses.TOUR_FILTER_MENU_CLASS}>
              <Button
                variant="link"
                color="#2D426A"
                height="24px"
                fontSize="12px"
                px={2}
                border="1px solid rgba(45, 66, 106, 0)"
                borderRadius="3px"
                boxSizing="border-box"
                _hover={{
                  border: '1px solid rgba(45, 66, 106, 0.3)',
                  transition: 'border-color 200ms',
                }}
                leftIcon={
                  <Icon
                    as={FiSliders}
                    boxSize={3.5}
                    transform="rotate(90deg)"
                  />
                }
                size="sm"
                data-testid="filter-menu-btn"
              >
                Filter
              </Button>
            </Box>
          )
        }
        handleSubmit={handleFilterSubmit}
        resetBtn={
          selectValue !== undefined && (
            <Button
              variant="ghost"
              size="sm"
              fontSize="12px"
              colorScheme="red"
              flexShrink={0}
              onClick={handleClearSelections}
            >
              Clear Selections
            </Button>
          )
        }
        selectMenuOpenDefault={selectMenuOpenDefault}
        setHasClosed={setHasClosed}
        initialFocusRef={initialFocusRef}
        showActionMenu={showActionMenu}
        submitOnEnter={submitOnEnter}
      >
        <Flex direction="column" gap="0.5rem" py={0}>
          {!hideSelect && (
            <FilterTypeSelect
              fil={fil}
              initialFocusRef={initialFocusRef}
              groupedOptions={groupedOptions}
              selectValue={selectValue}
              handleFilterSelectChange={handleFilterSelectChange}
              selectMenuOpenDefault={selectMenuOpenDefault}
              selectListHeight={selectListHeight}
              focusInput={focusInput}
              hasClosed={hasClosed}
              setHasClosed={setHasClosed}
            />
          )}

          {view === Views.TALENT_DISCOVERY ? (
            <TalentDiscoveryFilterMenu
              selections={get(selectValue, 'value', [])}
              TDState={TDState}
              setTDState={setTDState}
              showDivider={selectValue?.showDivider}
              submitRef={submitRef}
              setIsSubmitDisabled={setSubmitDisabled}
              filterTreeItemData={filterTreeItemData}
            />
          ) : (
            <>
              {showScreenerRef && (
                <ScreenerRef
                  key={selectValue.filterName}
                  selectValue={selectValue}
                  defaultOPVal={defaultOPVal}
                  opValue={opValue}
                  setOpValue={setOpValue}
                  startValue={startValue}
                  setStartValue={setStartValue}
                  endValue={endValue}
                  setEndValue={setEndValue}
                  defaultDateVal={defaultDateVal}
                  dateValue={dateValue}
                  setDateValue={setDateValue}
                  companyValue={companyValue}
                  setCompanyValue={setCompanyValue}
                  tempEmpTypes={tempEmpTypes}
                  setTempEmpTypes={setTempEmpTypes}
                  screenerDefaults={screenerDefaults}
                  dateToggle={dateToggle}
                  setDateToggle={setDateToggle}
                  employeeToggle={employeeToggle}
                  setEmployeeToggle={setEmployeeToggle}
                  limit={limit}
                  ref={submitRef}
                />
              )}
              <DateFilters
                view={view}
                selectValue={selectValue}
                fil={fil}
                endDateDefaultFilterName={endDateDefaultFilterName}
                dateRangeValue={dateRangeValue}
                setDateRangeValue={setDateRangeValue}
                dateRangeError={dateRangeError}
                setDateRangeError={setDateRangeError}
                dateRangeFullValue={dateRangeFullValue}
                setDateRangeFullValue={setDateRangeFullValue}
                selectedSnapshotDate={selectedSnapshotDate}
                setSelectedSnapshotDate={setSelectedSnapshotDate}
              />
              <KeywordFilters
                filterName={selectValue?.filterName}
                state={keywords}
                setState={setKeywords}
              />
              <TreeFilters
                handleFilterSubmit={handleFilterSubmit}
                view={view}
                selectValue={selectValue}
                submitRef={submitRef}
                setTempSelections={setTempSelections}
                expandRoots={expandRoots}
                offsetParent={offsetParent}
                showActionMenu={showActionMenu}
                viewIdForDefault={viewIdForDefault}
                closeFilterMenu={externalControl.onClose}
              />
            </>
          )}
        </Flex>
      </PopoverWithContext>
    </NodeModalStateProvider>
  );
}

export default FilterMenu;
