import { PageTitles, PrimaryFilters } from '@revelio/core';
import {
  FilterMenuLimits,
  FilterSets,
  LocalSelectionCategories,
  PrimaryFilterLimits,
  RICS_AND_COMPANY_FILTERS,
  ROLE_GRANULARITY_FILTERS,
  SKILL_GRANULARITY_FILTERS,
  SelectionCategories,
  ViewTypes,
} from '@revelio/filtering';
import Transitions from '../../../transitions/transitions';
import { View } from '@revelio/data-access';

export function RoleTransitions() {
  const title = [PageTitles.ROLE, PageTitles.TRANSITIONS];

  const primaryViewFilter = [
    {
      filters: ROLE_GRANULARITY_FILTERS,
      isNested: true,
    },
  ];

  const selectableFilters = [
    SelectionCategories.SAVED_FILTER_SET,
    SelectionCategories.HIGHEST_DEGREE,
    SelectionCategories.ETHNICITY,
    SelectionCategories.GENDER,
    {
      filters: [
        SelectionCategories.REGION,
        SelectionCategories.COUNTRY,
        SelectionCategories.METRO_AREA,
      ],
      isNested: true,
      limit: 10,
    },
    SelectionCategories.SENIORITY,
    {
      filters: SKILL_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    {
      filters: RICS_AND_COMPANY_FILTERS,
      isNested: true,
      limit: 10,
    },
    SelectionCategories.DATE_RANGE,
    SelectionCategories.KEYWORD,
    SelectionCategories.RAW_TITLE,
  ];

  const additionalNonActiveFilters = [
    LocalSelectionCategories.INFLOW_OR_OUTFLOW,
    SelectionCategories.DATE_RANGE,
  ];

  const otherFilters: [] = [];

  return (
    <Transitions
      title={title}
      viewType={ViewTypes.ROLE}
      primaryFilter={PrimaryFilters.ROLE}
      primaryViewFilters={primaryViewFilter}
      selectableFilters={selectableFilters}
      additionalNonActiveFilters={additionalNonActiveFilters}
      otherFilters={otherFilters}
      sharedFilterSetId={FilterSets.ROLE}
      filterSet={FilterSets.ROLE_TRANSITIONS}
      primaryFilterLimit={PrimaryFilterLimits.TRANSITIONS}
      filterMenuLimit={FilterMenuLimits.TRANSITIONS}
      savedSetView={View.Role}
    />
  );
}

export default RoleTransitions;
