import {
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  useToast,
} from '@chakra-ui/react';
import { UserSavedFilterSet } from '../../../../engine/filters.savedset.models';
import { useMutation } from 'urql';
import { DeleteSavedSetMutation } from '../../../../engine/gql-models/saved-sets.models';
import { useSavedSetsSelectOptions } from './saved-sets-selection-list.context';
import { useNodeModalState } from '../../node-modal-state.context';
import { FiEdit, FiMoreHorizontal, FiTrash2 } from 'react-icons/fi';
import { ACTION_MENU_LIST_CLASS_NAME } from '../../../utils/constants';
import { useGetStoredSets } from '../../../../engine/filters.savedset';

interface NodeMenuProps {
  node: UserSavedFilterSet;
}

export const NodeMenu = ({ node }: NodeMenuProps) => {
  const toast = useToast();

  const { refresh } = useGetStoredSets();

  const [, deleteSavedSet] = useMutation(DeleteSavedSetMutation);

  const { setSelectedOption } = useSavedSetsSelectOptions();

  const { setNodeModalOpen } = useNodeModalState();

  return (
    <Menu closeOnBlur>
      <MenuButton
        data-testid={`node-menu-button-${node.name.toLowerCase().replace(/\s+/g, '-')}`}
        as={IconButton}
        icon={<FiMoreHorizontal />}
        size="xs"
        variant="link"
        color="icon.lightGray"
        colorScheme="gray"
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
      <Portal>
        <MenuList className={ACTION_MENU_LIST_CLASS_NAME} minWidth="120px">
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();

              setSelectedOption(node);
              setNodeModalOpen(true);
            }}
            icon={<Icon as={FiEdit} boxSize={3.5} />}
            fontSize="sm"
          >
            Rename
          </MenuItem>
          <MenuItem
            icon={<Icon as={FiTrash2} boxSize={3.5} />}
            fontSize="sm"
            onClick={(e) => {
              e.stopPropagation();

              deleteSavedSet({ id: node.id })
                .then((res) => {
                  if (res.error) {
                    toast({
                      title: 'Could not delete saved set',
                      status: 'error',
                      duration: 4000,
                      position: 'top-right',
                    });

                    return;
                  }

                  toast({
                    title: 'Saved set deleted',
                    status: 'success',
                    duration: 4000,
                    position: 'top-right',
                  });
                })
                .catch((err) => {
                  console.log(err);
                  toast({
                    title: 'Could not delete saved set',
                    status: 'error',
                    duration: 4000,
                    position: 'top-right',
                  });
                })
                .finally(() => {
                  refresh();
                });
            }}
          >
            Delete
          </MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  );
};
