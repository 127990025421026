import { Box, Button, Icon } from '@chakra-ui/react';
import { useUserTrialDetails } from '@revelio/auth';
import { WithTooltip } from '@revelio/core';
import { useEffect, useState } from 'react';
import { FiDownload } from 'react-icons/fi';
import { useTalentDiscoveryFilter } from './filters/td-filter-provider';
import { serialiseTalentDiscoveryFilters } from './data-fetch/serialiseTalentDiscoveryFilters';
import { TalentDiscoveryV1_5Filter } from '@revelio/data-access';
import { useDownloadData } from '../../shared/hooks/useDownloadPlotData';

export const TalentDiscoveryDownload = () => {
  const { state } = useTalentDiscoveryFilter();

  const [serialisedFilters, setSerialisedFilters] =
    useState<TalentDiscoveryV1_5Filter | null>(null);

  const { isTrialUser } = useUserTrialDetails<boolean>({
    initialRenderValue: false,
  });

  useEffect(() => {
    let isMounted = true;

    const fetchSerializedFilters = async () => {
      try {
        const result = await serialiseTalentDiscoveryFilters(state.filters);
        if (isMounted) {
          setSerialisedFilters(result);
        }
      } catch (err) {
        if (isMounted) {
          console.error(err);
        }
      }
    };

    fetchSerializedFilters();

    // Cleanup function to prevent setting state on unmounted component
    return () => {
      isMounted = false;
    };
  }, [state.filters]);

  const { triggerDownload, isLoading } =
    useDownloadData<TalentDiscoveryV1_5Filter | null>({
      serialisedFilters,
      downloadPath: 'api/talent-discovery/downloads',
    });

  return (
    <WithTooltip
      showCondition={isTrialUser}
      placement="top"
      variant="label"
      padding="10px 15px"
      label="This feature is not available for trial users"
      aria-label={`This feature is not available for trial users`}
    >
      <Box>
        {Object.keys(serialisedFilters || {})?.length > 0 ? (
          <Button
            isLoading={isLoading}
            loadingText="Preparing Download..."
            leftIcon={<Icon as={FiDownload} boxSize={3.5} />}
            size="sm"
            height="24px"
            variant="white"
            onClick={triggerDownload}
            justifyContent="center"
            data-testid="download-data-button"
            isDisabled={isTrialUser}
          >
            Download Data
          </Button>
        ) : null}
      </Box>
    </WithTooltip>
  );
};
