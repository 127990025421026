import { useQuery } from 'urql';
import { useState, useEffect, Dispatch, SetStateAction } from 'react';

import { TALENT_DISCOVERY_DATA } from './graphql-operations';
import { useTalentDiscoveryFilter } from '../filters/td-filter-provider';

import { serialiseTalentDiscoveryFilters } from './serialiseTalentDiscoveryFilters';
import { TalentDiscoveryV1_5Filter } from '@revelio/data-access';

export const useTalentDiscoveryDataFetch = ({
  resetSelections,
}: {
  resetSelections: Dispatch<SetStateAction<boolean>>;
}) => {
  const { state } = useTalentDiscoveryFilter();

  const numOfFilterSelections = Object.keys(state?.filters || {}).length;
  const [variables, setVariables] = useState<
    | {
        query: TalentDiscoveryV1_5Filter;
      }
    | undefined
  >(undefined);

  useEffect(() => {
    let isMounted = true;

    resetSelections(true);
    const fetchFilters = async () => {
      if (numOfFilterSelections === 0) {
        setVariables(undefined);
        return;
      }

      try {
        const filters = await serialiseTalentDiscoveryFilters(state.filters);

        if (isMounted) {
          setVariables({
            query: {
              ...filters,
              page: 1,
              num_results: 5000,
            },
          });
        }
      } catch (error) {
        console.error('Error serializing filters:', error);
        if (isMounted) {
          setVariables(undefined);
        }
      }
    };

    fetchFilters();

    return () => {
      isMounted = false;
    };
  }, [state.filters, numOfFilterSelections, resetSelections]);

  const [{ data, fetching, error }] = useQuery({
    query: TALENT_DISCOVERY_DATA,
    variables,
    pause: !variables,
  });

  const hasFetchBeenCalled = fetching || !!data || !!error;
  const loading =
    (fetching || !hasFetchBeenCalled) && numOfFilterSelections > 0;

  return {
    data: numOfFilterSelections > 0 ? data : undefined,
    loading,
  };
};
