import {
  Box,
  Button,
  Stack,
  StackDivider,
  Flex,
  Center,
  Spacer,
  useToast,
} from '@chakra-ui/react';
import { AdminCard, FieldGroup } from '@revelio/layout';
import { LoaderType, Loading } from '@revelio/core';
import { USER_DETAILS_QUERY } from '@revelio/data-access';
import { useParams } from 'react-router';
import { Control, UseFormWatch, useForm } from 'react-hook-form';
import { isRoleRevelioAdmin, useGetLoggedInUser } from '@revelio/auth';
import { get } from 'lodash';
import { useMutation, useQuery } from 'urql';

import 'react-datepicker/dist/react-datepicker.css';
import { ClientDetailsQuery, UpdateUserMutation } from '../userOperations';
import UserForm, { submitEditUser, UserFormValues } from '../user-form';
import ClientForm, { ClientFormValues } from '../client-form';
import DashboardPage from '../../DashboardPage';

const UserEdit = () => {
  const toast = useToast();
  const { userId } = useParams();
  const {
    loggedInUser,
    query: [{ fetching: loggedInUserLoading }],
  } = useGetLoggedInUser();

  const isRevelioAdmin = isRoleRevelioAdmin(loggedInUser?.role);
  const [{ fetching, data }] = useQuery({
    query: USER_DETAILS_QUERY,
    variables: { ids: [userId as string] },
    pause: !loggedInUser,
  });
  const userToEdit = get(data, 'users[0]', {});
  const isEditingOwnUser = loggedInUser?.id === userId;
  const [{ fetching: fetchingClient, data: clientData }] = useQuery({
    query: ClientDetailsQuery,
    variables: { ids: [userToEdit.client_name] },
    pause: !userToEdit.client_name,
    requestPolicy: 'cache-and-network',
  });
  const userClientSettings = get(clientData, 'clients[0]', {});
  const [, updateUser] = useMutation(UpdateUserMutation);

  const userFormMethods = useForm<UserFormValues>();
  const clientReadonlyForm = useForm<ClientFormValues>();

  return (
    <DashboardPage title="Manage" hideTop loader={LoaderType.MANUAL}>
      <Box maxW="5xl" mx="auto">
        <Stack as="section" spacing="6">
          <AdminCard>
            {!fetching &&
            userToEdit &&
            !fetchingClient &&
            !loggedInUserLoading ? (
              <Stack direction="row" divider={<StackDivider />} spacing="6">
                <form
                  style={{ width: '930px' }}
                  onSubmit={userFormMethods.handleSubmit(
                    submitEditUser({
                      updateUser,
                      userId: userId as string,
                      toast,
                      currentUser: userToEdit,
                    })
                  )}
                >
                  <Stack divider={<StackDivider />} spacing="6">
                    <FieldGroup
                      title={isEditingOwnUser ? 'Your account' : `Editing user`}
                      description={`Update ${isEditingOwnUser ? 'your' : 'their'} details`}
                    >
                      <UserForm
                        useFormMethods={userFormMethods}
                        userToEdit={userToEdit}
                        setPassword={(newPassword: string) =>
                          userFormMethods.setValue('password', newPassword)
                        }
                        passwordRules={{
                          minLength: {
                            value: 4,
                            message: 'Minimum length should be 4',
                          },
                        }}
                      />
                    </FieldGroup>

                    <FieldGroup>
                      <Stack direction="row" spacing={4} align="center">
                        {!isRevelioAdmin && !isEditingOwnUser && (
                          <Button
                            colorScheme="navyBlue"
                            variant="solid"
                            size="md"
                          >
                            Cancel
                          </Button>
                        )}
                        <Button
                          colorScheme="green"
                          variant="solid"
                          size="md"
                          isLoading={userFormMethods.formState.isSubmitting}
                          type="submit"
                        >
                          Update User
                        </Button>
                        <Spacer />
                      </Stack>
                    </FieldGroup>
                  </Stack>
                </form>

                <ClientForm
                  clientToEdit={userClientSettings}
                  readonly={true}
                  title="Your client group settings"
                  description="Get in touch with Revelio to make any changes"
                  register={() => ({})}
                  control={
                    clientReadonlyForm.control as unknown as Control<
                      ClientFormValues,
                      object
                    >
                  }
                  watch={
                    clientReadonlyForm.watch as unknown as UseFormWatch<ClientFormValues>
                  }
                />
              </Stack>
            ) : (
              <Flex justify="center" minHeight="200px">
                <Center>
                  <Loading />
                </Center>
              </Flex>
            )}
          </AdminCard>
        </Stack>
      </Box>
    </DashboardPage>
  );
};

export default UserEdit;
