import { Option } from '../../selection-list/selection-list-select';
import { METRIC_MODE_IDS } from '@revelio/data-access';

const UNIFIED_SOURCE_ONLY_OPTION_IDS = [
  METRIC_MODE_IDS.expectedHires,
  METRIC_MODE_IDS.expectedHiresPerPosting,
];

const OPTIONS: Option[] = [
  {
    value: METRIC_MODE_IDS.unweighted,
    label: 'Unweighted',
  },
  {
    value: METRIC_MODE_IDS.expectedHires,
    label: 'Weighted by Expected Hires',
  },
  {
    value: METRIC_MODE_IDS.expectedHiresPerPosting,
    label: 'Expected Hires Per Posting',
  },
];

export const getMetricModeOptions = (
  isUnifiedPostingsSourceSelected = true
): Option[] => {
  if (isUnifiedPostingsSourceSelected) {
    return OPTIONS;
  }

  return OPTIONS.map((option) => ({
    ...option,
    isDisabled: UNIFIED_SOURCE_ONLY_OPTION_IDS.includes(option.value),
  }));
};
