import { ContactSalesButton } from '@revelio/composed';
import { PageTitles, PrimaryFilters } from '@revelio/core';
import {
  FilterMenuLimits,
  FilterSets,
  LocalSelectionCategories,
  PrimaryFilterLimits,
  RICS_AND_COMPANY_FILTERS,
  ROLE_GRANULARITY_FILTERS,
  SKILL_GRANULARITY_FILTERS,
  SelectionCategories,
  ViewTypes,
} from '@revelio/filtering';
import Transitions from '../../../transitions/transitions';
import { View } from '@revelio/data-access';

export function CompanyTransitions() {
  const title = [PageTitles.COMPANY, PageTitles.TRANSITIONS];

  const primaryViewFilter = [
    {
      filters: RICS_AND_COMPANY_FILTERS,
      isNested: true,
      limit: 1,
    },
  ];

  const selectableFilters = [
    SelectionCategories.SAVED_FILTER_SET,
    SelectionCategories.HIGHEST_DEGREE,
    SelectionCategories.ETHNICITY,
    SelectionCategories.GENDER,
    {
      filters: [
        SelectionCategories.REGION,
        SelectionCategories.COUNTRY,
        SelectionCategories.METRO_AREA,
      ],
      isNested: true,
      limit: 10,
    },
    {
      filters: ROLE_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    SelectionCategories.SENIORITY,
    {
      filters: SKILL_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    SelectionCategories.DATE_RANGE,
    SelectionCategories.KEYWORD,
    SelectionCategories.RAW_TITLE,
  ];

  const additionalNonActiveFilters = [
    LocalSelectionCategories.INFLOW_OR_OUTFLOW,
    SelectionCategories.DATE_RANGE,
  ];

  const otherFilters: [] = [];

  return (
    <Transitions
      title={title}
      viewType={ViewTypes.COMPANY}
      primaryFilter={PrimaryFilters.COMPANY}
      primaryViewFilters={primaryViewFilter}
      selectableFilters={selectableFilters}
      additionalNonActiveFilters={additionalNonActiveFilters}
      otherFilters={otherFilters}
      sharedFilterSetId={FilterSets.COMPANY}
      filterSet={FilterSets.COMPANY_TRANSITIONS}
      primaryFilterLimit={PrimaryFilterLimits.TRANSITIONS}
      filterMenuLimit={FilterMenuLimits.TRANSITIONS}
      trialNoResultsMessage={
        <>
          Can't find a company?
          <ContactSalesButton
            buttonProps={{
              size: 'sm',
              variant: 'link',
              fontWeight: 400,
              paddingTop: 2,
              paddingLeft: 1,
            }}
            page={PageTitles.TRANSITIONS}
          />
        </>
      }
      savedSetView={View.Company}
    />
  );
}

export default CompanyTransitions;
