import { Button, Flex } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';
import { Icon } from '@chakra-ui/react';
import { FiSliders } from 'react-icons/fi';
import { TalentDiscoveryFilterPopover } from './td-filter-popover';
import { useTalentDiscoveryFilter } from './td-filter-provider';
import { FilterTag } from './filter-tag';
import { FILTER_OPTIONS } from './filter-configuration-options';
import { FilterSetSaveMenu } from '@revelio/filtering';
import { View } from '@revelio/data-access';
import { SingleValue } from 'react-select';
import { Option } from '@revelio/filtering';

const FilterButton = forwardRef((props, ref: LegacyRef<HTMLButtonElement>) => {
  return (
    <Button
      {...props}
      ref={ref}
      colorScheme="gray"
      borderRadius="4px"
      size="sm"
      paddingX={3}
      height={6}
      backgroundColor="transparent"
      fontSize={'xs'}
      fontWeight={400}
      color="navyBlue.500"
      leftIcon={<Icon as={FiSliders} boxSize={3.5} transform="rotate(90deg)" />}
      data-testid="filter-menu-btn"
    >
      Filter
    </Button>
  );
});

export type TalentDiscoveryFilterMenuProps = {
  onSubmitSavedFilterSet?: (value: SingleValue<Option> | undefined) => void;
};

export const TalentDiscoveryFilterMenu = ({
  onSubmitSavedFilterSet,
}: TalentDiscoveryFilterMenuProps) => {
  const { state, dispatch } = useTalentDiscoveryFilter();

  return (
    <Flex wrap="wrap" fontSize="sm" rowGap={2.5}>
      {state.filters?.map((filter) => (
        <FilterTag
          key={filter.name}
          filterOptions={FILTER_OPTIONS}
          filter={filter}
          dispatch={dispatch}
        />
      ))}
      <TalentDiscoveryFilterPopover
        trigger={<FilterButton />}
        onSubmitSavedFilterSet={onSubmitSavedFilterSet}
      />
      <FilterSetSaveMenu view={View.TalentDiscovery} />
    </Flex>
  );
};
