import { Flex } from '@chakra-ui/layout';
import { useMemo } from 'react';
import {
  TalentDiscoveryFilterOption,
  isCompanySearchFilter,
  isSchoolSearchFilter,
  isFreeTextFilter,
  isRangeFilter,
  isTreeFilter,
} from '../types';
import { TreeFilter } from './tree-filter';
import { CompanySearchFilter } from './company-search-filter';
import { SchoolSearchFilter } from './school-search-filter';
import { FreeTextFilter } from './free-text-filter';
import { RangeFilter } from './range-filter';
import {
  SelectionCategories,
  SavedSetsSelectOptionsProvider,
  useOnClickOutsidePopover,
} from '@revelio/filtering';
import {
  SavedSetsNestedSelectionList,
  NodeModalStateProvider,
} from '@revelio/filtering';
import { SingleValue } from 'react-select';
import { Option } from '@revelio/filtering';

export const TalenDiscoveryFilter = ({
  selectedFilter,
  closeMenu,
  filterId,
  onSubmitSavedFilterSet,
}: {
  selectedFilter: TalentDiscoveryFilterOption;
  closeMenu: () => void;
  filterId?: string;
  onSubmitSavedFilterSet?: (value: SingleValue<Option> | undefined) => void;
}) => {
  const isATreeFilter = isTreeFilter(selectedFilter?.value);

  const isCompanySearch = isCompanySearchFilter(selectedFilter?.value);
  const isSchoolSearch = isSchoolSearchFilter(selectedFilter?.value);
  const isFreeTextSearchFilter = isFreeTextFilter(selectedFilter?.value);
  const isARangeFilter = isRangeFilter(selectedFilter?.value);

  const renderFilterComponent = useMemo(() => {
    if (isCompanySearch) {
      return (
        <CompanySearchFilter
          treeHeight={220}
          closeMenu={closeMenu}
          selectedFilter={selectedFilter}
        />
      );
    }

    if (isATreeFilter) {
      return (
        <TreeFilter
          selectionLists={selectedFilter.filterGranularities || []}
          closeMenu={closeMenu}
          selectedFilter={selectedFilter}
          filterId={filterId}
        />
      );
    }

    if (isSchoolSearch) {
      return (
        <SchoolSearchFilter
          treeHeight={220}
          closeMenu={closeMenu}
          selectedFilter={selectedFilter}
        />
      );
    }

    if (isFreeTextSearchFilter) {
      return (
        <FreeTextFilter
          closeMenu={closeMenu}
          selectedFilter={selectedFilter}
          filterId={filterId}
        />
      );
    }

    if (isARangeFilter) {
      return (
        <RangeFilter closeMenu={closeMenu} selectedFilter={selectedFilter} />
      );
    }

    if (selectedFilter.value === SelectionCategories.SAVED_FILTER_SET) {
      return (
        <SavedSetsSelectOptionsProvider>
          <NodeModalStateProvider>
            <SavedSetsNestedSelectionList
              close={closeMenu}
              onSubmitSavedFilterSet={onSubmitSavedFilterSet}
            />
          </NodeModalStateProvider>
        </SavedSetsSelectOptionsProvider>
      );
    }

    return null;
  }, [
    isARangeFilter,
    isATreeFilter,
    isCompanySearch,
    isFreeTextSearchFilter,
    isSchoolSearch,
    closeMenu,
    onSubmitSavedFilterSet,
    filterId,
    selectedFilter,
  ]);

  const componentRef = useOnClickOutsidePopover(closeMenu);

  return (
    <Flex direction="column" gap="4" ref={componentRef}>
      {renderFilterComponent}
    </Flex>
  );
};
