import produce from 'immer';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { devTools } from '@ngneat/elf-devtools';
import { getRegistry } from '@ngneat/elf';
import FlagProvider, { IConfig } from '@unleash/proxy-client-react';
import '@fontsource/source-sans-pro/300.css';
import '@fontsource/source-sans-pro/400.css';
import '@fontsource/source-sans-pro/600.css';
import '@fontsource/source-sans-pro/700.css';

import { theme } from '@revelio/styles';
import { ServiceWorker, userTracking } from '@revelio/core';
import { KibanaLoggerProvider } from '@revelio/layout';
import { KibanaLogger } from '@revelio/iso-utility';
import { authStore, initAuth } from '@revelio/auth';
import { initFiltering } from '@revelio/filtering';

import { environment } from './environments/environment';
import { OpenReplayProvider } from './hooks/open-replay';
import { Next } from './next/next';
import { UrqlProvider } from './next/urql-provider/urql-provider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const sharedLibInitConfig = {
  apiRoot: environment.proxy
    ? environment.REACT_APP_URL
    : environment.REACT_APP_API,
  goApiRoot: environment.GO_API_ROOT,
};

initAuth({
  ...sharedLibInitConfig,
  legacyAuthRoot: environment.LEGACY_AUTH_ROOT,
  oryKratosRoot: environment.ORY_KRATOS_URL,
  dashMetaRoot: environment.DASH_META_API,
  accountPortalRoot: environment.ACCOUNT_PORTAL_ROOT,
  accountPortalBackendRoot: environment.ACCOUNT_PORTAL_BACKEND_ROOT,
});
initFiltering({
  ...sharedLibInitConfig,
  companyMapperUrl: environment.COMPANY_MAPPER_URL,
});

const unleashedConfig: IConfig = {
  url: environment.UNLEASH_URL,
  clientKey: environment.UNLEASH_CLIENT_KEY,
  refreshInterval: 15,
  appName: 'reveliolabs-dashboard',
};

const queryClient = new QueryClient();

if (document.cookie.includes('msw')) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('./mocks/browser');
  worker.start({ onUnhandledRequest: 'bypass' });
}

/** Listen for uncaught promise rejections.
 * This is being setup to specifically handle uncaught rejections from an OpenReplay custom fetch function
 * We are catching the specific error and stopping its propagation */
window.addEventListener('unhandledrejection', (event) => {
  event.promise.catch((err) => {
    // ignore user aborted request thrown by OpenReplay
    if (
      err?.name === 'AbortError' &&
      err?.message === 'The user aborted a request.'
    ) {
      event.preventDefault();
      return;
    }
  });
});

userTracking.init(environment.mixpanelToken, {
  debug: false,
  opt_out_tracking_by_default: !environment.production,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const serialiseTooLongSelectionLists = (state: any) =>
  state && state.filters && state.filters.selectionListEntities
    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
      produce(state, (draftState: any) => {
        draftState.filters.selectionListEntities['company'] =
          '<<long company list>>';
      })
    : state;
devTools({
  name: 'RL Elf Dash',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  stateSanitizer: serialiseTooLongSelectionLists, // e.g. company has 40000+ items which causes memory crashes
});

// this is so we can log the redux store to debug in cypress
// eslint-disable-next-line @typescript-eslint/no-explicit-any
if ((window as any).Cypress) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).stores = getRegistry();
}

const kibanaLogger = new KibanaLogger({
  source: 'REVELIO_DASHBOARD',
  dashMetaRoot: environment.DASH_META_API,
  authStore,
  sendLogs: !environment.DASH_META_API.includes('localhost'),
});

const rootContainer = document.getElementById('next') as Element;
const root = createRoot(rootContainer);

root.render(
  <OpenReplayProvider>
    <ServiceWorker />
    <KibanaLoggerProvider kibanaLogger={kibanaLogger}>
      <FlagProvider config={unleashedConfig}>
        <UrqlProvider>
          <QueryClientProvider client={queryClient}>
            <ChakraProvider theme={theme} portalZIndex={40}>
              <BrowserRouter>
                <Next />
              </BrowserRouter>
            </ChakraProvider>
          </QueryClientProvider>
        </UrqlProvider>
      </FlagProvider>
    </KibanaLoggerProvider>
  </OpenReplayProvider>
);
