import { PageTitles, PrimaryFilters, PrimaryView } from '@revelio/core';
import {
  SelectionCategories,
  ViewTypes,
  FilterSets,
  PrimaryFilterLimits,
  FilterMenuLimits,
  ROLE_GRANULARITY_FILTERS,
  GEOGRAPHY_GRANULARITY_FILTERS,
  RICS_AND_COMPANY_FILTERS,
} from '@revelio/filtering';
import Compensation from '../../../compensation/compensation';
import { View } from '@revelio/data-access';

const title = [PageTitles.ROLE, PageTitles.PAY];

const primaryViewFilters = [
  {
    filters: ROLE_GRANULARITY_FILTERS,
    isNested: true,
  },
];

export function RolePay() {
  const selectableFilters = [
    SelectionCategories.SAVED_FILTER_SET,
    {
      filters: RICS_AND_COMPANY_FILTERS,
      isNested: true,
      limit: 10,
    },
    {
      filters: GEOGRAPHY_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    SelectionCategories.SENIORITY,
    SelectionCategories.DATE_RANGE_FULL,
  ];

  return (
    <Compensation
      title={title}
      primaryView={PrimaryView.ROLE}
      viewType={ViewTypes.ROLE}
      primaryFilter={PrimaryFilters.ROLE}
      sharedFilterSetId={FilterSets.ROLE}
      filterSet={FilterSets.ROLE_COMPENSATION}
      primaryViewFilters={primaryViewFilters}
      primaryFiltersLimit={PrimaryFilterLimits.COMPENSATION}
      selectableFilters={selectableFilters}
      filterMenuLimits={FilterMenuLimits.COMPENSATION}
      otherFilters={[]}
      savedSetView={View.Role}
    />
  );
}

export default RolePay;
