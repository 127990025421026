import { Flex, PopoverBody, PopoverFooter } from '@chakra-ui/react';
import {} from './filter-select-dropdown';
import { useScreenerFilter } from './screener-filter-provider';
import { Tree, TreeMethodHandle, TreeType } from '../collection';
import { useRef, useState } from 'react';
import { SelectionListIdNames, TreeItem } from '../../engine/filters.model';
import { SelectFooter } from './select-footer';
import { ScreenerFilterOption } from './types';

export const PrimaryFilter = ({
  selectedFilter,
  closeMenu,
}: {
  selectedFilter: ScreenerFilterOption;
  closeMenu: () => void;
}) => {
  const treeRef = useRef<TreeMethodHandle>();

  const [primaryFilterSelection, setPrimaryFilterSelection] = useState<
    Record<string, TreeItem>
  >({});

  const { state, dispatch } = useScreenerFilter();

  const primaryFilter = state.filters.primary_filter;

  const handleClearSelections = () => {
    treeRef.current?.handleClearSelections?.();
  };

  const handleAddFilter = () => {
    dispatch({ type: 'SET_PRIMARY_FILTER', filter: primaryFilterSelection });
    closeMenu();
  };

  const treeFilter = [
    selectedFilter.value,
    ...(selectedFilter.filterGranularities || []),
  ];

  return (
    <Flex direction="column" gap="4">
      <PopoverBody padding={0}>
        <Tree
          forwardedRef={treeRef}
          selectionLists={treeFilter as SelectionListIdNames[]}
          limit={20}
          setTempSelections={setPrimaryFilterSelection}
          defaultSelectedItemIds={Object.keys(primaryFilter || {})}
          height={220}
          nestingTreeType={TreeType.SUB_MENU_NESTED}
        />
      </PopoverBody>
      <PopoverFooter padding={'8px 0 0'} borderTopWidth={0}>
        <SelectFooter
          onClearSelections={handleClearSelections}
          onClose={closeMenu}
          onAdd={handleAddFilter}
          addLabel={!Object.keys(primaryFilter || {}).length ? 'Add' : 'Update'}
        />
      </PopoverFooter>
    </Flex>
  );
};
