import { NodeApi, NodeRendererProps } from 'react-arborist';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Checkbox, Icon } from '@chakra-ui/react';
import styles from './Node.module.css';
import { MouseEvent, MouseEventHandler } from 'react';
import classNames from 'classnames';
import { TreeData } from './useTreeApi';

export type NodeData = {
  id: string;
  name: string;
  linkedIds?: string[];
  secondaryLabel?: string;
  secondaryLabelTitle?: string;
  searchTerms?: string[];
};

export type TNode = NodeApi<TreeData>;

export type DrillMode = {
  isEnabled: boolean;
  level: number;
  isRoot?: boolean;
  drillIntoNode: (props: {
    event?: MouseEvent<SVGElement>;
    node: TNode;
    level: number;
  }) => void;
};

type NodeProps = {
  className?: string;
  selected: boolean;
  halfCheck: boolean;
  disabled?: boolean;
  onToggle: (id: string) => void;
  drillMode?: DrillMode;
};

const iconProps = {
  'aria-label': 'node toggle button',
  boxSize: 6,
  color: 'text.primary',
  marginTop: '-2px',
  outline: 'none',
};

export const Node = ({
  node,
  className,
  selected,
  style,
  halfCheck,
  disabled,
  onToggle,
  drillMode,
}: NodeRendererProps<TreeData> & NodeProps) => {
  const isDrillModeEnabled = drillMode?.isEnabled;

  const handleClickExpandNode: MouseEventHandler<SVGElement> = (e) => {
    e.stopPropagation();
    node.toggle();
  };

  const handleClickNodeDrill: MouseEventHandler<SVGElement> = (e) => {
    e.stopPropagation();
    drillMode?.drillIntoNode?.({ event: e, node, level: drillMode.level });
  };

  return (
    <div
      data-testid="selection-tree-data-node"
      {...(!isDrillModeEnabled && { style })}
      className={classNames(styles.nodeContainer, className)}
      onClick={(event) => {
        event.stopPropagation();
        !disabled && onToggle(node.id);
      }}
    >
      {!isDrillModeEnabled && (
        <Icon
          visibility={
            node.children && node.children.length > 0 ? 'visible' : 'hidden'
          }
          {...iconProps}
          data-testid="popout-menu"
          as={node.isOpen ? ChevronDownIcon : ChevronRightIcon}
          onClick={handleClickExpandNode}
        />
      )}

      <Checkbox
        isChecked={selected}
        isIndeterminate={halfCheck && !selected}
        colorScheme="green"
        variant="node-checkbox"
        width="90%"
        disabled={disabled}
        className={styles.checkbox}
      >
        <div className={styles.label}>{node.data.name}</div>
      </Checkbox>
      {isDrillModeEnabled && node.children && node.children.length > 0 && (
        <Icon
          {...iconProps}
          data-testid="popout-menu"
          as={ChevronRightIcon}
          onClick={handleClickNodeDrill}
        />
      )}
    </div>
  );
};
