import { Box, Flex, Text } from '@chakra-ui/layout';
import { Card } from '@chakra-ui/react';
import { GroupedBarChartHorizontal } from '@revelio/d3';
import { CompositionDataQuery } from '@revelio/data-access';
import { useContext, useMemo } from 'react';
import { GeographyBarChartsData, GeographySummaryMetric } from '../../types';
import { getGeographyBarChartProps } from '../utils/get-geography-bar-chart-props';
import { getGeographyCompositionData } from '../utils/get-geography-composition-data';
import { ChartsRenderedContext } from '../../shared/charts-rendered-context';

interface GeographySummaryTopIndustriesProps {
  data: CompositionDataQuery | undefined;
}

export const GeographySummaryTopIndustries = ({
  data,
}: GeographySummaryTopIndustriesProps) => {
  const compositionData: GeographyBarChartsData = useMemo(
    () => getGeographyCompositionData(data),
    [data]
  );

  const chartProps = useMemo(
    () =>
      getGeographyBarChartProps(
        compositionData,
        { value: GeographySummaryMetric.Industries, label: 'Industries' },
        'geography-summary-top-companies'
      ),
    [compositionData]
  );

  const context = useContext(ChartsRenderedContext);
  if (!context) {
    throw new Error(
      'Chart Component must be used within a ChartsRenderedProvider'
    );
  }
  const { setChartHasRendered } = context;

  return (
    <Card height="100%" variant="unstyled" borderRadius={'8px'}>
      <Box height="100%" p={4}>
        <Flex justifyContent="space-between">
          <Text fontSize="sm" fontWeight={600}>
            Top Industries
          </Text>
        </Flex>

        <Box height="calc(100% - 21px)">
          <GroupedBarChartHorizontal
            {...chartProps}
            setChartHasRendered={setChartHasRendered}
          />
        </Box>
      </Box>
    </Card>
  );
};
