import { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Center,
  Flex,
  Spacer,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { AdminCard, FieldGroup } from '@revelio/layout';
import {
  Client,
  CustomTaxonomyEnum,
  GetClientDetailsQuery,
  Role,
  S3Format,
  Tab,
} from '@revelio/data-access';
import { useForm, useWatch } from 'react-hook-form';
import { isRoleRevelioAdmin, useGetLoggedInUser } from '@revelio/auth';
import { compact, get, pull } from 'lodash';

import 'react-datepicker/dist/react-datepicker.css';
import { TypedDocumentNode, useClient, useMutation, useQuery } from 'urql';
import {
  ClientDetailsQuery,
  UpdateClientGroupMutation,
} from '../userOperations';
import { UserFormValues } from '../user-form';
import ClientForm, {
  ClientFormValues,
  ReportSelectOption,
  TabSelectOption,
} from '../client-form';
import {
  getReportOptions,
  serializeOptions,
  tabOptions,
} from '../utils/helpers';
import { useSearchParams } from 'react-router-dom';
import { Loading } from '@revelio/core';

const AdminClientEdit = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const toast = useToast();
  const client = useClient();
  const clientId = searchParams.get('client');
  const [{ fetching: fetchingClient, data: clientData }] = useQuery({
    query: ClientDetailsQuery,
    variables: { ids: [clientId as string] },
    pause: !clientId,
  });

  const {
    loggedInUser,
    query: [{ fetching: loggedInUserLoading }],
  } = useGetLoggedInUser();

  const loggedInClientId = loggedInUser?.client_name;

  const [{ fetching: fetchingLoggedInClient, data: loggedInClientData }] =
    useQuery({
      query: ClientDetailsQuery,
      variables: { ids: [loggedInClientId as string] },
      pause: !loggedInClientId,
    });

  const clientToEdit = useMemo(() => {
    const _client: Client = get(clientData, 'clients[0]', {});
    // TODO: temp migrate TD_V2 to TD tab
    if (
      _client.tabs?.includes(Tab.TalentDiscoveryV2) &&
      !_client.tabs.includes(Tab.TalentDiscovery)
    ) {
      _client.tabs.push(Tab.TalentDiscovery);
      pull(_client.tabs, Tab.TalentDiscoveryV2);
    }
    return _client;
  }, [clientData]);
  const [, updateClientGroup] = useMutation(UpdateClientGroupMutation);
  const [selectedClient, setSelectedClient] = useState(clientToEdit);
  const [reportOptions, setReportOptions] = useState<ReportSelectOption[]>([]);

  const { control, watch } = useForm<UserFormValues>();

  const {
    control: clientControl,
    register: clientFormRegister,
    formState: { isSubmitting: clientIsSubmitting, errors },
    handleSubmit: clientHandleSubmit,
    setValue: clientSetValue,
    reset,
    watch: clientWatch,
  } = useForm<ClientFormValues>();

  useEffect(() => {
    clientSetValue('num_seats', clientToEdit.num_seats);
  }, [clientToEdit.num_seats, clientSetValue]);

  useEffect(() => {
    setSelectedClient(clientToEdit);
  }, [clientToEdit]);

  const hasDeliverablesTab = clientWatch('tabs', [])?.some(
    (tab) => tab?.value === 'deliverables'
  );

  const hasReportsTab = clientWatch('tabs', [])?.some(
    (tab) => tab?.value === 'reports'
  );

  const submitEditClient = clientHandleSubmit((formData) => {
    const reportTypesToUse = formData.report_types
      ? serializeOptions(formData.report_types).reportTypes
      : selectedClient.reports_configuration?.report_types;

    // Defaulting to selectedClient's data_builder_configuration if formData.pipeline_types is undefined
    const pipelineTypesToUse = formData.pipeline_types
      ? serializeOptions(formData.pipeline_types).pipelineTypes
      : selectedClient.data_builder_configuration?.pipeline_type;

    const postingSourcesToUse = formData.pipeline_types
      ? serializeOptions(formData.pipeline_types).postingSources
      : selectedClient.data_builder_configuration?.posting_source;

    const s3Location = formData.s3_location
      ? {
          s3_bucket: formData.s3_location,
          s3_file_prefix: '', // placeholders
          s3_format: S3Format.Csv, // placeholders
        }
      : undefined;

    const snowflakeLocation =
      formData.snowflake_database || formData.snowflake_schema
        ? {
            snowflake_db: formData.snowflake_database || '',
            snowflake_schema: formData.snowflake_schema || '',
            snowflake_file_prefix: '', // placeholder
          }
        : undefined;

    return updateClientGroup({
      client_name: clientId as string,
      tabs: compact(formData.tabs).map((tab) => tab.value),
      live: formData.live,
      active: formData.active,
      linkup_postings: formData.linkup_postings,
      unified_postings: formData.unified_postings,
      num_seats: formData?.num_seats?.toString(),
      custom_taxonomy:
        formData.custom_taxonomy?.value ||
        formData.custom_taxonomy ||
        CustomTaxonomyEnum.Undefined,
      data_builder_configuration: hasDeliverablesTab
        ? {
            pipeline_type: pipelineTypesToUse,
            posting_source: postingSourcesToUse,
            s3_location: s3Location,
            snowflake_location: snowflakeLocation,
          }
        : {},
      ...(hasReportsTab && reportTypesToUse
        ? { reports_configuration: { report_types: reportTypesToUse } }
        : {}),
    }).then((result) => {
      if (result.error) {
        return;
      }

      toast({
        title: 'client group updated',
        description: 'The client group has now been updated',
        status: 'success',
        duration: 4000,
        position: 'top-right',
      });
    });
  });

  const isRevelioAdmin = isRoleRevelioAdmin(loggedInUser.role);
  // as a client admin, the client settings are hidden and cancel button added next to update user button
  const isClientAdmin = loggedInUser.role === Role.ClientAdmin;

  const selectedClientGroup = useWatch({
    control,
    name: 'client_group.value',
  }); // initially watch returns undefined when set by the defaultValue of clientId
  const isUserClientGroupEdited =
    (selectedClientGroup || clientId) !== clientId;
  const watchedClientGroup = watch('client_group');
  useEffect(() => {
    if (clientId && !fetchingLoggedInClient && loggedInClientData) {
      const fetchClientDetails = async () => {
        try {
          const query: TypedDocumentNode<
            GetClientDetailsQuery,
            { ids: string[] }
          > = ClientDetailsQuery;
          const { data } = await client
            .query(query, {
              ids: [clientId],
            })
            .toPromise();

          const clientData = get(data, 'clients[0]', {});

          const loggedInClient = get(loggedInClientData, 'clients[0]', {});

          if (
            loggedInClient?.reports_configuration &&
            clientData?.client_name
          ) {
            setSelectedClient(clientData);

            const allReportTypes = getReportOptions(loggedInClient);

            setReportOptions(allReportTypes);

            reset({
              ...clientData,
              tabs: [
                ...(clientData.tabs.map((tab: string) =>
                  tabOptions.find((o) => o.value === tab)
                ) as TabSelectOption[]),
              ],
            });
          }
        } catch (error) {
          // Handle error if needed
        }
      };

      fetchClientDetails();
    }
  }, [
    watchedClientGroup,
    client,
    clientToEdit,
    clientSetValue,
    reset,
    clientId,
    fetchingLoggedInClient,
    loggedInClientData,
  ]);

  return (
    <AdminCard
      p="2"
      h="100%"
      sx={{
        '& form': {
          height: '100%',
        },
        '& > form > div': {
          height: '100%',
          justifyContent: 'space-between',
        },
      }}
    >
      {!fetchingClient && !loggedInUserLoading && !isClientAdmin ? (
        <form style={{ height: '100%' }} onSubmit={submitEditClient}>
          <ClientForm
            errors={errors}
            clientToEdit={selectedClient}
            title={`Edit client group: ${selectedClient.client_name}`}
            description="Affects all users in group"
            readonly={isUserClientGroupEdited}
            register={clientFormRegister}
            control={clientControl}
            watch={clientWatch}
            reportOptions={reportOptions}
            actions={
              <FieldGroup>
                <Stack direction="row" spacing={4} align="center">
                  <Button
                    colorScheme="navyBlue"
                    variant="solid"
                    size="md"
                    onClick={() => {
                      const newSearchParams = new URLSearchParams(searchParams);

                      newSearchParams.delete('editing');
                      newSearchParams.delete('client');

                      setSearchParams(newSearchParams);
                    }}
                  >
                    Cancel
                  </Button>
                  {isRevelioAdmin && (
                    <Button
                      colorScheme="green"
                      variant="solid"
                      size="md"
                      isLoading={clientIsSubmitting}
                      type="submit"
                      isDisabled={isUserClientGroupEdited}
                    >
                      Update Client
                    </Button>
                  )}
                  <Spacer />
                  {isRevelioAdmin && (
                    <Button
                      colorScheme="green"
                      variant="solid"
                      size="md"
                      onClick={() => {
                        const newSearchParams = new URLSearchParams(
                          searchParams
                        );

                        newSearchParams.delete('editing');
                        newSearchParams.set('client', clientId ?? '');

                        setSearchParams(newSearchParams);
                      }}
                    >
                      Show Users
                    </Button>
                  )}
                </Stack>
              </FieldGroup>
            }
          />
        </form>
      ) : (
        <Flex justify="center" minHeight="200px">
          <Center>
            <Loading />
          </Center>
        </Flex>
      )}
    </AdminCard>
  );
};

export default AdminClientEdit;
