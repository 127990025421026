import { PageTitles, Views } from '@revelio/core';
import {
  ScreenerFilterProvider,
  SelectionCategories,
  useTabMeta,
} from '@revelio/filtering';
import { Screener } from '../../../screener/screener';
import { generateFilterOptions } from '../../utils/generateFilterOptions';
import { ScreenerDimension, View } from '@revelio/data-access';

const title = [PageTitles.COMPANY, PageTitles.SCREENER];

export const CompanyScreener = () => {
  const filterOptions = generateFilterOptions(
    SelectionCategories.INDUSTRY,
    undefined
  );

  useTabMeta({
    savedSetView: View.CompanyScreener,
    view: Views.SCREENER,
  });

  return (
    <ScreenerFilterProvider>
      <Screener
        title={title}
        filterOptions={filterOptions}
        view={ScreenerDimension.Company}
        sortingDisabledColumns={[
          'Company Name',
          'Description',
          'Email',
          'Founder Ethnicity',
          'Founder Gender',
          'Funding Stage',
          'HQ Location',
          'Keywords',
          'Last Funding Amount',
          'Last Funding Year',
          'LinkedIn URL',
          'Phone Number',
          'Public Company',
          'Subsidiary Of',
          'Total Funding Amount',
          'Valuation',
          'Website',
          'Year Founded',
        ]}
        savedSetsView={View.CompanyScreener}
      />
    </ScreenerFilterProvider>
  );
};

export default CompanyScreener;
