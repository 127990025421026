import { IconButton, Tooltip } from '@chakra-ui/react';
import { FiFilter } from 'react-icons/fi';

interface CustomiseColumnsButtonProps {
  onClick: () => void;
  right?: number;
}

export const CustomiseColumnsButton = ({
  onClick,
  right,
}: CustomiseColumnsButtonProps) => {
  return (
    <Tooltip
      hasArrow
      label="Manage Columns"
      variant="label"
      placement="top"
      maxWidth="170px"
      fontSize="xs"
      lineHeight="15px"
      openDelay={400}
      p={2}
    >
      <IconButton
        position="absolute"
        zIndex={10}
        right={right}
        width="44px"
        height="42px"
        background="white"
        aria-label="Manage Columns"
        icon={<FiFilter color="#555e68" size="18px" />}
        border="1px solid #E4E3E2"
        colorScheme="gray"
        onClick={onClick}
        boxShadow="0 0 8px 5px rgba(45, 66, 106, 0.10)"
        clipPath="polygon(-20% 0%, 86% 0%, 100% 14%, 100% 100%, -20% 100%)"
        _focus={{
          boxShadow: '0 0 8px 5px rgba(45, 66, 106, 0.10) !important',
        }}
        style={{ borderRadius: '0 10px 0 0' }}
        data-testid="manage-table-columns-button"
      />
    </Tooltip>
  );
};
