import { LegacyRef, useEffect, useState } from 'react';
import { SystemStyleObject, chakra } from '@chakra-ui/system';
import { TypingText } from '@revelio/layout';
import { Socket, io } from 'socket.io-client';
import {
  AnyFilter,
  FilterList,
  FilterOrSubfilterName,
  getFilterChildSelectionListIds,
  SelectFilter,
  SelectionCategories,
  SelectionList,
  ValidValueTypes,
} from '@revelio/filtering';
import { environment } from '../../../environments/environment';
import { get } from 'lodash';

export interface ISentimentGptReviewIndexInput {
  RCID: Array<number | string>;
  SENIORITY: Array<number | string>;
  MSA_INDEX: Array<number | string>;
  REGION_INDEX: Array<number | string>;
  COUNTRY_INDEX: Array<number | string>;
  STATE_INDEX?: Array<number | string>;
  ROLE_K7_INDEX: Array<number | string>;
  ROLE_K150_INDEX: Array<number | string>;
  RICS_K10_INDEX: Array<number | string>;
  RICS_K50_INDEX: Array<number | string>;
  MAPPED_ROLE_INDEX: Array<number | string>;
  MONTH_INDEX: Array<number | string>;
}

export interface ISentimentGptReviewInput
  extends ISentimentGptReviewIndexInput {
  COMPANY_NAME?: string;
  INDUSTRY_NAME?: string;
  GEOGRAPHY_NAME?: string;
  ROLE_NAME?: string;
  MODEL: 'gpt-3.5-turbo' | string;
}

export type GptSentimentReviewType = 'positive' | 'negative';
export interface GptSentimentReviewProps {
  socket?: Socket;
  reviewType: GptSentimentReviewType;
  filters: FilterOrSubfilterName[];
  sx?: SystemStyleObject;
  targetRef?: LegacyRef<HTMLDivElement>;
}

const filterIndexMap: { [key in FilterOrSubfilterName]?: string } = {
  [SelectionCategories.REGION]: 'REGION_INDEX',
  [SelectionCategories.COUNTRY]: 'COUNTRY_INDEX',
  [SelectionCategories.METRO_AREA]: 'MSA_INDEX',
  [SelectionCategories.ROLE_K1500]: 'MAPPED_ROLE_INDEX',
  [SelectionCategories.ROLE_K150]: 'ROLE_K150_INDEX',
  [SelectionCategories.JOB_CATEGORY]: 'ROLE_K7_INDEX',
  [SelectionCategories.SENIORITY]: 'SENIORITY',
  [SelectionCategories.COMPANY]: 'RCID',
  [SelectionCategories.RICS_K10]: 'RICS_K10_INDEX',
  [SelectionCategories.RICS_K50]: 'RICS_K50_INDEX',
  [SelectionCategories.INDUSTRY]: 'INDUSTRY_INDEX',
};

export const gptSentimentIndexSetter = (
  filter: AnyFilter<FilterList<ValidValueTypes>>,
  state: ISentimentGptReviewInput,
  propPath = 'id'
) => {
  const indexName = filterIndexMap[
    filter.id
  ] as keyof ISentimentGptReviewIndexInput;
  if (indexName) {
    (filter as SelectFilter<FilterList<ValidValueTypes>>).value.forEach(
      (entity) => {
        const index = get(entity, propPath) as string;
        if (index) {
          state[indexName]?.push(Number.parseInt(index));
        }
      }
    );
  }
};

export const gptSentimentRoleTaxonomyIndexSetter = (
  filter: AnyFilter<FilterList<ValidValueTypes>>,
  state: ISentimentGptReviewInput,
  propPath = 'id',
  selectionLists: SelectionList<ValidValueTypes>[] | undefined
) => {
  const indexName = filterIndexMap[
    filter.id
  ] as keyof ISentimentGptReviewIndexInput;
  if (indexName === 'MAPPED_ROLE_INDEX') {
    (filter as SelectFilter<FilterList<ValidValueTypes>>).value.forEach(
      (entity) => {
        const index = get(entity, propPath) as string;
        if (index) {
          state[indexName]?.push(Number.parseInt(index));
        }
      }
    );
  }

  const selectedFilterValues = (
    filter as SelectFilter<FilterList<ValidValueTypes>>
  ).value.map((f) => f.id) as string[];
  const getMappedRolesFromK150 = (filterIds: string[]) => {
    return getFilterChildSelectionListIds({
      selectedFilterValues: filterIds,
      selectionLists,
      selectedFilterSelectionListId: SelectionCategories.ROLE_K150,
      noSelectedFilterSelectionListId: SelectionCategories.JOB_CATEGORY,
      isDashboardFilterRoles: true,
    }).map((id) => Number.parseInt(id));
  };
  if (indexName === 'ROLE_K150_INDEX') {
    state['MAPPED_ROLE_INDEX'] = getMappedRolesFromK150(selectedFilterValues);
  }

  if (indexName === 'ROLE_K7_INDEX') {
    const roleK150Ids = getFilterChildSelectionListIds({
      selectedFilterValues,
      selectionLists,
      selectedFilterSelectionListId: SelectionCategories.JOB_CATEGORY,
      noSelectedFilterSelectionListId: SelectionCategories.JOB_CATEGORY,
      isDashboardFilterRoles: true,
    });

    state['MAPPED_ROLE_INDEX'] = getMappedRolesFromK150(roleK150Ids);
  }
};

export const sentimentSocket = io(
  `${environment.DASH_META_API}/sentiment-chatgpt`,
  {
    path: `/labsws`,
    // addTrailingSlash: false,
    autoConnect: false,
    transports: ['websocket'],
    reconnectionAttempts: 5,
    secure: environment.production,
    withCredentials: true,
    query: {
      'request-id': crypto.randomUUID(),
    },
    rejectUnauthorized: environment.production,
  }
);

function InternalGptSentimentReview({
  reviewType,
  sx = {},
}: GptSentimentReviewProps) {
  const [gptReview, setGptReview] = useState('');

  useEffect(() => {
    if (sentimentSocket) {
      const messageChannel = `chat:${reviewType === 'positive' ? 'pos' : 'neg'}`;
      const onClear = () => {
        setGptReview('');
      };
      const onChat = (data: string) => setGptReview((s) => `${s}${data}`);

      sentimentSocket.on('chat:clear', onClear);
      sentimentSocket.on(messageChannel, onChat);

      return () => {
        sentimentSocket.off(messageChannel, onChat);
        sentimentSocket.off('chat:clear', onClear);
      };
    }
    return () => '';
  }, [reviewType]);

  return (
    <chakra.div
      display="flex"
      flexDirection="column"
      justifyContent="center"
      maxHeight="90vh"
      sx={{
        ...sx,
        maskImage: 'linear-gradient(to bottom, black 87%, transparent 100%);',
      }}
    >
      <TypingText textChunk={gptReview} sx={{ pb: '16px' }} />
    </chakra.div>
  );
}

export { InternalGptSentimentReview as GptSentimentReview };
