import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LZString from 'lz-string';
import {
  TalentDiscoveryFilterAction,
  TalentDiscoveryFilterState,
} from './filters/td-filter-reducer';
import { useTalentDiscoveryFilter } from './filters/td-filter-provider';

/**
 * Loads state from URL or localStorage.
 */
export const loadStateFromUrlOrStorage = <T>(
  paramName: string,
  urlParams: URLSearchParams,
  setState: (state: T) => void,
  validateState: (state: unknown) => state is T
): void => {
  const compressedFromUrl = urlParams.get(paramName);
  if (compressedFromUrl) {
    try {
      const serialized =
        LZString.decompressFromEncodedURIComponent(compressedFromUrl);

      if (serialized) {
        const state: unknown = JSON.parse(serialized);

        if (validateState(state)) {
          setState(state);
          return;
        } else {
          urlParams.delete(paramName); // Remove invalid param from URL
        }
      }
    } catch (error) {
      urlParams.delete(paramName); // Remove invalid param from URL
    }
  }

  // No valid data in URL, check localStorage
  const compressedFromStorage = localStorage.getItem(paramName);
  if (compressedFromStorage) {
    try {
      const serialized = LZString.decompressFromUTF16(compressedFromStorage);

      if (serialized) {
        const state: unknown = JSON.parse(serialized);

        if (validateState(state)) {
          setState(state);
        } else {
          localStorage.removeItem(paramName); // Remove invalid data from localStorage
        }
      }
    } catch (error) {
      localStorage.removeItem(paramName); // Remove invalid data from localStorage
    }
  }
};

/**
 * Saves state to URL and localStorage.
 */
export const saveStateToUrlAndStorage = <T>(
  paramName: string,
  urlParams: URLSearchParams,
  state: T | undefined,
  isEmpty: (state: T) => boolean = () => false,
  validateState: (state: unknown) => state is T
): void => {
  if (state && !isEmpty(state) && validateState(state)) {
    const serialized = JSON.stringify(state);

    const compressedForUrl = LZString.compressToEncodedURIComponent(serialized);

    urlParams.set(paramName, compressedForUrl);

    const compressedForStorage = LZString.compressToUTF16(serialized);

    localStorage.setItem(paramName, compressedForStorage);
  } else {
    urlParams.delete(paramName);
    localStorage.removeItem(paramName);
  }
};

const isValidTalentDiscoveryFilters = (
  filters: unknown
): filters is TalentDiscoveryFilterState['filters'] => {
  return Array.isArray(filters);
};

const isValidColumns = (columns: unknown): columns is string[] => {
  if (columns === undefined) {
    return true; // Allow undefined to denote default columns
  }

  if (!Array.isArray(columns)) {
    return false;
  }

  return columns.every((col) => typeof col === 'string');
};

export const loadAllTalentDiscoveryStateFromUrl = (
  dispatch: React.Dispatch<TalentDiscoveryFilterAction>,
  setSavedCheckedColumns: React.Dispatch<React.SetStateAction<string[]>>,
  params: URLSearchParams
) => {
  // Handle filters
  loadStateFromUrlOrStorage<TalentDiscoveryFilterState['filters']>(
    'filters',
    params,
    (filters) => {
      dispatch({ type: 'OVERWRITE_FILTERS', filters });
    },
    isValidTalentDiscoveryFilters
  );

  // Handle selected columns
  loadStateFromUrlOrStorage<string[]>(
    'columns',
    params,
    setSavedCheckedColumns,
    isValidColumns
  );
};

export const useSyncTalentDiscoveryFiltersWithUrl = (
  savedCheckedColumns: string[],
  setSavedCheckedColumns: React.Dispatch<React.SetStateAction<string[]>>
) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state, dispatch } = useTalentDiscoveryFilter();

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    loadAllTalentDiscoveryStateFromUrl(
      dispatch,
      setSavedCheckedColumns,
      params
    );

    // Check if any params were removed
    if (params.toString() !== location.search.substring(1)) {
      const newSearch = params.toString();
      const newUrl = `${location.pathname}${newSearch ? `?${newSearch}` : ''}`;
      navigate(newUrl, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, setSavedCheckedColumns]);

  // On state change, update the URL and local storage
  useEffect(() => {
    const params = new URLSearchParams(location.search);

    // Handle filters
    saveStateToUrlAndStorage(
      'filters',
      params,
      state.filters,
      (filters: TalentDiscoveryFilterState['filters']) =>
        !filters || filters.length === 0,
      isValidTalentDiscoveryFilters
    );

    // Handle selected columns
    saveStateToUrlAndStorage(
      'columns',
      params,
      savedCheckedColumns,
      (cols) => !cols || cols.length === 0,
      isValidColumns
    );

    // Update the URL if any changes
    const newSearch = params.toString();
    if (newSearch !== location.search.substring(1)) {
      const newUrl = `${location.pathname}${newSearch ? `?${newSearch}` : ''}`;
      navigate(newUrl, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.filters, savedCheckedColumns, navigate, location.pathname]);
};
