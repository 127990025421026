import {
  Button,
  Stack,
  StackDivider,
  Flex,
  Center,
  Spacer,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Box,
} from '@chakra-ui/react';
import { AdminCard, FieldGroup } from '@revelio/layout';
import { Role, User, USER_DETAILS_QUERY } from '@revelio/data-access';
import { useForm } from 'react-hook-form';
import { HamburgerIcon } from '@chakra-ui/icons';
import { isRoleRevelioAdmin, useGetLoggedInUser } from '@revelio/auth';
import { get } from 'lodash';

import 'react-datepicker/dist/react-datepicker.css';
import { useMutation, useQuery } from 'urql';
import {
  ClientDetailsQuery,
  DeleteUserMutation,
  UpdateUserMutation,
} from '../userOperations';
import UserForm, { submitEditUser, UserFormValues } from '../user-form';
import { useSearchParams } from 'react-router-dom';
import { Loading } from '@revelio/core';
import styles from '../long-form.module.css';
import { useEffect } from 'react';

const AdminUserEdit = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const toast = useToast();
  const userId = searchParams.get('userId');
  const [{ fetching, data }] = useQuery({
    query: USER_DETAILS_QUERY,
    variables: { ids: [userId as string] },
  });
  const userToEdit: User = get(data, 'users[0]', {});
  const [{ fetching: fetchingClient, data: clientData }] = useQuery({
    query: ClientDetailsQuery,
    variables: { ids: [userToEdit.client_name as string] },
    pause: !userToEdit.client_name,
  });
  const [, deleteUser] = useMutation(DeleteUserMutation);
  const [, updateUser] = useMutation(UpdateUserMutation);

  const userFormMethods = useForm<UserFormValues>();

  useEffect(() => {
    if (userId !== userToEdit.id) {
      userFormMethods.reset();
    }
  });

  const {
    loggedInUser,
    query: [{ fetching: loggedInUserLoading }],
  } = useGetLoggedInUser();
  const isRevelioAdmin = isRoleRevelioAdmin(loggedInUser.role);
  // as a client admin, the client settings are hidden and cancel button added next to update user button
  const isClientAdmin = loggedInUser.role === Role.ClientAdmin;

  return (
    <Stack h={'100%'} w="100%" as="section" spacing="2">
      <AdminCard
        p="2"
        h="100%"
        sx={{
          '& form': {
            height: '100%',
          },
          '& > form > div': {
            height: '100%',
            justifyContent: 'space-between',
          },
        }}
      >
        {!fetching && userToEdit && !fetchingClient && !loggedInUserLoading ? (
          <form
            onSubmit={userFormMethods.handleSubmit(
              submitEditUser({
                updateUser,
                userId: userId as string,
                toast,
                currentUser: userToEdit,
              })
            )}
          >
            <Stack divider={<StackDivider />} spacing="2">
              <Box overflow="scroll" className={styles.contentContainer}>
                <FieldGroup
                  title="Edit User"
                  description={`Edit user account - id:${userId}`}
                >
                  <UserForm
                    userToEdit={userToEdit}
                    useFormMethods={userFormMethods}
                    setPassword={(newPassword: string) =>
                      userFormMethods.setValue('password', newPassword)
                    }
                    setTrialExpirationDate={(newTrialExpiration: Date) =>
                      userFormMethods.setValue(
                        'trialExpirationDate',
                        newTrialExpiration.toISOString()
                      )
                    }
                    passwordRules={{
                      minLength: {
                        value: 4,
                        message: 'Minimum length should be 4',
                      },
                    }}
                  />
                </FieldGroup>
              </Box>

              <FieldGroup>
                <Stack direction="row" spacing={2} align="center">
                  {isClientAdmin && (
                    <Button colorScheme="navyBlue" variant="solid" size="md">
                      Cancel
                    </Button>
                  )}
                  <Button
                    colorScheme="green"
                    variant="solid"
                    size="md"
                    isLoading={userFormMethods.formState.isSubmitting}
                    type="submit"
                  >
                    Update User
                  </Button>
                  {clientData?.clients && (
                    <Button
                      onClick={() => {
                        const newSearchParams = new URLSearchParams(
                          searchParams
                        );

                        newSearchParams.delete('editing');
                        newSearchParams.delete('userId');

                        if (clientData?.clients) {
                          newSearchParams.set(
                            'client',
                            clientData.clients[0]?.client_name ?? ''
                          );
                        }

                        setSearchParams(newSearchParams);
                      }}
                    >
                      Back to User List
                    </Button>
                  )}
                  <Spacer />
                  {isRevelioAdmin && (
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        icon={<HamburgerIcon />}
                        variant="solid"
                        colorScheme="gray"
                      />
                      <MenuList>
                        <MenuItem
                          color="red"
                          onClick={() =>
                            deleteUser({ id: userId as string }).then(
                              (result) => {
                                if (result.error) {
                                  toast({
                                    title: 'Error',
                                    description:
                                      'There was an error deleting the account',
                                    status: 'error',
                                    duration: 4000,
                                    position: 'top-right',
                                  });
                                  return;
                                }

                                toast({
                                  title: 'Account deleted',
                                  description:
                                    'The account has now been deleted',
                                  status: 'success',
                                  duration: 4000,
                                  position: 'top-right',
                                });
                                const newSearchParams = new URLSearchParams(
                                  searchParams
                                );
                                newSearchParams.delete('editing');
                                newSearchParams.delete('client');
                              }
                            )
                          }
                        >
                          Delete
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  )}
                </Stack>
              </FieldGroup>
            </Stack>
          </form>
        ) : (
          <Flex justify="center" minHeight="200px">
            <Center>
              <Loading />
            </Center>
          </Flex>
        )}
      </AdminCard>
    </Stack>
  );
};

export default AdminUserEdit;
