import { isEmpty, clamp } from 'lodash';
export interface popupHTMLGenInterface {
  header: string;
  profiles?: string;
  headcount?: string;
  growth?: string;
  postings?: string;
  timeToFill?: string;
  marketTightness?: string;
  salaryDist?: string[];
  isLoading: boolean;
  height?: number;
}

const renderProfiles = (profiles: string | undefined, isLoading: boolean) => {
  if (!profiles) return '';
  return `
  <div class="info-item" data-testid='td-tooltip-profiles'>
    <div>Profiles</div>
      <div>
          ${isLoading ? '' : profiles}
         <div class="h10" style="display: ${isLoading ? 'flex' : 'none'};">
          <div 
            class="skeleton skeleton-line br2" 
            style="--c-w: 54px; --l-h:10px;--c-p:0px;--bg: rgba(255,255,255,0.1); --t: 1s;"
          ></div>
        </div>
      </div>
    </div>`;
};

const renderHeadcount = (headcount: string | undefined, isLoading: boolean) => {
  if (!headcount) return '';
  return `
  <div class="info-item" data-testid='td-tooltip-headcount'>
    <div>Headcount</div>
      <div>
          ${isLoading ? '' : headcount}
         <div class="h10" style="display: ${isLoading ? 'flex' : 'none'};">
          <div 
            class="skeleton skeleton-line br2" 
            style="--c-w: 54px; --l-h:10px;--c-p:0px;--bg: rgba(255,255,255,0.1); --t: 1s;"
          ></div>
        </div>
      </div>
    </div>`;
};

const renderGrowth = (growth: string | undefined, isLoading: boolean) => {
  if (!growth) return '';
  return `
    <div class="info-item" data-testid='td-tooltip-growth'>
      <div>Growth</div>
      <div style="display: ${isLoading ? 'none' : 'flex'};" >
        <span class="${
          growth.includes('−') ? 'highlight-red' : 'highlight-green'
        }">
        ${growth}
        <span>
      </div>
      <div class="h10" style="display: ${isLoading ? 'flex' : 'none'};">
        <div class="skeleton skeleton-line br2" style = "--c-w: 30px; --l-h:10px;--c-p:0px;--bg: rgba(255,255,255,0.1); --t: 1s;"></div>
      </div>
    </div>
  `;
};

const renderPostings = (postings: string | undefined, isLoading: boolean) => {
  if (!postings) return '';
  return `
    <div class="info-item" data-testid='td-tooltip-postings'>
      <div>Postings</div>
      <div>${isLoading ? '' : postings}
        <div class="h10" style="display: ${isLoading ? 'flex' : 'none'};">
          <div class="skeleton skeleton-line br2" style = "--c-w: 48px; --l-h:10px;--c-p:0px;--bg: rgba(255,255,255,0.1); --t: 1s;"></div>
        </div>
      </div>
    </div>
  `;
};

const renderTimeToFill = (
  timeToFill: string | undefined,
  isLoading: boolean
) => {
  if (!timeToFill) return '';
  return `
    <div class="info-item">
      <div>Time to Fill</div>
      <div data-testid='td-tooltip-time-to-fill'>${isLoading ? '' : timeToFill + ' days'}
        <div class="h10" style="display: ${isLoading ? 'flex' : 'none'};">
          <div class="skeleton skeleton-line br2" style = "--c-w: 34px; --l-h:10px;--c-p:0px;--bg: rgba(255,255,255,0.1); --t: 1s;"></div>
        </div>
      </div>
    </div>
  `;
};

const renderMarketTightness = (
  marketTightness: string | undefined,
  isLoading: boolean
) => {
  if (!marketTightness) return '';

  const parsedMarketTightness =
    marketTightness === '−−' ? 0 : parseInt(marketTightness, 10) || 0;
  const clampedMarketTightness = clamp(parsedMarketTightness, 0, 100);

  return `
    <div class="plot-container" data-testid='td-tooltip-market-tightness'>
      <div class="plot-box">
        <div class="plot-title">Market Tightness</div>
        <div class="plot-item">
          <div class="slider-popover" style="left: ${clampedMarketTightness}%">${isLoading ? '' : clampedMarketTightness + '%'}</div>
          <div style="display: ${isLoading ? 'none' : 'block'};">
            <input class="slider" type="range" min="1" max="100" value="${clampedMarketTightness}" />
          </div>
          <div class="h10full" style="display: ${isLoading ? 'block' : 'none'}; margin-top: 8px;">
            <div class="skeleton skeleton-line brfull" style="--c-w: 100%; --l-h:14px;--c-p:0px;--bg: rgba(255,255,255,0.1); --t: 1s;"></div>
          </div>
        </div>
      </div>
    </div>
  `;
};

const renderSalaryDist = (
  salaryDist: string[] | undefined,
  isLoading: boolean
) => {
  if (!salaryDist) return '';
  return `
    <div class="plot-container">
      <div class="plot-box plot-box-bell">
        <div class="plot-title">Salary</div>
        <div class="plot-item">
          <div style="display: ${isLoading ? 'none' : 'block'};">
            ${
              isEmpty(salaryDist)
                ? `<div class="plot-no-data">No data at this time</div>`
                : `<svg
                  width="100%"
                  height="100%"
                  viewBox="0 0 155 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M77.5 1C55.641 1 43.7179 34 0 34H155C109.295 34 97.3718 1 77.5 1Z"
                    fill="url(#paint0_linear_3541_2187)"
                  />
                  <path
                    d="M77 1V34"
                    stroke="white"
                    stroke-opacity="0.3"
                    stroke-width="0.5"
                  />
                  <path
                    d="M100 12V34"
                    stroke="white"
                    stroke-opacity="0.3"
                    stroke-width="0.5"
                  />
                  <path
                    d="M123 27V34"
                    stroke="white"
                    stroke-opacity="0.3"
                    stroke-width="0.5"
                  />
                  <path
                    d="M54 12V34"
                    stroke="white"
                    stroke-opacity="0.3"
                    stroke-width="0.5"
                  />
                  <path
                    d="M31 27V34"
                    stroke="white"
                    stroke-opacity="0.3"
                    stroke-width="0.5"
                  />
                  <path
                    d="M155 34L4.84173e-06 34"
                    stroke="white"
                    stroke-opacity="0.3"
                    stroke-width="0.5"
                  />
                  <path
                    d="M0 34C43.7179 34 55.641 1 77.5 1C97.3718 1 109.295 34 155 34"
                    stroke="#48AFF4"
                    stroke-width="2"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_3541_2187"
                      x1="77.5"
                      y1="1"
                      x2="77.5"
                      y2="34"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#48AFF4" stop-opacity="0.5" />
                      <stop offset="1" stop-color="#48AFF4" stop-opacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
                <div class="plot-axis">
                  <div class="plot-axis-label" data-testid='td-tooltip-salary-dist'>${salaryDist[0]}</div>
                  <div class="plot-axis-label" data-testid='td-tooltip-salary-dist'>${salaryDist[1]}</div>
                  <div class="plot-axis-label" data-testid='td-tooltip-salary-dist'>${salaryDist[2]}</div>
                </div>`
            }
          </div>
        <div class="h56" style="display: ${isLoading ? 'block' : 'none'};">
          <div class="skeleton skeleton-line br2" style = "--c-w: 100%; --l-h:56px;--c-p:0px;--bg: rgba(255,255,255,0.1); --t: 1s;"></div>
        </div>
      </div>
    </div>
  `;
};

export const popupHTML = ({
  header,
  profiles,
  headcount,
  growth,
  postings,
  timeToFill,
  marketTightness,
  salaryDist,
  isLoading,
  height = 303,
}: popupHTMLGenInterface) => `
<div class="tooltip-td" data-testid="td-tooltip-container" ${height ? `style="height: ${height}px;"` : ''}>
  <div class="header">${header}</div>
    <div class="list-info">
      ${renderProfiles(profiles, isLoading)}
      ${renderHeadcount(headcount, isLoading)}
      ${renderGrowth(growth, isLoading)}
      ${renderPostings(postings, isLoading)}
      ${renderTimeToFill(timeToFill, isLoading)}
      <hr class="divider" />
      ${renderMarketTightness(marketTightness, isLoading)}
      ${renderSalaryDist(salaryDist, isLoading)}
    </div>
  </div>
`;
