import { useCallback, useEffect, useMemo, useState } from 'react';
import { POSTING_SOURCE_IDS } from '@revelio/data-access';
import { useSingleOrMoreFilterState } from '../../../engine/filters.engine';
import {
  LocalSelectionCategories,
  FilterItem,
  SelectFilter,
} from '../../../engine/filters.model';
import { upsertFilter } from '../../../engine/filters.repository';
import { SelectionListValue } from '../../selection-list';
import { OptionProps, SingleValue } from 'react-select';
import { TempSelections } from '../types';
import { getMetricModeOptions } from './metric-mode-options';
import { MetricModeOption } from '../../selection-list/metric-mode-option';
import {
  Option,
  SelectionListSelect,
} from '../../selection-list/selection-list-select';
import { SelectionListControls } from '../../SelectionListControls';
import { metricModeDefault } from '../../../engine/filters.constants';

export type MetricModeSelectionListProps = {
  close: () => void;
  handleFilterSubmit: (selections?: TempSelections) => void;
};

export const MetricModeSelectionList = ({
  close,
  handleFilterSubmit,
}: MetricModeSelectionListProps) => {
  const [value, setValue] = useState<SelectionListValue>([]);

  const [metricModeFilter] = useSingleOrMoreFilterState<
    FilterItem,
    SelectFilter<FilterItem>
  >(LocalSelectionCategories.METRIC_MODE);

  const [providerFilter] = useSingleOrMoreFilterState<
    FilterItem,
    SelectFilter<FilterItem[]>
  >(LocalSelectionCategories.PROVIDER);

  const isUnifiedPostingsSourceSelected = useMemo(() => {
    return providerFilter?.value?.some(
      (option) => option.id === POSTING_SOURCE_IDS.unified
    );
  }, [providerFilter]);

  useEffect(() => {
    const selectedOption = getMetricModeOptions().filter(
      (option) => option.value === metricModeFilter?.value?.id
    );
    setValue(selectedOption);
  }, [metricModeFilter]);

  const submitMetricModeSelection = () => {
    if (value.length) {
      const selections: TempSelections = value.reduce<TempSelections>(
        (acc, item) => {
          const id = `${LocalSelectionCategories.METRIC_MODE}.${item.value}`;
          return {
            ...acc,
            [id]: {
              item: {
                id: item.value,
                shortName: item.label,
                longName: item.label,
                label: item.label,
                isActive: true,
              },
              id,
              isMulti: false,
              children: [],
              selectionListId: LocalSelectionCategories.METRIC_MODE,
            },
          };
        },
        {}
      );

      handleFilterSubmit(selections);
      upsertFilter(LocalSelectionCategories.METRIC_MODE, {
        isUserSubmitted: true,
      });
    }

    close();
  };

  const onChange = useCallback(
    (selectedOptions: SingleValue<Option> | null) => {
      if (selectedOptions !== null) {
        setValue([selectedOptions]);
      }
    },
    [setValue]
  );

  const clearSelections = useCallback(() => {
    setValue([metricModeDefault.value]);
  }, [setValue]);

  return (
    <>
      <SelectionListSelect<false>
        defaultValue={[metricModeDefault.value]}
        value={value}
        onChange={onChange}
        options={getMetricModeOptions(isUnifiedPostingsSourceSelected)}
        components={{
          Option: (props: OptionProps<Option, false>) => (
            <MetricModeOption {...props} />
          ),
        }}
      />
      <SelectionListControls
        onSubmit={submitMetricModeSelection}
        submitText={metricModeFilter?.isUserSubmitted ? 'Update' : 'Add'}
        onClose={close}
        onClear={clearSelections}
      />
    </>
  );
};
