import { Flex } from '@chakra-ui/react';
import { SelectionListIdNames, TreeItem } from '../../../engine/filters.model';
import Tree from '../../tree/tree/tree';
import React, { Dispatch, useRef } from 'react';
import { Segment } from './segment-selection-form';
import { TreeMethodHandle, TreeType } from '../../collection';
import { SelectFooter } from '../select-footer';
import { useOnClickOutsidePopover } from '../../utils/useOnClickOutsidePopover';

interface SegmentTreeFormProps {
  selectedSegment: Segment | null;
  setSegmentSelection: Dispatch<
    React.SetStateAction<Record<string, TreeItem<string>>>
  >;
  initialSegmentValues: Record<string, TreeItem<string>>;
  onClickBack: () => void;
  onClickAddSegment: () => void;
  onClickClose: () => void;
}

export const SegmentTreeForm = ({
  selectedSegment,
  setSegmentSelection,
  initialSegmentValues,
  onClickBack,
  onClickAddSegment,
  onClickClose,
}: SegmentTreeFormProps) => {
  const handleClearSelections = () => {
    treeRef.current?.handleClearSelections?.();
  };
  const treeRef = useRef<TreeMethodHandle>();

  const componentRef = useOnClickOutsidePopover(onClickClose);

  return (
    <Flex direction="column" gap="4" ref={componentRef}>
      {selectedSegment && (
        <Tree
          forwardedRef={treeRef}
          selectionLists={selectedSegment.value as SelectionListIdNames[]}
          limit={20}
          setTempSelections={setSegmentSelection}
          height={220}
          defaultSelectedItemIds={Object.keys(initialSegmentValues || {})}
          nestingTreeType={TreeType.SUB_MENU_NESTED}
          showHeader
        />
      )}
      <SelectFooter
        onClearSelections={handleClearSelections}
        onClose={onClickBack}
        onAdd={onClickAddSegment}
        addLabel="Add Segment"
      />
    </Flex>
  );
};
