import {
  SelectionListIdNames,
  SelectionListItem,
  SelectionListItems,
} from '../../../engine/filters.model';

type GetSelectionListItemProps = {
  id: string;
  selectionLists: SelectionListItems[];
};

export const getSelectionListItem = ({
  id,
  selectionLists,
}: GetSelectionListItemProps):
  | (SelectionListItem & { selectionListId: SelectionListIdNames })
  | null => {
  const splitIds = id.split('_');
  const splitIdsLength = splitIds.length;
  const lastId = splitIds[splitIdsLength - 1];

  if (!lastId) return null;

  const selectionList = selectionLists[splitIdsLength - 1];
  const selectedItem = selectionList.value.find((item) => item.id === lastId);

  if (!selectedItem) return null;

  return { ...selectedItem, selectionListId: selectionList.id };
};
