import { useScreenerFilter } from '@revelio/filtering';
import { ScreenerDimension } from '@revelio/data-access';
import { Button, Icon } from '@chakra-ui/react';
import { FiDownload } from 'react-icons/fi';
import { serialiseScreenerFilters } from './screener-table/utils';
import { WithTooltip } from '@revelio/core';
import { useDownloadData } from '../../shared/hooks/useDownloadPlotData';
import { useUserTrialDetails } from '@revelio/auth';

interface DownloadScreenerButtonProps {
  view: ScreenerDimension;
}

export const DownloadScreenerButton = ({
  view,
}: DownloadScreenerButtonProps) => {
  const { state } = useScreenerFilter();

  const filters = serialiseScreenerFilters(state.filters, view);

  const { isTrialUser } = useUserTrialDetails<boolean>({
    initialRenderValue: false,
  });
  const { triggerDownload, isLoading } = useDownloadData({
    downloadPath: `api/v2/screener/downloads`,
    serialisedFilters: {
      ...filters,
      pagination: { page: 1 },
      sorting: state.sorting,
    },
  });

  if (
    Object.keys(filters.primary_selector)?.length === 0 &&
    Object.keys(filters.segments)?.length === 0 &&
    Object.keys(filters.entity_info_selectors?.company_info_selectors || {})
      ?.length === 0
  ) {
    return null;
  }

  return (
    <WithTooltip
      showCondition={isTrialUser}
      placement="top"
      variant="label"
      padding="10px 15px"
      label="This feature is not available for trial users"
      aria-label={`This feature is not available for trial users`}
    >
      <Button
        leftIcon={<Icon as={FiDownload} boxSize={3.5} />}
        size="sm"
        variant="white"
        onClick={triggerDownload}
        height="24px"
        data-testid="download-screener-data-button"
        isDisabled={isTrialUser}
        isLoading={isLoading}
        loadingText="Preparing Download..."
      >
        Download Data
      </Button>
    </WithTooltip>
  );
};
