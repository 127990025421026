import { useMemo, useState, useEffect } from 'react';
import { useToast, ModalFooter, ModalBody } from '@chakra-ui/react';
import { isEmpty, mapKeys } from 'lodash';
import styles from './BreadcrumbTreeFilter.module.css';
import { SelectionCategories, TreeItem } from '../../engine/filters.model';
import { BreadcrumbTree, BreadcrumbTreeProps } from './BreadcrumbTree';
import { useSelectionListsValidated } from '../../engine/filters.engine';
import { getNestedId, nestSelectionLists } from '../tree';
import { SelectionListControls } from '../SelectionListControls';

type BreadcrumbTreeFilter = Pick<BreadcrumbTreeProps, 'branches'> & {
  selectionListIds: SelectionCategories[];
  initialSelections?: Record<string, TreeItem>;
  maxSelections?: number;
  filterLabel?: string;
  onClose?: () => void;
  onSubmit?: (items: Record<string, TreeItem>) => void;
  setNumSelections?: (num: number) => void;
};

export const BreadcrumbTreeFilter = ({
  selectionListIds,
  initialSelections = {},
  branches,
  maxSelections,
  filterLabel,
  onClose,
  onSubmit,
  setNumSelections,
}: BreadcrumbTreeFilter) => {
  const [selections, setSelections] = useState<string[]>([]);

  useEffect(() => {
    if (setNumSelections) {
      setNumSelections(selections.length);
    }
  }, [selections, setNumSelections]);

  const toast = useToast();

  const onChange = (value: string[]) => {
    /** if we are over the max selections, and max selections is not === 1 (single select)
     * show a warning toast that we are over the limit */
    if (maxSelections && maxSelections > 1 && value.length > maxSelections) {
      if (!toast.isActive('max-selections-toast')) {
        toast({
          id: 'max-selections-toast',
          position: 'top-right',
          status: 'warning',
          title: `${filterLabel ? filterLabel : 'Selection'} Limit`,
          description: `You can only choose up to ${maxSelections} ${filterLabel ? filterLabel : 'items'} at a time!`,
          isClosable: true,
          variant: 'subtle',
        });
      }
      return;
    }

    // otherwise, set the selections
    setSelections(value);
  };

  const selectionLists = useSelectionListsValidated(selectionListIds);

  useEffect(() => {
    /** map initial tree selections to nestedIds
     * then setSelections */
    const applicableSelections = Object.values(initialSelections).filter(
      (item) =>
        selectionLists
          .map((list) => list.id)
          .includes(item.selectionListId as SelectionCategories)
    );
    const nestedIds = applicableSelections.map((item) =>
      getNestedId({ selectionLists, item })
    );
    setSelections(nestedIds);
  }, [initialSelections, selectionLists]);

  const nestedSelectionTree = useMemo(
    () => nestSelectionLists(selectionLists),
    [selectionLists]
  );

  const onClear = () => {
    setSelections([]);
  };

  const handleSubmit = () => {
    const selectedTreeItems: TreeItem[] = selections
      .map((id): TreeItem | null => {
        const splitIds = id.split('_');
        const splitIdsLength = splitIds.length;
        const lastId = splitIds[splitIdsLength - 1];

        if (!lastId) return null;

        const selectionList = selectionLists[splitIdsLength - 1];
        const selectedItem = selectionList.value.find(
          (item) => item.id === lastId
        );

        if (!selectedItem) return null;

        return {
          id: selectedItem.id,
          item: selectedItem,
          selectionListId: selectionList.id,
          children: [],
        };
      })
      .filter((entity) => entity !== null);

    const selectionsObject = mapKeys(
      selectedTreeItems,
      (value) => `${value.selectionListId}.${value.id}`
    );

    onSubmit?.(selectionsObject);
  };

  const isSubmitDisabled = useMemo(() => {
    return isEmpty(selections);
  }, [selections]);

  return (
    <>
      <ModalBody py="16px">
        <BreadcrumbTree
          data={nestedSelectionTree}
          value={selections}
          branches={branches}
          onChange={onChange}
          height={320}
          singleSelect={maxSelections === 1}
        />
      </ModalBody>
      <ModalFooter width="100%" borderTop="1px solid #E5EBF1">
        <SelectionListControls
          className={styles.controls}
          onClear={onClear}
          onClose={onClose}
          onSubmit={handleSubmit}
          clearText="Reset"
          submitText="Submit"
          isSubmitDisabled={isSubmitDisabled}
        />
      </ModalFooter>
    </>
  );
};
