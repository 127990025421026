import { MetadataKey, useAuthStore } from '@revelio/auth';
import { getMetadataValue } from '../../adminRewrite/utils/helpers';

export const useSourceWhaleApiKey = () => {
  const { user } = useAuthStore();
  const apiKey =
    getMetadataValue(user?.metadata, MetadataKey.SourceWhaleKey) || null;

  return {
    isEnabled: apiKey !== null,
    apiKey,
  };
};
