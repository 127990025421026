import { Item } from '@revelio/filtering';
import { fromPairs } from 'lodash';

export const repvueRCIDsFilter = (item: Item) => {
  return REPVUE_RCIDS[String(item.id)];
};

const REPVUE_RCIDS = fromPairs(
  [
    7484845, 500931, 9310, 1044059, 265070, 20961664, 805278, 22142112, 867471,
    1481814, 577402, 358821, 836300, 965779, 529106, 548362, 46473, 1291101,
    208100, 995410, 19139786, 1136950, 800198, 110582, 1214068, 1226323,
    22183980, 1430675, 35016, 739126, 12324302, 1402227, 81368, 1203182, 35976,
    960067, 709703, 293064, 996881, 242888, 426015, 519559, 1321763, 22226648,
    891187, 99282, 22251698, 1141065, 803850, 22177620, 20930257, 27729930,
    1256989, 21047059, 677192, 605834, 54436, 114748, 22159668, 652034, 1169948,
    1208331, 1265001, 1248354, 1191500, 307900, 485560, 1027636, 1013819,
    583855, 239682, 1430738, 3355, 6347770, 380732, 53775, 22173634, 21022868,
    785809, 110490, 488262, 698690, 651390, 934621, 484378, 193115, 22247412,
    22238486, 1140427, 118149, 1104097, 705093, 715974, 211862, 6417, 206996,
    11731503, 1240150, 372336, 594175, 54285, 907674, 1161594, 161469, 3536155,
    936378, 665970, 1037748, 1013227, 1442530, 1400574, 23927360, 7646243,
    874293, 22258597, 1121881, 1178109, 1489168, 1060443, 659723, 822237,
    22142727, 79329, 737331, 1277858, 193319, 7996892, 8080702, 809141, 7956567,
    368774, 16079069, 302182, 7832420, 499893, 815731, 465681, 445587, 678932,
    676007, 267250, 1425207, 24146, 763078, 2636440, 710644, 31845, 1210370,
    22242854, 215536, 794951, 1097474, 771336, 1123339, 591290, 327547, 75066,
    39113, 286667, 197273, 1522722, 1342433, 319700, 1099481, 554373, 26241,
    608563, 29914, 785865, 10141573, 117138, 899839, 1032145, 746873, 584250,
    1345763, 546625, 1158300, 392057, 22262638, 714744, 428877, 1039125, 15509,
    413761, 1082586, 1285902, 2104, 393528, 259080, 97840, 1026680, 73962,
    365544, 22788306, 7867377, 371695, 1270445, 152089, 1019590, 1345906,
    566029, 1089316, 296301, 986341, 31555, 345547, 1326617, 20923715, 502284,
    173081, 635298, 1289223, 979399, 765869, 482878, 882078, 1478370, 1127936,
    184945, 316012, 267301, 286170, 762166, 863390, 11189368, 548502, 7762171,
    849832, 627898, 624383, 595996, 233062, 491130, 1020191, 22142190, 985635,
    1304352, 1061141, 321247, 970592, 714715, 194207, 287048, 502241, 896036,
    366870, 745764, 281774, 265362, 7896166, 709686, 287399, 768428, 1241550,
    678157, 713999, 550919, 11648590, 509466, 1488387, 874149, 22261860, 502321,
    797234, 21771383, 692396, 828444, 718923, 432365, 1223575, 1158503, 7760921,
    759755, 916956, 22694264, 556712, 673672, 864909, 735311, 1336661, 836615,
    219817, 637618, 7708737, 1029296, 603005, 7841618, 1029210, 406488, 744395,
    8052573, 748512, 1187175, 1072512, 200270, 26796085, 906990, 1142983,
    8066183, 301362, 1276522, 1290858, 1296160, 1191683, 1177074, 801390,
    1046359, 928006, 204119, 7617732, 423021, 455634, 369884, 978030, 570041,
    668611, 603191, 621364, 1060666, 186938, 166350, 884409, 22219647, 1330216,
    7760842, 1165208, 334347, 1162968, 245436, 1216897, 929789, 184698, 1072323,
    13445, 430889, 741158, 1139932, 744069, 161829, 1049506, 1249638, 419353,
    733218, 414316, 7519973, 476289, 670776, 798302, 81479, 965075, 267513,
    178012, 376276, 612916, 400527, 831947, 14852, 1376003, 621684, 331159,
    1257996, 303524, 3551528, 1222658, 1405958, 684770, 971856, 866194, 1291854,
    1221768, 447263, 555645, 1070003, 1129435, 7750714, 62106, 1168535, 337899,
    357873, 1302253, 1244001, 7760816, 174981, 20921708, 677120, 22984222,
    1318144, 20957436, 21237, 750615, 553813, 20922659, 1300095, 1122311,
    147878, 399505, 676626, 443193, 1189021, 972441, 773513, 374398, 1326290,
    162834, 187745, 1151929, 578423, 309248, 647894, 30828, 1085585, 1269309,
    295486, 395072, 1055665, 1087009, 566265, 22226748, 1287028, 386318, 809347,
    704021, 382720, 804707, 1233225, 1103481, 489272, 376082, 360462, 656869,
    92777661, 1400269, 7883071, 1365353, 550328, 592179, 22243082, 1134469,
    1311350, 736220, 366678, 879602, 53691, 20922195, 178346, 1311281, 468391,
    5336534, 693669, 1480098, 746622, 21274357, 989880, 690100, 1390084,
    1422815, 600505, 773045, 639710, 225321, 419667, 700260, 523467, 1317378,
    20038, 1127337, 608035, 355324, 500864, 206421, 808126, 1261137, 22250475,
    1112751, 1306805, 21820170, 1464419, 712845, 13704, 1186061, 1575679,
    5160905, 7882857, 973451, 980528, 1128352, 11499, 1043762, 1068821, 369460,
    606289, 306587, 699819, 687182, 20994354, 7485714, 677832, 22148737,
    1374849, 270072, 1260867, 6009653, 218, 347029, 22182821, 436978, 92777382,
    1487573, 247772, 840459, 266247, 288028, 1286288, 810001, 119622, 308907,
    958679, 672075, 1653234, 37937, 96924689, 158434, 1186389, 290549, 941874,
    117636, 477639, 1269454, 6968218, 963802, 7756510, 23223115, 700151,
    22159428, 949298, 92811149, 119214, 22158563, 814216, 186543, 414803,
    1083697, 1359285, 500622, 971288, 134629, 15755, 715757, 200212, 1169409,
    698426, 187670, 656743, 510756, 942416, 620767, 516311, 914923, 993996,
    1475701, 1400009, 794299, 1088125, 1049656, 275866, 1159802, 21131614,
    598113, 1155055, 453342, 63172, 1149690, 1017034, 21052708, 247750, 7712818,
    369333, 22665710, 177243, 1012881, 453143, 7674950, 955350, 317822, 104448,
    903794, 869091, 786950, 1339438, 150054, 730038, 1283875, 320517, 1139960,
    1018914, 122881, 573385, 7969086, 1140760, 1376927, 4360968, 462023, 51377,
    1032978, 948432, 22242248, 739347, 17148592, 1297153, 89833, 136871,
    1029972, 20931381, 1466807, 93987438, 900460, 13172, 141935, 526047,
    7646128, 477137, 1008117, 770049, 705400, 769314, 640959, 267552, 20929327,
    1005150, 3751273, 7783228, 635356, 7917, 1231451, 448554, 98289, 269808,
    1065016, 1049595, 1211143, 1013618, 671503, 388017, 439304, 462850,
    23647053, 123431, 1182394, 7461849, 395122, 1229661, 77657, 1216985, 712971,
    327957, 7546975, 9203751, 794059, 896950, 1059458, 1236558, 1373927,
    20994677, 1178915, 15583, 956763, 816880, 420392, 794334, 8663755, 1334278,
    665801, 1211108, 958482, 1231114, 898042, 83358, 625726, 1096494, 16145114,
    1287152, 1105526, 109953, 157995, 22167550, 359317, 811612, 372376, 1456492,
    1062201, 3362, 1012658, 1492897, 1452181, 944927, 693463, 863339, 772009,
    1097713, 22371001, 7877811, 347904, 962574, 1146667, 1058510, 219997,
    491197, 21048901, 1071570, 5192123, 5778710, 1214503, 895176, 876636,
    131133, 1289793, 1024115, 262314, 766779, 15253643, 338315, 15486893, 88530,
    1236502, 776811, 740484, 324289, 1369147, 1265405, 419289, 1255545, 1354369,
    1439879, 781221, 1076374, 795889, 3319882, 891626, 820766, 516698, 470287,
    7477628, 358422, 1489615, 7646304, 296936, 922682, 813334, 23454478,
    7859291, 874029, 769087, 815311, 692801, 853728, 960216, 22141982, 559240,
    619751, 110915, 8078359, 19470943, 395618, 675805, 5721195, 741054, 341090,
    1463940, 4757807, 951871, 426968, 288242, 136446, 957413, 310886, 1239499,
    7668245, 1242380, 772858, 604981, 7798971, 575518, 1241049, 831322,
    20930518, 33442, 869582, 682278, 1012746, 22223489, 1137474, 501454,
    10769675, 1538302, 20921942, 913399, 1130317, 365874, 1007812, 429010,
    1165679, 22149913, 716827, 270014, 47852, 82512, 538848, 768097, 1020885,
    96028381, 326768, 1336958, 757752, 51154, 22269318, 460720, 436191, 696397,
    19888582, 1106413, 1445017, 20923137, 766625, 7961131, 620082, 90761,
    778926, 933997, 184832, 66037, 334767, 916421, 1411246, 922629, 1314385,
    1095464, 572657, 446078, 756811, 410941, 67675, 438605, 294084, 1238575,
    7796416, 653317, 7695451, 532351, 1173310, 1064167, 1638625, 527754, 222592,
    221321, 177996, 993423, 490760, 1190871, 267475, 808044, 7860514, 1139894,
    687075, 671178, 946521, 574538, 368169, 7717919, 94933564, 783871, 283854,
    1087239, 98568, 1084081, 331427, 889810, 7520029, 7421683, 1219528, 1246452,
    1278324, 759700, 832856, 7681630, 1173596, 7753874, 967574, 765049, 38396,
    1410622, 1272470, 490415, 537526, 83643, 672703, 786030, 102145, 14274,
    23294, 370790, 1420830, 471698, 256682, 262398, 53742584, 234927, 1009199,
    18883, 359425, 968716, 560722, 1347700, 7520617, 561828, 36389, 169377,
    807572, 906505, 313151, 183627, 7646826, 1266498, 1207995, 987707, 20963054,
    22147128, 1010813, 1070638, 1242360, 1221902, 1049114, 1080483, 1166215,
    897674, 1148464, 6183879, 1269645, 407298, 378883, 1314485, 307625, 1188943,
    22142783, 1272463, 1094814, 22142047, 698362, 1335800, 1176247, 1199487,
    669883, 22178396, 13556053, 7852567, 1011447, 2160636, 430606, 279872,
    849366, 628254, 200000, 1474629, 697220, 919186, 1230433, 413038, 510536,
    1359692, 669636, 335586, 384504, 1188058, 1415179, 741254, 881178, 1207154,
    1212471, 350953, 209675, 3662667, 1329535, 7659873, 22182674, 373246,
    438473, 7718358, 1058117, 20203, 919809, 344364, 1112079, 913631, 652578,
    85705, 489734, 7671080, 628942, 22216945, 636666, 639172, 154670, 626959,
    892139, 506513, 96515660, 77087, 18354521, 7951587, 7646174, 90582436,
    534729, 20928872, 19194, 625145, 109401, 1393437, 1178398, 1238679, 57280,
    20924380, 1301166, 21013947, 7824655, 132249, 1307463, 28217, 219364,
    932399, 389746, 196491, 102162, 22150407, 1453808, 472640, 293901, 486990,
    1013753, 245798, 133343, 725751, 22142476, 543726, 21371591, 608478, 187634,
    508109, 259010, 262613, 1176313, 826949, 1121744, 1239035, 1192920, 976079,
    645881, 640572, 762560, 21140963, 348323, 666163, 1157843, 1028530, 1450831,
    954203, 600485, 1248510, 8061619, 336691, 346494, 822918, 22150786, 407798,
    582240, 1201694, 669389, 1266369, 485950, 196294, 1223923, 745135, 991338,
    823649, 944978, 21013263, 443475, 817356, 1323360, 826769, 1134784, 233459,
    1146079, 860320, 815031, 1121147, 1357307, 654258, 1143189, 92808716,
    239426, 17781871, 861262, 1351499, 248474, 1256784, 342384, 22269346,
    603001, 509865, 22231334, 182459, 830265, 16003, 953215, 22230201, 1230089,
    548872, 1374720, 55523, 1313891, 245012, 720125, 1196823, 968433, 1025806,
    40101467, 7891987, 346411, 20928263, 7764797, 644910, 375438, 177386,
    450631, 1430146, 65765, 1471087, 604502, 759493, 21409435, 858536, 7657177,
    84064, 1462541, 741476, 1019874, 21038694, 739092, 1297106, 1242266, 452754,
    1118052, 648289, 677217, 837072, 76202, 894061, 283054, 21035979, 22142913,
    929858, 478953, 7706085, 547343, 54279, 802397, 978469, 646638, 1158271,
    645732, 622012, 484262, 753532, 877644, 1083336, 24835, 282316, 1419983,
    831969, 1063072, 1121534, 1079314, 513460, 708211, 2376186, 22244116,
    552797, 161544, 41776, 1142109, 914315, 234540, 560872, 462506, 937415,
    7654233, 164308, 1255770, 620065, 22146563, 7880178, 1375528, 773760,
    1210129, 818302, 1296213, 638951, 580224, 403968, 272202, 184838, 381043,
    730062, 7763750, 14992503, 188011, 6714051, 500585, 344532, 366637, 799144,
    1075458, 22150636, 786986, 1047994, 1338893, 1169594, 998072, 777168,
    479796, 852825, 421002, 439890, 577381, 1197560, 732042, 879895, 775484,
    952182, 881400, 1359215, 1489790, 831608, 1564826, 1321206, 727481, 743976,
    877410, 407832, 1306158, 862995, 196290, 1134821, 22142761, 775653, 679909,
    22148380, 1372812, 21027110, 820084, 21398043, 246464, 1144196, 1058378,
    896789, 7963787, 564233, 583440, 510394, 141899, 709929, 247416, 865092,
    2035696, 1161992, 1105817, 633950, 1057249, 1268997, 247697, 140436, 25416,
    700286, 492358, 853880, 475176, 210358, 432320, 1101379, 23473012, 447505,
    1132134, 1126900, 3492612, 1262278, 20930481, 12315062, 393029, 16618,
    879099, 20954281, 314257, 269874, 1022769, 26894529, 22253206, 1214223,
    656100, 501382, 249488, 56449, 1190631, 20514864, 323939, 294995, 712929,
    547550, 925445, 7727701, 886016, 348342, 609520, 383532, 606053, 126106,
    22248004, 496894, 1066592, 6648, 49162, 1216142, 322221, 1083342, 606304,
    652517, 381112, 1151438, 27786207, 21017609, 1136838, 1017918, 336809,
    11822681, 580315, 555059, 222610, 1142228, 1321546, 1002610, 1116644, 55690,
    81401692, 364060, 507167, 1073501, 895889, 582349, 458680, 1022671, 815664,
    779593, 6518, 699705, 1009093, 9624124, 349908, 1138286, 543448, 548357,
    959073, 584818, 21012406, 1096745, 21007446, 1382, 626225, 1095919, 1093338,
    22184281, 2397100, 20921451, 994684, 1318635, 485765, 543640, 844986,
    332619, 1291414, 469597, 7862903, 744717, 1057345, 14656219, 716841, 186769,
    7881403, 588488, 708485, 383326, 1311722, 441320, 934365, 254822, 270558,
    656613, 780555, 755148, 505274, 858455, 683719, 1395704, 304815, 669531,
    951441, 249962, 513805, 1012780, 929633, 906399, 1164860, 7689819, 878620,
    1324647, 775676, 761785, 1384421, 380564, 309030, 247454, 489098, 84351,
    1090206, 549963, 17229955, 76915, 622622, 6026, 195523, 976078, 44109,
    7969203, 16863082, 596634, 931638, 1326102, 254456, 7522051, 95085075,
    1187079, 522054, 1052813, 802437, 1258704, 638902, 739686, 372782, 83100,
    7494, 672131, 601111, 854168, 1205892, 268499, 122492, 633662, 1292613,
    210447, 995112, 821239, 792782, 1002125, 981501, 645392, 1058439, 20930457,
    1011768, 23456686, 746907, 1242196, 1237501, 602994, 691168, 32275,
    15824885, 516597, 1031006, 401234, 647140, 1401341, 1076393, 219678,
    22251970, 1037059, 1409113, 914117, 343509, 1282370, 7719488, 756685,
    649208, 34616, 22168659, 723539, 1148999, 1444677, 717553, 874972, 141432,
    1042388, 505355, 628360, 511228, 1465508, 112959, 1271407, 7858153, 155536,
    1370, 7546100, 1485785, 480155, 331805, 146397, 657684, 1351300, 1243864,
    335105, 322514, 818205, 3074542, 752913, 397333, 48468926, 614853, 1137418,
    1436618, 1378365, 929741, 401487, 1048824, 1019795, 1034708, 1501773,
    637040, 1042880, 1150829, 981618, 1448313, 459850, 22151265, 7830934,
    824430, 6235077, 22247196, 1264390, 92778931, 238090, 7375, 8035136, 185214,
    331945, 21022660, 1109560, 1137889, 1271016, 356878, 22186750, 22238090,
    493323, 935110, 332815, 70328, 875666, 780253, 252168, 538792, 22263341,
    111115, 1193920, 1603922, 473701, 3009465, 455385, 1089041, 7651857, 304787,
    8047187, 601080, 970461, 544878, 627502, 415931, 1221615, 606921, 168154,
    22142596, 96738090, 400585, 507774, 1057699, 1210778, 1029007, 21012165,
    545417, 1187448, 803929, 957136, 485234, 720054, 1255750, 1313278, 368418,
    94085, 234399, 1145182, 649365, 7498338, 386950, 517680, 1452671, 54855,
    691192, 154502, 382365, 579347, 1013055, 756980, 192653, 1168823, 35612,
    1091551, 22210738, 163383, 1095585, 494205, 1240198, 17894532, 287534,
    1069687, 40874910, 1190456, 470932, 390930, 409295, 1267152, 360437,
    1140437, 669161, 397335, 416629, 686415, 7523059, 1425861, 210685, 1085814,
    1022572, 20929890, 1426498, 573610, 626553, 599072, 550031, 8250, 348298,
    757184, 1386894, 7969251, 550680, 673681, 22182261, 822591, 1262506, 25497,
    22142328, 25180967, 340167, 9187697, 365964, 11358, 494178, 385412, 137987,
    22145648, 687961, 1257565, 1051895, 1023265, 7800547, 7860023, 451906,
    1080732, 924161, 519690, 141974, 1115563, 7763487, 1196578, 865958, 1304472,
    273865, 23032754, 277595, 77051, 243687, 1126333, 1287055, 1015017, 88455,
    354334, 1300458, 95937, 274544, 815969, 10246322, 1456099, 1310725, 7618984,
    10736817, 7538240, 454681, 873224, 215904, 806273, 230528, 1412417, 620115,
    6560976, 681040, 612997, 1475340, 799, 1258532, 1145284, 39768, 511908,
    3500, 212692, 271586, 26985297, 573442, 22268758, 415334, 903532, 1307392,
    642342, 1050907, 930316, 387112, 22217582, 344897, 1074082, 1008825,
    1137778, 7763103, 242251, 1076423, 780364, 1070575, 747858, 312963, 599292,
    311348, 22259190, 1413455, 659936, 621960, 906508, 347418, 963919, 7470095,
    49367, 992659, 204261, 1495078, 1463354, 1233178, 297823, 782961, 1140871,
    20995810, 1243535, 19140272, 749825, 667712, 1207237, 322541, 771909,
    1047558, 372387, 172980, 978225, 388447, 849857, 92840, 1364670, 20991,
    402764, 1191828, 578396, 367531, 92806310, 951665, 50627, 721173, 273641,
    1294260, 382012, 265056, 1070814, 1351205, 21047943, 941500, 359449, 978438,
    160136, 826467, 80757, 22224049, 256277, 1217989, 160446, 185477, 1117144,
    1254077, 1128016, 1405877, 876980, 500126, 21928618, 176377, 751003, 266181,
    1128236, 968536, 44983, 1493574, 508665, 489110, 1425713, 1344832, 1037635,
    1144865, 113647, 1052109, 1114699, 1065631, 1462736, 846991, 119655, 288828,
    7397831, 906543, 1061142, 1062930, 22142390, 740569, 21566684, 68099,
    1294789, 1341444, 435115, 517220, 22269023, 1489424, 22169941, 236471,
    95959, 307835, 1123716, 220528, 532877, 624640, 1231199, 773196, 409938,
    638477, 383295, 7570086, 284544, 914616, 246265, 443107, 672103, 7875355,
    456624, 442116, 1421338, 11141470, 2894, 116063, 1351753, 627319, 426014,
    1232095, 867359, 287155, 960028, 707102, 142839, 445290, 903784, 22208096,
    290866, 19319448, 8084102, 929275, 480497, 4684804, 20953809, 1360810,
    1363321, 24558, 754391, 867590, 1601535, 766211, 332992, 1023967, 822684,
    918353, 1018982, 91673658, 463718, 1108197, 743137, 1387355, 1232055,
    903518, 482258, 39019, 12941645, 193564, 43168, 7852358, 225787, 1191416,
    445963, 513930, 54065, 860528, 413072, 21022337, 1373217, 304160, 1292460,
    1038647, 1106253, 230146, 89925, 1400745, 1483487, 1166597, 1169463,
    1467860, 626402, 398186, 7546695, 341072, 1058616, 504111, 20930340,
    1162996, 22058347, 1099054, 630870, 608767, 758368, 7494248, 312735, 525318,
    852857, 1156951, 1326039, 20959393, 630562, 23457970, 829185, 7041990,
    262646, 196508, 905996, 793545, 1425674, 1166779, 410825, 68446, 22264613,
    1258174, 560591, 22179102, 442731, 277170, 1092726, 1086162, 706923,
    20929698, 120919, 518303, 190799, 494984, 3973, 22142245, 102118, 175539,
    148641, 1527722, 1264768, 1247645, 564758, 86741, 729591, 435819, 22147223,
    7561798, 431194, 923586, 1094630, 22147382, 8881265, 143033, 87984, 691613,
    1027406, 7546002, 1200034, 616287, 14292247, 1468588, 1452354, 818652,
    510585, 1405553, 224648, 143826, 928148, 1282996, 1051356, 392937, 98570638,
    911200, 923614, 859211, 1442074, 343526, 437969, 13203637, 1270063, 478849,
    1064162, 1375404, 447749, 7476521, 1190398, 1186053, 1248830, 929005,
    639377, 589927, 20928147, 1027863, 642964, 893782, 688798, 250717, 259779,
    1230770, 22223327, 1196688, 1233227, 305430, 1001360, 1435623, 2481197,
    6189881, 306104, 5731840, 733694, 1294922, 521069, 869205, 745618, 1361982,
    979428, 341413, 383268, 702752, 7952592, 490087, 21017747, 335312, 233467,
    58921, 284956, 22227490, 1467907, 1360734, 305012, 929895, 7775058, 702652,
    1254031, 1269148, 1397161, 59205, 384363, 179009, 856215, 8710130, 1141101,
    1126553, 806534, 189982, 22245575, 922343, 300294, 251540, 18079, 7760808,
    294614, 9523, 542946, 20937770, 152906, 64437, 619724, 837993, 872379,
    1222178, 22143924, 674, 381667, 815035, 1095312, 540826, 286218, 1033640,
    1167506, 1406740, 994815, 896639, 7178479, 438861, 959694, 1131032, 783388,
    745084, 609179, 1315129, 357097, 21023192, 1182966, 22255773, 832518,
    920687, 321480, 4939, 535902, 972304, 527018, 76050, 502654, 7646545,
    1260583, 15602770, 7834679, 20998927, 1390353, 1394564, 1304689, 53550,
    704256, 24546, 641698, 918209, 1051521, 599390, 378802, 91588, 1306851,
    110518, 147797, 22243128, 672859, 700718, 477646, 614349, 223777, 1354841,
    1255299, 3925969, 238710, 21041371, 1372505, 880140, 487213, 7750244,
    1496843, 557293, 449546, 18431, 307693, 7701608, 580819, 561336, 396601,
    979234, 468297, 484136, 1012636, 652290, 842731, 610752, 1654455, 395854,
    1215450, 1342061, 1237269, 1017958, 22142569, 992277, 20767136, 539822,
    425258, 957177, 764393, 1211583, 22238576, 7929126, 366242, 495823, 2237326,
    518897, 16641360, 704860, 29551, 928149, 797490, 1316608, 745033, 66723,
    212011, 500528, 1247236, 2400, 324186, 1347046, 541751, 561139, 462363,
    70936, 1150860, 20924531, 895916, 210915, 332803, 707908, 756551, 7850061,
    24494111, 471501, 1419934, 608490, 1144595, 610676, 7535748, 755828, 948506,
    370409, 7810966, 287573, 1033568, 327746, 1497047, 18705366, 726019, 286162,
    76278, 162314, 759336, 46736, 236396, 323008, 7746915, 7670316, 1058490,
    1303134, 825247, 155638, 1092583, 935804, 236032, 612890, 640685, 1176144,
    933094, 764484, 594101, 232088, 387716, 332330, 356932, 24955617, 441784,
    559742, 269931, 22156766, 977724, 21046573, 118310, 1478706, 103941,
    20189867, 1456403, 738252, 294863, 493504, 231858, 22221434, 795425,
    1332831, 300810, 3542747, 94615803, 851738, 229820, 87553, 1092368, 1257614,
    1199522, 26919201, 1118688, 206659, 381413, 3883219, 101626, 45970, 7495075,
    964073, 6013298, 1045302, 7968862, 146162, 22232565, 115177, 993115, 593075,
    76114, 1079156, 205268, 776526, 308573, 982268, 1392659, 22228591, 589762,
    37972, 7473292, 465081, 409526, 525718, 506494, 645563, 195887, 7395140,
    849315, 1085769, 1016924, 1401946, 683875, 677347, 7503600, 548042, 742913,
    539437, 645089, 1170377, 1189860, 307586, 164597, 1411136, 1216707, 6611660,
    867861, 1423179, 778643, 21011206, 725591, 823595, 620441, 1292986, 977427,
    1355953, 392383, 790226, 197301, 23060936, 1164310, 22147647, 852340,
    655043, 544287, 7738099, 806600, 236781, 1424661, 1338270, 1259600, 7561935,
    1104429, 895155, 905447, 346438, 5918427, 20518145, 7835979, 931049,
    1013811, 1328544, 729294, 1279430, 629833, 1040935, 280060, 135490, 1416122,
    816845, 984140, 938163, 840862, 1102980, 147308, 1118920, 1016049, 1313769,
    96498622, 22142648, 255460, 872414, 1078837, 695107, 1204064, 245963,
    525397, 660036, 911449, 322656, 27698147, 19458804, 509100, 986459, 934563,
    1352133, 3316133, 1210978, 1364486, 136662, 1035049, 1456615, 948003,
    876295, 4372885, 287548, 8038658, 1058753, 302060, 497946, 22142633, 198410,
    617490, 166122, 314004, 187881, 241592, 141825, 31936, 1336694, 196042,
    1181303, 96737402, 69621, 1297170, 980160, 698139, 607933, 409465, 1010588,
    956269, 1333984, 975602, 7520809, 489417, 1153091, 933343, 22947061,
    7905311, 376033, 551109, 1387761, 980775, 157694, 1321334, 1121349,
    21024968, 185084, 7835386, 634123, 1425251, 50506, 837200, 1330022, 1517558,
    81110, 20921455, 861490, 706703, 243587, 1366373, 1398143, 949896, 462650,
    22681215, 122439, 74030, 121548, 514226, 693695, 1191139, 1650280, 98411,
    1070920, 1292779, 22150963, 11524, 1350809, 970845, 1302548, 75650, 272654,
    1086887, 21048729, 319336, 22238491, 2066574, 4193, 1122375, 868335, 709173,
    535048, 698173, 8000623, 279840, 223301, 690439, 1269630, 441660, 1901502,
    7660304, 824854, 887216, 1264130, 565635, 116091, 501613, 22251879, 9119130,
    1361101, 26445360, 334785, 1208913, 1039502, 8054215, 7471436, 8029209,
    990609, 1244395, 793427, 1348012, 370574, 722284, 971904, 614617, 1337447,
    145465, 7341873, 1136392, 958287, 1192326, 1015103, 7193293, 974470, 85295,
    49797206, 3919144, 367642, 1094585, 22145268, 22480560, 1100671, 862591,
    125101, 338370, 691774, 8111607, 599466, 1419715, 72884, 1332282, 562204,
    1315467, 1199369, 1123794, 1270477, 761210, 663534, 898815, 1167341, 740384,
    39836, 23319, 270599, 59669, 1085479, 99161, 5493441, 1186033, 8002708,
    125135, 1413175, 606871, 390060, 45577, 183382, 7646741, 655762, 876608,
    906171, 244369, 600548, 523898, 1037905, 577121, 1729800, 829986, 1301557,
    370569, 388984, 498830, 171190, 8083388, 1143155, 1391399, 5975097, 703565,
    7872232, 531110, 803508, 910208, 391795, 1168432, 1038667, 580439, 1105324,
    605043, 218726, 93406274, 1337952, 381537, 624552, 913287, 1262916, 343942,
    52413, 873895, 8447684, 1331055, 154734, 262760, 251578, 1225244, 1056210,
    604422, 437269, 296579, 1364064, 922173, 705146, 653605, 1307708, 1276161,
    560396, 1509464, 1005273, 1223822, 5788758, 1334877, 20922684, 1439040,
    214866, 161034, 775575, 73856, 806061, 7716135, 350267, 16588, 436915,
    1415359, 878787, 986188, 1676249, 1346325, 1425831, 888151, 7403406, 841425,
    1340365, 963400, 239196, 1082656, 963502, 1223555, 630539, 218642, 7962497,
    251904, 361507, 210318, 65154, 753408, 204026, 2671905, 418894, 363756,
    810207, 97906, 397921, 1427219, 241662, 311965, 21785916, 1214980, 1444104,
    71110, 502745, 19442778,
  ].map((id) => [String(id), true])
);
