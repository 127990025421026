import {
  DATE_FORMAT_WITH_DAY,
  STANDARD_DATE_FORMAT,
  write,
} from '@revelio/core';
import { PipelineType } from '@revelio/data-access';
import { DateRangeValue } from '@revelio/filtering';
import { format } from 'date-fns';
import { isEqual } from 'lodash';
import { useCallback, useMemo } from 'react';
import { DatasetDateFilter, Deliverable } from '../../../deliverables.model';
import { updateDraftDeliverable } from '../../../deliverables.repository';
import {
  dateFromMonthOrFullDateString,
  DateRangeFilterType,
  getPipelineDateRangeFilterType,
} from './dataset-date-range-helpers';

export const CUSTOM_DATE_RANGE_SELECT_ID = 'custom';

export interface DatasetDateRangeFilterOption {
  label: string;
  value: string;
}

interface OptionWithRangeGenerator extends DatasetDateRangeFilterOption {
  getRange: (v: DatasetDateRangeInputValue) => DatasetDateFilter | undefined;
}

interface DatasetDateRangeInputValue {
  selectedOption: DatasetDateRangeFilterOption | undefined;
  dateRangeValue?: DateRangeValue;
}

export const useDatasetDateRangeFilter = (pipelineType: PipelineType) => {
  // Feature Flags
  const optionsAndDateRangeGenerators = useMemo(() => {
    const dateRangeType = getPipelineDateRangeFilterType(pipelineType);
    const formatDate = (date: Date) => {
      return format(
        date,
        getPipelineDateRangeFilterType(pipelineType) ===
          DateRangeFilterType.Daily
          ? DATE_FORMAT_WITH_DAY
          : STANDARD_DATE_FORMAT
      );
    };
    const monthsAgoRangeFn =
      (monthsAgo: number) => (v: DatasetDateRangeInputValue) => {
        const endDate = new Date();
        const startDate = new Date();
        startDate.setMonth(startDate.getMonth() - monthsAgo);
        if (dateRangeType === DateRangeFilterType.Monthly) {
          startDate.setDate(1);
          endDate.setDate(1);
        }

        return {
          start_date: formatDate(startDate),
          end_date: formatDate(endDate),
        };
      };

    const options: OptionWithRangeGenerator[] = [
      {
        label: 'Last 6 Months',
        value: 'last-6',
        getRange: monthsAgoRangeFn(6),
      },
      {
        label: 'Last Year',
        value: 'last-12',
        getRange: monthsAgoRangeFn(12),
      },
      {
        label: 'Last 2 Years',
        value: 'last-24',
        getRange: monthsAgoRangeFn(24),
      },
      {
        label: 'Last 5 Years',
        value: 'last-60',
        getRange: monthsAgoRangeFn(60),
      },
      {
        label: 'Last 10 Years',
        value: 'last-120',
        getRange: monthsAgoRangeFn(120),
      },
      {
        label: 'Custom',
        value: CUSTOM_DATE_RANGE_SELECT_ID,
        getRange: ({ dateRangeValue }) => {
          if (!dateRangeValue) return undefined;
          const startDate = formatDate(dateRangeValue.startDate);
          let endDate = formatDate(dateRangeValue.endDate);
          if (dateRangeType === DateRangeFilterType.Monthly) {
            // Monthly end dates should always be the last day of the month
            endDate = formatDate(
              new Date(
                dateRangeValue.endDate.getFullYear(),
                dateRangeValue.endDate.getMonth() + 1,
                0
              )
            );
          }
          return {
            start_date: startDate,
            end_date: endDate,
          };
        },
      },
    ];
    return options;
  }, [pipelineType]);

  const options = useMemo(
    () =>
      optionsAndDateRangeGenerators.map((o) => ({
        label: o.label,
        value: o.value,
      })),
    [optionsAndDateRangeGenerators]
  );

  const dateRangeFromOption = useCallback(
    (v: DatasetDateRangeInputValue) => {
      const getRange = optionsAndDateRangeGenerators.find(
        (o) => o.value === v.selectedOption?.value
      )?.getRange;
      return getRange ? getRange(v) : undefined;
    },
    [optionsAndDateRangeGenerators]
  );

  const dateRangeToSelection = useCallback<
    (v: DatasetDateFilter | undefined) => DatasetDateRangeInputValue
  >(
    (v) => {
      const dateFiltersToDateRange = v
        ? {
            startDate: dateFromMonthOrFullDateString(v.start_date),
            endDate: dateFromMonthOrFullDateString(v.end_date),
          }
        : undefined;
      const matchingOption = optionsAndDateRangeGenerators.find((o) => {
        const generatedRange = o.getRange({
          selectedOption: o,
          dateRangeValue: dateFiltersToDateRange,
        }); // Generate range from the option
        const equal = isEqual(v, generatedRange);
        if (o.value === CUSTOM_DATE_RANGE_SELECT_ID) {
          return equal && v !== undefined;
        } else {
          return equal;
        }
      });
      return {
        selectedOption: matchingOption,
        dateRangeValue:
          matchingOption?.value === CUSTOM_DATE_RANGE_SELECT_ID
            ? dateFiltersToDateRange
            : undefined,
      };
    },
    [optionsAndDateRangeGenerators]
  );

  return {
    options,
    dateRangeFromOption,
    dateRangeToSelection,
  };
};

export const useUpdateDatasetDateRangeFilters = () => {
  return useCallback(
    (
      entityId: Deliverable['id'],
      dateFilter: DatasetDateFilter | undefined
    ) => {
      updateDraftDeliverable(
        entityId,
        write<Deliverable>((state) => {
          if (dateFilter) {
            state.pipeline = {
              ...state.pipeline,
              date_filter: dateFilter,
            };
          } else {
            const clonedPipeline = { ...state.pipeline };
            delete clonedPipeline.date_filter;
            state.pipeline = clonedPipeline;
          }
        })
      );
    },
    []
  );
};
