import { useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';
import { SearchIcon } from '@chakra-ui/icons';
import { Box, Divider, Flex } from '@chakra-ui/layout';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  InputGroup,
  Input,
  InputRightElement,
  CheckboxGroup,
  Checkbox,
  ModalFooter,
  ButtonGroup,
  Button,
} from '@chakra-ui/react';

interface ColumnSelectModalProps {
  isOpen: boolean;
  onClose: () => void;
  columns: { value?: string; label?: string }[];
  setSavedCheckedColumns: (value: string[]) => void;
  savedCheckedColumns: string[];
  initialCheckedColumn?: string[];
  onSave?: (checkedColumns: string[]) => void;
}

export const ColumnSelectModal = ({
  isOpen,
  onClose,
  columns,
  setSavedCheckedColumns,
  savedCheckedColumns,
  initialCheckedColumn,
  onSave,
}: ColumnSelectModalProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [checkedColumns, setCheckedColumns] = useState<string[]>([]);
  const [searchedColumns, setSearchedColumns] = useState(columns);
  const [columnHeaders, setColumnHeaders] = useState<string[]>([]);

  useEffect(() => {
    const searchedCols = columns.filter((column) =>
      column?.value?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setSearchedColumns(searchedCols);
  }, [columns, searchTerm]);

  const newColumnHeaders = useMemo(
    () => columns.map((col) => col?.value || ''),
    [columns]
  );
  useEffect(() => {
    if (!isEqual(columnHeaders, newColumnHeaders)) {
      const defaultCheckedColumns = initialCheckedColumn
        ? newColumnHeaders.filter((col) => initialCheckedColumn?.includes(col))
        : newColumnHeaders;
      if (defaultCheckedColumns?.length > 0) {
        setCheckedColumns(defaultCheckedColumns);
        setSavedCheckedColumns(defaultCheckedColumns);
        setColumnHeaders(newColumnHeaders);
      }
    }
  }, [
    isOpen,
    newColumnHeaders,
    columnHeaders,
    setSavedCheckedColumns,
    initialCheckedColumn,
  ]);

  useEffect(() => {
    if (isOpen) {
      setCheckedColumns(savedCheckedColumns);
    }

    setSearchTerm('');
  }, [isOpen, savedCheckedColumns]);

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const onChangeColumns = (values: string[]) => setCheckedColumns(values);

  const onSaveColumn = () => {
    setSavedCheckedColumns(checkedColumns);
    if (onSave) {
      onSave(checkedColumns);
    }
    onClose();
  };

  const columnsToShow = searchedColumns.map((column) => ({
    value: column?.value,
    label: column?.label,
  }));

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      returnFocusOnClose={false}
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color="text.primary">Manage Columns</ModalHeader>
        <ModalCloseButton />
        <Divider orientation="horizontal" />
        <ModalBody>
          <InputGroup>
            <Input
              placeholder="Search..."
              value={searchTerm}
              onChange={onChangeSearch}
            />
            <InputRightElement>
              <SearchIcon w={3.5} h={3.5} color="silver.600" />
            </InputRightElement>
          </InputGroup>
          <Box
            overflowY="scroll"
            maxH="350px"
            sx={{
              '&::-webkit-scrollbar': {
                width: '14px',
              },
              '&::-webkit-scrollbar-track': {
                background: 'transparent',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#e6ebf4',
                borderRadius: '10px',
                border: '3px solid #ffffff',
              },
            }}
            mt={2}
            pl={1}
          >
            <CheckboxGroup
              colorScheme="green"
              value={checkedColumns}
              onChange={onChangeColumns}
            >
              <Flex flexDirection="column">
                {columnsToShow
                  .filter((column) => column.label)
                  .map((column) => {
                    return (
                      <Checkbox
                        key={column.value}
                        value={column.value}
                        color="text.primary"
                        data-testid={column.value + '-checkbox'}
                        mb={2}
                      >
                        {column.label}
                      </Checkbox>
                    );
                  })}
              </Flex>
            </CheckboxGroup>
          </Box>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup spacing={4}>
            <Button
              variant="link"
              fontSize="12px"
              colorScheme="gray"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              borderRadius="4px"
              fontSize="12px"
              fontWeight="600"
              colorScheme="green"
              variant="solid"
              size="sm"
              onClick={onSaveColumn}
              data-testid="save-table-columns-button"
            >
              Save
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
