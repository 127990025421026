import { Button, Flex, Icon } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';
import { FiSliders } from 'react-icons/fi';
import { ScreenerFilterOption } from './types';
import { ScreenerFilterPopover } from './screener-filter-popover';
import { PrimaryFilterTag } from './tags/primary-filter-tag';
import { SubFilterTag } from './tags/sub-filter-tag';
import { useScreenerFilter } from './screener-filter-provider';
import { CompanyDetailFilterTag } from './tags/company-detail-filter-tag';
import { FilterSetSaveMenu } from '../collection';
import { View } from '@revelio/data-access';
import { SingleValue } from 'react-select';
import { Option } from '../selection-list/selection-list-select';
export interface ScreenerFiltersProps {
  filterOptions: ScreenerFilterOption[];
  savedSetsView: View;
  onSubmitSavedFilterSet: (value: SingleValue<Option> | undefined) => void;
}

const FilterButton = forwardRef((props, ref: LegacyRef<HTMLButtonElement>) => {
  return (
    <Button
      {...props}
      ref={ref}
      colorScheme="gray"
      borderRadius="4px"
      size="sm"
      paddingX={3}
      height={6}
      backgroundColor="transparent"
      fontSize={'xs'}
      fontWeight={400}
      color="navyBlue.500"
      leftIcon={<Icon as={FiSliders} boxSize={3.5} transform="rotate(90deg)" />}
      data-testid="filter-menu-btn"
    >
      Filter
    </Button>
  );
});

export const ScreenerFilters = ({
  filterOptions,
  savedSetsView,
  onSubmitSavedFilterSet,
}: ScreenerFiltersProps) => {
  const { state, dispatch } = useScreenerFilter();

  const primaryFilter = state.filters.primary_filter;

  return (
    <Flex wrap="wrap" fontSize="sm" rowGap={2.5}>
      {primaryFilter && (
        <PrimaryFilterTag
          filterOptions={filterOptions}
          dispatch={dispatch}
          primaryFilter={primaryFilter}
        />
      )}
      {state.filters.company_detail_filters?.map((companyDetailFilter) => (
        <CompanyDetailFilterTag
          key={companyDetailFilter.name}
          filterOptions={filterOptions}
          companyDetailFilter={companyDetailFilter}
          dispatch={dispatch}
        />
      ))}
      {state.filters.sub_filters?.map((subFilter) => (
        <SubFilterTag
          key={subFilter.id}
          filterOptions={filterOptions}
          subFilter={subFilter}
        />
      ))}
      <ScreenerFilterPopover
        trigger={<FilterButton />}
        filterOptions={filterOptions}
        onSubmitSavedFilterSet={onSubmitSavedFilterSet}
      />
      <FilterSetSaveMenu view={savedSetsView} />
    </Flex>
  );
};
