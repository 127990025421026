import { FunctionComponent } from 'react';

import BoxPlot from './lib/box/BoxPlot';
import BarChartHorizontal from './lib/bar/BarChartHorizontal';
import BarChartHorizontalMirror from './lib/bar/BarChartHorizontalMirror';
import BarChartVertical from './lib/bar/BarChartVertical';
import StackedBarChartHorizontal from './lib/bar/StackedBarChartHorizontal';
import GroupedBarChartHorizontal from './lib/bar/GroupedBarChartHorizontal';
import Histogram from './lib/histogram/Histogram';
import LineChart from './lib/line/LineChart';
import {
  DailyLineChart,
  DailyLineChartProps,
} from './lib/line/daily-line-chart';
import {
  MonthlyLineChart,
  MonthlyLineChartProps,
} from './lib/line/monthly-line-chart';
import MainPostingsPlot from './lib/postings/MainPostingsPlot';
import SankeyDiagram from './lib/sankey/SankeyDiagram';
import ScatterPlot from './lib/scatter/ScatterPlot';
import TreeDiagram from './lib/tree/TreeDiagram';
import WordCloudV3 from './lib/word-cloud/WordCloudV3'; //Todo: remove this when sentiment is switched to GQL
import WordCloud from './lib/word-cloud/WordCloudV4';
import Stats from './lib/stats/Stats';
import KDEPlot from './lib/kde/KDEPlot';
import Meter from './lib/meter/Meter';
import { D3NoMatch } from './lib/no-match';

// png download imports
import { pngDownloader } from './lib/utilities/pngDownloader';
import { SankeyGenerator } from './lib/plot-generators';
import {
  BarChartHorizontalGenerator,
  LineChartGenerator,
  StackedBarChartHorizontalGenerator,
  MainPostingsPlotGenerator,
  GroupedBarChartHorizontalGenerator,
} from './lib/plot-generators';
import { BarChartHorizontalMirrorGenerator } from './lib/plot-generators/BarChartHorizontalMirrorGenerator';
import { HistogramGenerator } from './lib/plot-generators/HistogramGenerator';
import { BarChartVerticalGenerator } from './lib/plot-generators/BarChartVerticalGenerator';
import { KDEPlotGenerator } from './lib/plot-generators/kde/KDEPlotGenerator';
import { MeterGenerator } from './lib/plot-generators/meter/MeterGenerator';

import {
  IBarChartHorizontal,
  IBarChartHorizontalMirror,
  IBarChartVertical,
  IStackedBarChartHorizontal,
  IGroupedBarChartHorizontal,
  IHistogram,
  ILineChart,
  IMainPostingsPlot,
  ISankeyDiagram,
  ITalentDiscoveryMap,
  D3ChartNames,
  IBaseD3Chart,
  IBoxPlot,
  IScatterPlot,
  ITreePlot,
  D3ChartGenerator,
  IKDEPlot,
  IMeter,
} from './model';
import TalentDiscoveryMap, {
  PopupDataKey,
} from './lib/talent-discovery/TalentDiscoveryMap';
import { calcMarketTightness } from './lib/talent-discovery/td-utils';

export * from './model';
export * from './lib/utilities/colors';
export * from './lib/utilities/plot-colors';
export * from './lib/utilities/custom-formatters';

export * from './lib/plot-generators';

export * from './lib/meter/meter-chart';
export * from './lib/box/box-plot';

export type { PopupDataKey, DailyLineChartProps, MonthlyLineChartProps };
export {
  calcMarketTightness,
  pngDownloader,
  BarChartHorizontal,
  BarChartHorizontalMirror,
  BarChartVertical,
  StackedBarChartHorizontal,
  GroupedBarChartHorizontal,
  Histogram,
  LineChart,
  DailyLineChart,
  MonthlyLineChart,
  MainPostingsPlot,
  SankeyDiagram,
  WordCloud,
  WordCloudV3, //Todo: remove this when sentiment is switched to GQL
  ScatterPlot,
  TreeDiagram,
  Stats,
  KDEPlot,
  Meter,
  TalentDiscoveryMap,
};

export const D3ChartComponentLookup = {
  [D3ChartNames.BoxPlot]: BoxPlot as FunctionComponent<IBoxPlot>,
  [D3ChartNames.BarChartHorizontal]:
    BarChartHorizontal as FunctionComponent<IBarChartHorizontal>,
  [D3ChartNames.BarChartHorizontalMirror]:
    BarChartHorizontalMirror as FunctionComponent<IBarChartHorizontalMirror>,
  [D3ChartNames.BarChartVertical]:
    BarChartVertical as FunctionComponent<IBarChartVertical>,
  [D3ChartNames.StackedBarChartHorizontal]:
    StackedBarChartHorizontal as FunctionComponent<IStackedBarChartHorizontal>,
  [D3ChartNames.GroupedBarChartHorizontal]:
    GroupedBarChartHorizontal as FunctionComponent<IGroupedBarChartHorizontal>,
  [D3ChartNames.Histogram]: Histogram as FunctionComponent<IHistogram>,
  [D3ChartNames.LineChart]: LineChart as FunctionComponent<ILineChart>,
  [D3ChartNames.MainPostingsPlot]:
    MainPostingsPlot as FunctionComponent<IMainPostingsPlot>,
  [D3ChartNames.SankeyDiagram]:
    SankeyDiagram as FunctionComponent<ISankeyDiagram>,
  [D3ChartNames.TalentDiscoveryMap]:
    TalentDiscoveryMap as FunctionComponent<ITalentDiscoveryMap>,
  [D3ChartNames.Stats]: Stats as FunctionComponent<IBaseD3Chart>,
  [D3ChartNames.ScatterPlot]: ScatterPlot as FunctionComponent<IScatterPlot>,
  [D3ChartNames.TreeDiagram]: TreeDiagram as FunctionComponent<ITreePlot>,
  [D3ChartNames.KDE]: KDEPlot as FunctionComponent<IKDEPlot>,
  [D3ChartNames.Meter]: Meter as FunctionComponent<IMeter>,
  D3NoMatch: D3NoMatch,
};

export const D3ChartGeneratorLookup: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key in D3ChartNames]?: D3ChartGenerator | any | undefined;
} = {
  [D3ChartNames.BarChartHorizontal]: BarChartHorizontalGenerator,
  [D3ChartNames.BarChartHorizontalMirror]: BarChartHorizontalMirrorGenerator,
  [D3ChartNames.BarChartVertical]: BarChartVerticalGenerator,
  [D3ChartNames.GroupedBarChartHorizontal]: GroupedBarChartHorizontalGenerator,
  [D3ChartNames.Histogram]: HistogramGenerator,
  [D3ChartNames.LineChart]: LineChartGenerator,
  [D3ChartNames.MainPostingsPlot]: MainPostingsPlotGenerator,
  [D3ChartNames.SankeyDiagram]: SankeyGenerator,
  [D3ChartNames.StackedBarChartHorizontal]: StackedBarChartHorizontalGenerator,
  [D3ChartNames.KDE]: KDEPlotGenerator,
  [D3ChartNames.Meter]: MeterGenerator,
  [D3ChartNames.D3NoMatch]: undefined,
};
