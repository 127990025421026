import { flatten, omit } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Flex, Grid, GridItem, Heading } from '@chakra-ui/layout';
import { useQuery } from 'urql';

import {
  AddEntityButtonText,
  PageTitles,
  PrimaryFilters,
  PrimaryView,
  Views,
} from '@revelio/core';
import {
  COMPOSITION_GET_SUMMARY_DATA,
  GET_ENTITY_DATA,
  createSelectableFiltersMap,
  DateRangeFormattedValues,
  DefaultDates,
  FilterChips,
  FilterChipsContainer,
  FilterContainer,
  FilterItem,
  FilterMenu,
  FilterMenuLimits,
  FilterSets,
  GEOGRAPHY_GRANULARITY_FILTERS,
  LocalSelectionCategories,
  POSTINGS_GET_ACTIVE,
  PrimaryFilterLimits,
  ROLE_GRANULARITY_FILTERS,
  SelectionCategories,
  SENTIMENT_GET_SUMMARY_DATA,
  serializeFilters,
  SHARED_SET_ENTITY_LIMIT,
  SKILL_GRANULARITY_FILTERS,
  useActiveFiltersState,
  useDefaultLastMonth,
  useSelectionLists,
  useStoredFilterSet,
  useSyncFiltersToSearchParams,
  useViewFilterDefaults,
  useViewFilters,
  ViewTypes,
  useRoleTaxonomySetting,
  useAdaptiveRoleTaxonomy,
  FilterSetSaveMenu,
  useTabMeta,
  PrimaryEntityPopoutTreeFilter,
  RICS_AND_COMPANY_FILTERS_SORTED,
  RICS_AND_COMPANY_LABELS,
  RICS_AND_COMPANY_FILTERS,
} from '@revelio/filtering';

import DashboardPage from '../../DashboardPage';
import {
  COMPANY_SUMMARY_SALARY_DISTRIBUTION,
  SalaryDistribution,
} from './salary-distribution/salary-distribution';
import { CompanySummaryOverview } from './company-summary-overview';
import { useGetKdePlotData } from './salary-distribution/useGetKdePlotData';
import {
  CustomRoleFilter,
  CustomRoleTaxonomySelection,
  Dimension1,
  Filters,
  METRIC_MODE_IDS,
  POSTING_SOURCE_IDS,
  View,
} from '@revelio/data-access';
import { CompetitorBarCharts } from './competitor-bar-charts/competitor-bar-charts';
import { CompanyMap } from './map/company-map';
import { useGetCompanyMapData } from './map/useGetCompanyMapData';
import { CompanyStats } from './company-stats/company-stats';
import { transformFiltersToPostingVariables } from '../../postings/utils';
import {
  ChartsRenderedContext,
  ChartsRenderedProvider,
} from '../shared/charts-rendered-context';
import {
  CompositionStatsMetric,
  PostingsStatsMetric,
  SentimentMetric,
} from '../shared/stat';
import { COMPETITOR_BAR_CHART_NAME } from './competitor-bar-charts/utils/get-competitor-bar-chart-props';
import { Card } from '@chakra-ui/react';
import { SankeyError } from '@revelio/assets';

const CompanySummary = () => {
  const SUMMARY_SECONDARY_FILTERS = [
    SelectionCategories.SAVED_FILTER_SET,
    {
      filters: ROLE_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    {
      filters: GEOGRAPHY_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    SelectionCategories.SENIORITY,
    {
      filters: SKILL_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
  ];

  const SUMMARY_PRIMARY_VIEW_FILTER = [
    {
      filters: RICS_AND_COMPANY_FILTERS,
      isNested: true,
      limit: 1,
    },
  ];

  const primaryFilters = flatten(
    createSelectableFiltersMap(SUMMARY_PRIMARY_VIEW_FILTER)
  ) as SelectionCategories[];

  const selectableFiltersMap = createSelectableFiltersMap([
    ...SUMMARY_SECONDARY_FILTERS,
  ]);
  const flattenedSelectableFilters = flatten(selectableFiltersMap);

  useViewFilters([
    ...primaryFilters,
    ...flattenedSelectableFilters,
    SelectionCategories.DATE_RANGE,
    SelectionCategories.DATE_RANGE_FULL,
  ]);

  useSelectionLists([
    SelectionCategories.COMPANY,
    SelectionCategories.INDUSTRY,
    ...flattenedSelectableFilters,
  ]);

  useStoredFilterSet({
    sharedSetId: FilterSets.COMPANY,
    primaryEntitiesSync: true,
    limit: 1,
    defaultLimit: 1,
    filterNames: primaryFilters,
    uniqueSetId: FilterSets.COMPANY_SUMMARY,
  });

  const viewFilterDefaultArgs = {
    view: Views.ENTITY_SUMMARY,
    viewType: ViewTypes.COMPANY,
    presetView: FilterSets.COMPANY_SUMMARY,
    onlyConsiderTheseFiltersToTriggerDefaults: [
      LocalSelectionCategories.PRIMARY_ENTITIES,
    ],
    viewFilters: [LocalSelectionCategories.PRIMARY_ENTITIES],
    limit: PrimaryFilterLimits.ENTITY_SUMMARY,
    dateKey: SelectionCategories.DATE_RANGE,
    primaryFilters,
    supportPrimaryEntities: true,
  };

  useViewFilterDefaults(viewFilterDefaultArgs);

  useSyncFiltersToSearchParams({
    primaryFilters,
    syncToPrimaryEntities: true,
  });

  const activeFilters = useActiveFiltersState();

  const selectedCompanyRCID = parseInt(
    (
      activeFilters.find((filter) => filter.id === SelectionCategories.COMPANY)
        ?.value as FilterItem[]
    )?.[0]?.id as string,
    10
  );

  const selectedIndustry = activeFilters.find(
    (filter) => filter.id === SelectionCategories.INDUSTRY
  );

  const isIndustrySelected = !selectedCompanyRCID && !!selectedIndustry;

  const [{ data: entityData, fetching: primaryCompanyLoading }] = useQuery({
    query: GET_ENTITY_DATA,
    variables: {
      filters: {
        // secondary filters should not change the competitor search
        company: [selectedCompanyRCID],
        top_competitors: 7, //this will change to num_competitors
      },
    },
    pause: !selectedCompanyRCID,
  });

  const competitors = useMemo(
    () => entityData?.entity?.competitors || [],
    [entityData]
  );
  const primaryAndCompetitorRCIDs = useMemo(() => {
    const competitorIds = competitors
      ?.filter((competitor) => competitor?.metadata?.id)
      .map((competitor) => competitor?.metadata?.id as number);

    let ids = competitorIds || [];

    if (selectedCompanyRCID) {
      ids = [selectedCompanyRCID, ...ids];
    }

    return ids;
  }, [competitors, selectedCompanyRCID]);

  const { isEnabled: isCustomRoleTaxonomyEnabled } = useRoleTaxonomySetting();

  const { kdeSalaryData, loading: kdePlotsLoading } = useGetKdePlotData(
    primaryAndCompetitorRCIDs,
    isCustomRoleTaxonomyEnabled
  );

  useDefaultLastMonth({
    view: Views.ENTITY_SUMMARY,
    viewType: ViewTypes.COMPANY,
    dateType: DefaultDates.DEFAULT_LAST_MONTH,
  });
  // This is used for the active postings query in the overtime plots
  useDefaultLastMonth({
    view: Views.ENTITY_SUMMARY,
    viewType: ViewTypes.COMPANY,
    dateType: DefaultDates.LAST_START_DATE,
  });
  useAdaptiveRoleTaxonomy({
    viewType: ViewTypes.COMPANY,
    primaryFilters,
  });

  useTabMeta({
    savedSetView: View.Company,
    view: Views.ENTITY_SUMMARY,
    viewType: ViewTypes.COMPANY,
    limit: PrimaryFilterLimits.OVERVIEW,
    supportPrimaryEntities: true,
    includeDisabledFilters: true,
    primaryFilters,
  });

  const dates = activeFilters.find(
    (filter) => filter.id === SelectionCategories.DATE_RANGE
  )?.value as DateRangeFormattedValues;

  const getMsa = (): string | undefined => {
    return (
      activeFilters.find(
        (filter) => filter.id === SelectionCategories.METRO_AREA
      )?.value as Array<{ id: string }>
    )?.[0]?.id as string;
  };

  const metroArea = getMsa() || '';

  const overwrites = {
    metro_area: undefined,
    ...(metroArea && { msa: [metroArea] }),
  };

  const serializedFilters: typeof overwrites & Filters = serializeFilters(
    activeFilters,
    {
      overwrites: {
        company: primaryAndCompetitorRCIDs,
        metro_area: undefined,
        ...(metroArea && { msa: [metroArea] }),
      },
      isCustomRoleTaxonomyEnabled,
    }
  );

  const [{ data: compositionData, fetching: compositionLoading }] = useQuery({
    query: COMPOSITION_GET_SUMMARY_DATA,
    variables: {
      dim1: Dimension1.Company,
      filters: serializedFilters,
    },
    pause: !entityData || !dates,
  });

  const queryFilters = useMemo(
    () =>
      transformFiltersToPostingVariables({
        view: PrimaryView.COMPANY,
        filters: [...activeFilters],
        isCustomRoleTaxonomyEnabled,
      }),
    [activeFilters, isCustomRoleTaxonomyEnabled]
  );

  const postingsFiltersWithCompetitors = {
    ...queryFilters,
    filters: {
      ...queryFilters.filters,
      provider: [POSTING_SOURCE_IDS.unified],
      metric_mode: METRIC_MODE_IDS.expectedHires,
      company: primaryAndCompetitorRCIDs,
    },
  };

  const [{ data: postingsActiveData, fetching: postingsLoading }] = useQuery({
    query: POSTINGS_GET_ACTIVE,
    variables: postingsFiltersWithCompetitors,
    pause: !entityData,
  });

  const [{ data: sentimentData, fetching: sentimentLoading }] = useQuery({
    query: SENTIMENT_GET_SUMMARY_DATA,
    variables: {
      dim1: Dimension1.Company,
      filters: omit(serializedFilters, [
        // sentiment does not support seniority or skills filters
        SelectionCategories.SENIORITY,
        ...SKILL_GRANULARITY_FILTERS,
      ]),
    },
    pause: !entityData,
  });

  const {
    data: talentDiscoveryDataQuery,
    filters,
    loading: talentDiscoveryDataLoading,
  } = useGetCompanyMapData({ isCustomRoleTaxonomyEnabled });

  const customTaxonomy = ((): CustomRoleTaxonomySelection | undefined => {
    return filters?.custom_role?.taxonomyId
      ? filters?.custom_role?.taxonomyId
      : undefined;
  })();

  const customRole: CustomRoleFilter | undefined = customTaxonomy
    ? { taxonomyId: customTaxonomy }
    : undefined;

  const [salaryDisributionLoading, setSalaryDistributionLoading] =
    useState(false);
  const [firstLoad, setFirstLoad] = useState(false);

  const allLoadingStates = useMemo(
    () => [
      compositionLoading,
      kdePlotsLoading,
      postingsLoading,
      primaryCompanyLoading,
      salaryDisributionLoading,
      sentimentLoading,
      talentDiscoveryDataLoading,
    ],
    [
      compositionLoading,
      kdePlotsLoading,
      postingsLoading,
      primaryCompanyLoading,
      salaryDisributionLoading,
      sentimentLoading,
      talentDiscoveryDataLoading,
    ]
  );

  useEffect(() => {
    if (firstLoad && allLoadingStates.some((loading) => loading)) {
      setFirstLoad(false);
    }

    if (firstLoad && allLoadingStates.every((loading) => !loading)) {
      setFirstLoad(false);
    }
  }, [firstLoad, allLoadingStates]);

  const context = useContext(ChartsRenderedContext);
  if (!context) {
    throw new Error(
      'chartRenderedContext must be used within a ChartsRenderedProvider'
    );
  }
  const { allChartsRendered } = context;
  const anyRequestsLoading = useMemo(() => {
    const allLoadingComplete =
      allLoadingStates.every((loading) => !loading) && allChartsRendered;

    return !allLoadingComplete || firstLoad;
  }, [allLoadingStates, firstLoad, allChartsRendered]);

  const postingsStartDate = postingsFiltersWithCompetitors?.filters?.start_date;
  const postingsEndDate = postingsFiltersWithCompetitors?.filters?.end_date;

  return (
    <DashboardPage
      title={[PageTitles.COMPANY, PageTitles.SUMMARY]}
      hideSelectionsMargins
      loading={anyRequestsLoading && !isIndustrySelected}
      selections={
        <Flex
          justifyContent="flex-start"
          alignItems="center"
          flexDirection="row"
        >
          <FilterChipsContainer
            filterNames={primaryFilters}
            variant="companyChip"
            showColors={false}
            isPrimaryChip
            limit={PrimaryFilterLimits.COMPANY_SUMMARY}
            useChipsSkeleton={false}
            min={1}
            addButton={
              <PrimaryEntityPopoutTreeFilter
                selectionListIds={RICS_AND_COMPANY_FILTERS_SORTED}
                filterLabel="Companies"
                branches={RICS_AND_COMPANY_LABELS}
                maxSelections={SHARED_SET_ENTITY_LIMIT}
                minSelections={1}
                activeLimit={PrimaryFilterLimits.COMPANY_SUMMARY}
                disableLevels={[
                  SelectionCategories.RICS_K10,
                  SelectionCategories.RICS_K50,
                ]}
              >
                {AddEntityButtonText[PrimaryFilters.COMPANY]}
              </PrimaryEntityPopoutTreeFilter>
            }
          />
        </Flex>
      }
    >
      <FilterContainer
        flexDirection="row"
        alignItems="flex-start"
        justifyContent={'space-between'}
      >
        <Flex
          justifyContent="flex-start"
          alignItems="flex-start"
          flexDirection="row"
          wrap="wrap"
          rowGap="0.5rem"
        >
          <FilterChips
            filterNames={SUMMARY_SECONDARY_FILTERS}
            variant="filterChip"
            limit={FilterMenuLimits.ENTITY_SUMMARY}
            showGranularity={true}
            viewType={ViewTypes.COMPANY}
            useChipsSkeleton={true}
            addButton={
              <>
                <FilterMenu
                  title="Filter"
                  filters={SUMMARY_SECONDARY_FILTERS}
                  selectMenuOpenDefault
                  limit={FilterMenuLimits.ENTITY_SUMMARY}
                  viewIdForDefault={`${Views.ENTITY_SUMMARY}_${ViewTypes.COMPANY}`}
                />
                <FilterSetSaveMenu view={View.Company} />
              </>
            }
          />
        </Flex>
      </FilterContainer>

      {isIndustrySelected ? (
        <Card height="100%" borderRadius="10px" variant="unstyled">
          <Flex height="100%" flexDirection="column" justifyContent="center">
            <Heading
              textAlign="center"
              size="xl"
              fontWeight="extrabold"
              color="text.primary"
              mb="6"
            >
              Please select a company to view overview.
            </Heading>
            <SankeyError height="192px" />
          </Flex>
        </Card>
      ) : (
        <Grid
          templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
          gridTemplateRows="1fr 1fr"
          gap={4}
          height="100%"
        >
          {/* Row 1: Three equal items */}
          <GridItem height="auto" minHeight="0" maxHeight="100%">
            {anyRequestsLoading ? null : ( // this is to prevent gql cached text overlapping loading spinner when navigating between pages
              <CompanySummaryOverview
                description={entityData?.entity?.summary?.description}
                founded={entityData?.entity?.summary?.founded}
                headquarters={entityData?.entity?.summary?.headquarters}
              />
            )}
          </GridItem>
          <GridItem>
            <CompetitorBarCharts
              compositionData={compositionData}
              primaryRCID={selectedCompanyRCID}
            />
          </GridItem>

          <GridItem height="auto" minHeight="0" maxHeight="100%">
            <CompanyStats
              compositionData={compositionData}
              selectedCompanyRCID={selectedCompanyRCID}
              competitors={competitors}
              postingsActiveData={postingsActiveData}
              sentimentData={sentimentData}
            />
          </GridItem>

          {/* Row 2: 2/3 and 1/3 layout */}
          <GridItem colSpan={{ base: 1, md: 2 }}>
            {postingsStartDate && postingsEndDate && (
              <CompanyMap
                mapData={
                  talentDiscoveryDataQuery?.talent_discovery_search_v1_5
                    ?.map_data
                }
                postingsStartDate={postingsStartDate}
                postingsEndDate={postingsEndDate}
                custom_role={customRole}
              />
            )}
          </GridItem>

          <GridItem colSpan={1}>
            <SalaryDistribution
              salaryData={kdeSalaryData}
              primaryCompanyRCID={selectedCompanyRCID}
              competitors={competitors}
              primaryCompanyName={entityData?.entity?.summary?.name}
              setLoading={setSalaryDistributionLoading}
            />
          </GridItem>
        </Grid>
      )}
    </DashboardPage>
  );
};

export const CompanySummaryWithChartRenderingProvider = () => (
  <ChartsRenderedProvider
    chartNames={[
      COMPETITOR_BAR_CHART_NAME,
      CompositionStatsMetric.Headcount,
      CompositionStatsMetric.GrowthRate,
      PostingsStatsMetric.ActivePostings,
      SentimentMetric.OverallRating,
      SentimentMetric.BusinessOutlookRating,
      SentimentMetric.CompensationsBenefitsRating,
      COMPANY_SUMMARY_SALARY_DISTRIBUTION,
    ]}
    chartRenderingTimeout={1000}
  >
    <CompanySummary />
  </ChartsRenderedProvider>
);
