import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty, noop, startCase } from 'lodash';
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

import {
  ActionModalControlPanel,
  ActionModalControlPanelProps,
} from '@revelio/core';
import {
  BreadcrumbTreeFilter,
  GEOGRAPHY_GRANULARITY_FILTERS_WITH_STATE,
  SelectionCategories,
  SelectionListIdNames,
  Tree,
  TreeItem,
  TreeMethodHandle,
  TreeType,
} from '@revelio/filtering';

import {
  ReportEntityCompanyEditor,
  ReportEntityCompanyEditorHandle,
  ReportEntityCompanyEditorProps,
} from './report-entity-company-editor';
import {
  AdditionalReportSelectionCategories,
  ReportSelectionCategories,
} from '../entity-configuration/utils';
import { repvueRCIDsFilter } from './repvue-rcids';
import { REPORT_BUILDER_SELECTION_LIST_PARENT_MAP } from '../report-builder-selection-list-parent-map';

export interface ReportEntitySelectionsModalProps
  extends Partial<ActionModalControlPanelProps> {
  headerLabel?: string;
  selectionLists?: ReportSelectionCategories[];
  isOpen: boolean;
  submitHandler?: (selections: Record<string, TreeItem<string>>) => void;
  closeOnSubmit?: boolean;
  initialSelections?: Record<string, TreeItem>;
  limit?: number;
  isNested?: boolean;
}

export const ReportEntitySelectionsModal = ({
  headerLabel = '',
  selectionLists = [],
  isOpen,
  onClose = noop,
  onCancel,
  submitHandler,
  submitText,
  closeOnSubmit = true,
  initialSelections = {},
  limit,
  isNested,
}: ReportEntitySelectionsModalProps) => {
  const [selections, setSelections] =
    useState<Record<string, TreeItem>>(initialSelections);

  const [numBreadcrumbTreeSelections, setNumBreadcrumbTreeSelections] =
    useState<number>(0);

  const searchInputRef = useRef(null);
  const treeRef = useRef<TreeMethodHandle>();
  const companyEditorRef = useRef<ReportEntityCompanyEditorHandle>(null);
  const shouldUseReportEntityCompanyEditor = selectionLists?.some(
    (list) =>
      list === SelectionCategories.COMPANY ||
      list === AdditionalReportSelectionCategories.COMPANY_REPVUE
  );
  const shouldUseRepvue = selectionLists?.includes(
    AdditionalReportSelectionCategories.COMPANY_REPVUE
  );
  const shouldUseBreadcrumbTree =
    selectionLists?.every((s) => {
      return GEOGRAPHY_GRANULARITY_FILTERS_WITH_STATE.includes(
        s as SelectionCategories
      );
    }) &&
    selectionLists?.length === GEOGRAPHY_GRANULARITY_FILTERS_WITH_STATE.length;

  const normalizedSelectionLists = useMemo(() => {
    return selectionLists.map((s) =>
      s === AdditionalReportSelectionCategories.COMPANY_V1 ||
      s === AdditionalReportSelectionCategories.COMPANY_REPVUE
        ? SelectionCategories.COMPANY
        : s
    );
  }, [selectionLists]);

  const handleReset = () => {
    treeRef.current?.handleClearSelections?.();
    companyEditorRef.current?.handleClearSelections();
  };

  const handleSubmit = () => {
    submitHandler?.(selections);
    setSelections({});

    if (closeOnSubmit) {
      onClose();
    }
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    setSelections(initialSelections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  // BE return the filters with state after metro area, this is a quick fix to maintain correct order
  const sortedGeoSelectionListIds = useMemo(() => {
    const sortedListIds = [...GEOGRAPHY_GRANULARITY_FILTERS_WITH_STATE];

    return sortedListIds.filter((s) =>
      normalizedSelectionLists.includes(s as SelectionCategories)
    );
  }, [normalizedSelectionLists]);

  const handleBreadcrumbTreeSubmit = (
    breadCrumbTreeSelections: Record<string, TreeItem>
  ) => {
    const selectionsWithoutGeo = Object.entries(selections)
      .filter(
        ([_key, item]) =>
          !sortedGeoSelectionListIds.includes(
            item.selectionListId as SelectionCategories
          )
      )
      .reduce<Record<string, TreeItem>>((acc, [key, item]) => {
        acc[key] = item;
        return acc;
      }, {});

    const newSelections = {
      ...selectionsWithoutGeo,
      ...breadCrumbTreeSelections,
    };
    setSelections(newSelections);
    submitHandler?.(newSelections);
    if (closeOnSubmit) {
      onClose();
    }
  };

  const showSelected = (limit || 0) === 1;

  const header = (
    <Flex alignItems="center">
      <Text>{`Select ${headerLabel}`}</Text>
      {showSelected && (
        <Text
          fontSize={14}
          color="gray.600"
          ml={2}
        >{`(${shouldUseBreadcrumbTree ? numBreadcrumbTreeSelections : Object.values(selections).length || 0}/${limit} Selected)`}</Text>
      )}
    </Flex>
  );

  const onCompanySearchSelections = useCallback<
    ReportEntityCompanyEditorProps['onSelectionsValue']
  >(
    (items) => {
      const mappedSelections: Record<string, TreeItem> = {};
      const selectionListId = shouldUseRepvue
        ? AdditionalReportSelectionCategories.COMPANY_REPVUE
        : SelectionCategories.COMPANY;
      Object.keys(items).forEach((key) => {
        const companyItem = items[key];
        const id = `${selectionListId}.${companyItem.rcid}`;
        mappedSelections[id] = {
          id: id,
          item: {
            id: companyItem.rcid,
            shortName: companyItem.primary_name,
            longName: companyItem.primary_name,
            label: companyItem.primary_name,
            ...companyItem,
          },
          children: [],
          parentId: undefined,
          selectionListId: selectionListId as SelectionListIdNames,
        };
      });
      setSelections(mappedSelections);
    },
    [shouldUseRepvue]
  );

  const isSubmitDisabled = useMemo(() => {
    return isEmpty(selections);
  }, [selections]);

  const renderContent = () => {
    if (shouldUseReportEntityCompanyEditor) {
      return (
        <ModalBody py="16px">
          <ReportEntityCompanyEditor
            filterFn={shouldUseRepvue ? repvueRCIDsFilter : undefined}
            searchInputRef={searchInputRef}
            treeHeight={315}
            ref={companyEditorRef}
            onSelectionsValue={onCompanySearchSelections}
            limit={limit ?? 1}
          />
        </ModalBody>
      );
    }

    if (shouldUseBreadcrumbTree) {
      return (
        <BreadcrumbTreeFilter
          selectionListIds={sortedGeoSelectionListIds}
          initialSelections={selections}
          branches={sortedGeoSelectionListIds.map(
            (listName: SelectionCategories) => startCase(listName)
          )}
          maxSelections={limit}
          onSubmit={handleBreadcrumbTreeSubmit}
          onClose={onClose}
          setNumSelections={setNumBreadcrumbTreeSelections}
        />
      );
    }

    return (
      <ModalBody py="16px">
        <Tree
          forwardedRef={treeRef}
          selectionLists={normalizedSelectionLists}
          limit={limit}
          setTempSelections={(s) => {
            setSelections(s);
          }}
          defaultSelectedItemIds={Object.keys(initialSelections)}
          height={315}
          nestingTreeType={isNested ? TreeType.BREADCRUMB_NESTED : undefined}
          selectionListParentMap={REPORT_BUILDER_SELECTION_LIST_PARENT_MAP}
        />
      </ModalBody>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      initialFocusRef={searchInputRef}
    >
      <ModalOverlay />
      <ModalContent minHeight="530px">
        <ModalHeader
          borderBottom="1px solid #E5EBF1"
          fontSize="17px"
          fontWeight="600"
          py="12px"
        >
          {header}
        </ModalHeader>
        <ModalCloseButton size="sm" />
        {renderContent()}
        {!shouldUseBreadcrumbTree && (
          <ModalFooter borderTop="1px solid #E5EBF1">
            <ActionModalControlPanel
              submitText={submitText}
              onClose={onClose}
              onCancel={onCancel}
              onSubmit={handleSubmit}
              onReset={handleReset}
              submitIsDisabled={isSubmitDisabled}
            />
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};
