import { Flex } from '@chakra-ui/layout';
import { useOutsideClick } from '@chakra-ui/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { SelectionCategories, TreeItem } from '../../../engine/filters.model';
import { PopoutTree } from '../../PopoutTree';
import { useSelectionListsValidated } from '../../../engine/filters.engine';
import { nestSelectionLists } from '../../tree';
import styles from './NewSegmentTreeForm.module.css';
import { SelectFooter } from '../select-footer';
import { convertSelectionToTreeItems } from '../utils';

type NewSegmentTreeFormProps = {
  selectionListIds: SelectionCategories[];
  setTempTreeItems: (treeItems: Record<string, TreeItem>) => void;
  onClickBack: () => void;
  onClickAddSegment: () => void;
  onClose: () => void;
};

export const NewSegmentTreeForm = ({
  selectionListIds,
  setTempTreeItems,
  onClickBack,
  onClickAddSegment,
  onClose,
}: NewSegmentTreeFormProps) => {
  const selectionLists = useSelectionListsValidated(selectionListIds);

  const nestedSelectionTree = useMemo(
    () => nestSelectionLists(selectionLists),
    [selectionLists]
  );

  const [selections, setSelections] = useState<string[]>([]);
  const onChange = (value: string[]) => {
    setSelections(value);
  };
  useEffect(() => {
    const treeSelections = convertSelectionToTreeItems({
      selections,
      selectionLists,
    });

    setTempTreeItems(treeSelections);
  }, [selections, selectionLists, setTempTreeItems]);

  const handleClearSelections = () => {
    setSelections([]);
  };

  const componentRef = useRef<HTMLDivElement>(null);
  useOutsideClick({ ref: componentRef, handler: onClose });

  return (
    <Flex direction="column" gap="4" ref={componentRef}>
      <PopoutTree
        data={nestedSelectionTree}
        className={styles.popoutTree}
        value={selections}
        onChange={onChange}
      />
      <SelectFooter
        onClearSelections={handleClearSelections}
        onClose={onClickBack}
        onAdd={onClickAddSegment}
        addLabel="Add Segment"
      />
    </Flex>
  );
};
