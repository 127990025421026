import { useRef } from 'react';
import { useEffect } from 'react';

export const useOnClickOutsidePopover = (handler: () => void) => {
  const componentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target as Node) &&
        !(event.target as HTMLElement).closest('.chakra-popover__popper')
      ) {
        handler();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handler]);

  return componentRef;
};
