import { PopoverHeader, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { ScreenerFilterOption } from './types';
import { FilterSelectDropdown } from './filter-select-dropdown';
import { SingleValue } from 'chakra-react-select';
import { useOnClickOutsidePopover } from '../utils/useOnClickOutsidePopover';

export const FilterSelectDropdownWrapper = ({
  filterOptions,
  selectedFilter,
  setSelectedFilter,
  handleClose,
  children,
  isEditingFilter,
}: {
  filterOptions: ScreenerFilterOption[];
  selectedFilter: ScreenerFilterOption;
  setSelectedFilter: (selected: SingleValue<ScreenerFilterOption>) => void;
  handleClose: () => void;
  children: ReactNode;
  isEditingFilter: boolean;
}) => {
  const componentRef = useOnClickOutsidePopover(handleClose);

  return (
    <div ref={componentRef}>
      <PopoverHeader
        marginBottom={2}
        padding={0}
        fontWeight="semibold"
        borderBottomWidth={0}
      >
        {!isEditingFilter && (
          <FilterSelectDropdown
            filterOptions={filterOptions}
            selectedFilter={selectedFilter}
            onChangeSelectedFilter={setSelectedFilter}
          />
        )}
        {!!isEditingFilter && (
          <Text fontSize="sm" color="gray.500">
            {selectedFilter?.label}
          </Text>
        )}
      </PopoverHeader>
      {children}
    </div>
  );
};
