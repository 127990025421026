import { POSTING_SOURCE_IDS, User } from '@revelio/data-access';
import { Option } from '../../selection-list/selection-list-select';

const WEBSITE_POSTINGS_SOURCE_OPTION = {
  value: `${POSTING_SOURCE_IDS.linkup}`,
  label: 'Website Postings (other)',
};
export const UNIFIED_POSTING_SOURCE_OPTION = {
  value: `${POSTING_SOURCE_IDS.unified}`,
  label: 'Unified (COSMOS)',
};
const OPTIONS: Option[] = [
  UNIFIED_POSTING_SOURCE_OPTION,
  {
    value: `${POSTING_SOURCE_IDS.revelioWebsitePostings}`,
    label: 'Revelio Labs Website Postings',
  },
  WEBSITE_POSTINGS_SOURCE_OPTION,
  {
    value: `${POSTING_SOURCE_IDS.linkedIn}`,
    label: 'LinkedIn',
  },
  {
    value: `${POSTING_SOURCE_IDS.indeed}`,
    label: 'Indeed',
  },
];

export const getPostingsSourceOptions = (loggedInUser: User) => {
  let postingSourceOptions = OPTIONS;
  const userHasWebsitePostings = loggedInUser.linkup_postings;
  if (!userHasWebsitePostings) {
    postingSourceOptions = postingSourceOptions.filter(
      (option) => option.value !== WEBSITE_POSTINGS_SOURCE_OPTION.value
    );
  }
  const userHasUnifiedPostings = loggedInUser.unified_postings;
  if (!userHasUnifiedPostings) {
    postingSourceOptions = postingSourceOptions.filter(
      (option) => option.value !== UNIFIED_POSTING_SOURCE_OPTION.value
    );
  }

  return postingSourceOptions;
};
