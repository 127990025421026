import { groups } from 'd3-array';
import { SelectionListItems, TreeItem } from '../../../engine/filters.model';
import { getSelectionListItem } from '../../tree';

type ConvertSelectionToTreeItems = {
  selections: string[];
  selectionLists: SelectionListItems[];
};
export const convertSelectionToTreeItems = ({
  selections,
  selectionLists,
}: ConvertSelectionToTreeItems): Record<string, TreeItem> => {
  const selectedItems = selections
    .map((selectionId) =>
      getSelectionListItem({ id: selectionId, selectionLists })
    )
    .filter((item) => item !== null);
  const treeItems: TreeItem[] = selectedItems.map((item) => ({
    id: `${item.selectionListId}.${item.id}`,
    selectionListId: item.selectionListId,
    item: {
      id: item.id,
      shortName: item.shortName,
      longName: item.longName,
      label: item.longName,
    },
    children: [],
  }));

  const filters = groups(treeItems, (d) => d.id);

  return filters.reduce<Record<string, TreeItem>>((acc, [key, values]) => {
    const value = values[0];
    if (!value) return acc;
    return { ...acc, [key]: value };
  }, {});
};
