import { PageTitles, Views } from '@revelio/core';
import {
  ScreenerFilterProvider,
  SelectionCategories,
  useTabMeta,
} from '@revelio/filtering';
import { Screener } from '../../../screener/screener';
import { generateFilterOptions } from '../../utils/generateFilterOptions';
import { ScreenerDimension, View } from '@revelio/data-access';
const title = [PageTitles.GEOGRAPHY, PageTitles.SCREENER];

export const GeographyScreener = () => {
  const filterOptions = generateFilterOptions(
    SelectionCategories.REGION,
    'Geography'
  );

  useTabMeta({
    savedSetView: View.GeographyScreener,
    view: Views.SCREENER,
  });

  return (
    <ScreenerFilterProvider>
      <Screener
        title={title}
        filterOptions={filterOptions}
        view={ScreenerDimension.Geography}
        sortingDisabledColumns={['Metro Area']}
        savedSetsView={View.GeographyScreener}
      />
    </ScreenerFilterProvider>
  );
};

export default GeographyScreener;
