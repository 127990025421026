import {
  AddUserMutationVariables,
  Client,
  GetAllClientUsersQuery,
  GetAllClientUsersQueryVariables,
  GetAllUsersQuery,
  GetAllUsersQueryVariables,
  KeyValueResp,
  Maybe,
  MetadataKey,
  PipelineType,
  PostingSource,
  ReportTypeEnum,
  Role,
  Tab,
  User,
} from '@revelio/data-access';
import { OptionBase } from 'chakra-react-select';
import { PipelineSelectOption, ReportSelectOption } from '../client-form';
import { useCallback, useState } from 'react';
import { UserFormValues } from '../user-form';
import { useNavigate } from 'react-router';
import { get, isUndefined, startCase } from 'lodash';
import copy from 'copy-to-clipboard';
import { useMutation, useQuery } from 'urql';
import { AddUserMutation, AllClientUsersQuery } from '../userOperations';
import { useToast } from '@chakra-ui/react';
import { DASH_META_ROOT, getAuthDashMetaCsrfToken } from '@revelio/auth';
import { SENTIMENT_DATASETS } from '../../deliverables/deliverables.model';

export const clipBoardText = (username: string, password?: string | null) =>
  `dashboard.reveliolabs.com\nUsername: ${username}\nPassword: ${password}`;

export const tabOptions = [
  { label: 'Compositions', value: Tab.Overview, default: true },
  { label: 'Transitions', value: Tab.Transition, default: true },
  { label: 'Postings', value: Tab.Posting, default: true },
  { label: 'Sentiment', value: Tab.Sentiment, default: true },
  { label: 'Sentiment (with overtime)', value: Tab.SentimentV1 },
  { label: 'Screener', value: Tab.Screener, default: true },
  { label: 'Activity Analysis', value: Tab.JobAnalysis },
  { label: 'Talent Discovery', value: Tab.TalentDiscovery, default: true },
  { label: 'Taxonomy Visualizer', value: Tab.TaxonomyVisualizer },
  { label: 'Data Dictionary', value: Tab.DataDictionary, default: true },
  { label: 'Deliverables', value: Tab.Deliverables },
  { label: 'Data Enrichment', value: Tab.DataEnrichment },
  { label: 'Pay', value: Tab.Compensation, default: true },
  { label: 'Report Builder', value: Tab.Reports },
  { label: 'Resume Enrichment', value: Tab.ResumeParsing },
  {
    label: 'Entity Overview',
    value: Tab.EntitySummary,
  },
];
export const defaultTabOptions = tabOptions.filter((i) => i.default === true);

export type PipelineSelectOptions = PipelineSelectOption[];

export type ReportSelectOptions = ReportSelectOption[];

export const reportOptionsLookup = {
  [ReportTypeEnum.MarketTightness]: 'Market Conditions',
  [ReportTypeEnum.Compensation]: 'Compensation',
  [ReportTypeEnum.CompensationBenchmarking]: 'Compensation Benchmarking',
  [ReportTypeEnum.JobComparison]: 'Job Comparison',
  [ReportTypeEnum.GenderDiversity]: 'Gender Diversity',
  [ReportTypeEnum.Prestige]: 'Prestige',
  [ReportTypeEnum.AiAdoptionExposure]: 'AI Adoption',
  [ReportTypeEnum.MarketDiscovery]: 'Market Discovery',
  [ReportTypeEnum.MarketIntelligence]: 'Market Intelligence',
  [ReportTypeEnum.DynamicCompanyReport]: 'Dynamic Company Report',
  [ReportTypeEnum.MarketTightnessAlt]: 'Market Conditions (No Demographics)',
};

export const getReportOptions = (clientData: Client) => {
  const clientReportsConfig = get(clientData, 'reports_configuration');

  const defaultReports = get(clientReportsConfig, 'defaults');

  const allReportTypes: ReportSelectOption[] =
    clientReportsConfig?.all_report_types
      .map((reportType: string) => {
        return {
          label: get(reportOptionsLookup, reportType, startCase(reportType)),
          value: JSON.stringify([reportType]),
          default: !!defaultReports?.includes(reportType as ReportTypeEnum),
          isFixed: !!defaultReports?.includes(reportType as ReportTypeEnum),
        };
      })
      .sort((a: ReportSelectOption, b: ReportSelectOption) => {
        if (!isUndefined(a.isFixed) && !isUndefined(b.isFixed)) {
          return a.isFixed > b.isFixed ? -1 : 1;
        }

        return 1;
      }) ?? [];

  return allReportTypes;
};

export const COMPANY_INFO_DATASET_OPTION = {
  label: 'Company Mapping',
  value: JSON.stringify([PipelineType.CompanyInfo]),
  default: true,
};
export const pipelineOptions: PipelineSelectOptions = [
  {
    label: 'Workforce Dynamics',
    value: JSON.stringify([PipelineType.WfDynam, PipelineType.SkillDynam]),
    default: true,
  },
  COMPANY_INFO_DATASET_OPTION,
  {
    label: 'Individual Profiles',
    value: JSON.stringify([PipelineType.Individual]),
    default: true,
  },
  {
    label: 'Transitions',
    value: JSON.stringify([PipelineType.Transition]),
    default: true,
  },
  {
    label: 'Sentiment',
    value: JSON.stringify(SENTIMENT_DATASETS),
    default: true,
  },
  {
    label: 'Layoffs',
    value: JSON.stringify([PipelineType.Layoffs]),
    default: true,
  },
  {
    label: 'Job Postings (Indeed)',
    value: JSON.stringify([PostingSource.Indeed]),
  },
  {
    label: 'Job Postings (LinkedIn)',
    value: JSON.stringify([PostingSource.Mixrank]),
    default: true,
  },
  {
    label: 'Job Postings (LinkUp)',
    value: JSON.stringify([PostingSource.Linkup]),
  },
  {
    label: 'Job Postings (Unified)',
    value: JSON.stringify([PostingSource.Unified]),
  },
];

export const defaultPipelineOptions: PipelineSelectOptions =
  pipelineOptions.filter((i) => i.default === true);

export const serializeOptions = (
  options: { label: string; value: string }[]
): {
  pipelineTypes: PipelineType[];
  postingSources: PostingSource[];
  reportTypes: ReportTypeEnum[];
} => {
  const pipelineTypes: PipelineType[] = [];
  const postingSources: PostingSource[] = [];
  const reportTypes: ReportTypeEnum[] = [];

  options.forEach((option) => {
    // Parse the value from string to an array of string enums
    let values: string[];
    try {
      values = JSON.parse(option.value);
    } catch (e) {
      console.error('Parsing error for option:', option);
      return;
    }

    // Add to the respective array based on whether the value is a PostingSource or PipelineType
    values.forEach((value) => {
      if (Object.values(PostingSource).includes(value as PostingSource)) {
        postingSources.push(value as PostingSource);
      } else if (Object.values(PipelineType).includes(value as PipelineType)) {
        pipelineTypes.push(value as PipelineType);
      } else {
        reportTypes.push(value as ReportTypeEnum);
      }
    });
  });

  if (postingSources.length > 0) {
    pipelineTypes.push(PipelineType.Posting, PipelineType.PostingsIndividual);
  }

  return { pipelineTypes, postingSources, reportTypes };
};

export const mapServerReportResponseToSelectOptions = (
  reportTypes: ReportTypeEnum[],
  allOptions: ReportSelectOptions
) => {
  // Helper function to parse the JSON stringified values
  const parseValue = (value: string): ReportTypeEnum[] => {
    try {
      return JSON.parse(value);
    } catch (e) {
      console.error('Failed to parse value: ', value);
      return [];
    }
  };

  // Function to check if the parsed value from our options includes any of the server response types
  const includesServerResponse = (
    parsedValues: ReportTypeEnum[],
    serverResponse: ReportTypeEnum[] | undefined
  ) => {
    return serverResponse
      ? parsedValues.some((value) => serverResponse.includes(value))
      : false;
  };

  // If server responses are undefined, default them to empty arrays
  const safeReportTypes = reportTypes || [];

  // Map the server response to select options
  const selectedOptions: ReportSelectOptions = allOptions.filter((option) => {
    const parsedValues = parseValue(option.value);

    return includesServerResponse(parsedValues, safeReportTypes);
  });

  return selectedOptions;
};

export const mapServerResponseToSelectOptions = (
  pipelineTypes: PipelineType[] | undefined,
  postingSources: PostingSource[] | undefined,
  allOptions: PipelineSelectOptions
): PipelineSelectOptions => {
  // Helper function to parse the JSON stringified values
  const parseValue = (value: string): (PipelineType | PostingSource)[] => {
    try {
      return JSON.parse(value);
    } catch (e) {
      console.error('Failed to parse value: ', value);
      return [];
    }
  };

  // Function to check if the parsed value from our options includes any of the server response types
  const includesServerResponse = (
    parsedValues: (PipelineType | PostingSource)[],
    serverResponse: (PipelineType | PostingSource)[] | undefined
  ) => {
    return serverResponse
      ? parsedValues.some((value) => serverResponse.includes(value))
      : false;
  };

  // If server responses are undefined, default them to empty arrays
  const safePipelineTypes = pipelineTypes || [];
  const safePostingSources = postingSources || [];

  // Map the server response to select options
  const selectedOptions: PipelineSelectOptions = allOptions.filter((option) => {
    const parsedValues = parseValue(option.value);
    return (
      includesServerResponse(parsedValues, safePipelineTypes) ||
      includesServerResponse(parsedValues, safePostingSources)
    );
  });

  return selectedOptions;
};

export const USER_ROLE_OPTION = {
  label: 'User',
  value: Role.ClientUser,
  default: true,
  isFixed: true,
};
export const roleOptions = (userRole?: Role | null) => {
  switch (userRole) {
    case Role.RevelioAdmin:
    case Role.SuperAdmin:
      return [
        USER_ROLE_OPTION,
        {
          label: 'Client Admin',
          value: Role.ClientAdmin,
          default: false,
          isFixed: false,
        },
        {
          label: 'Revelio Admin',
          value: Role.RevelioAdmin,
          default: false,
          isFixed: false,
        },
        // it will set the admin to whatever type of admin the user is.
      ];
    case Role.ClientAdmin:
    case Role.ClientUser:
    default:
      return [USER_ROLE_OPTION];
  }
};

export const generatePassword = (
  setPassword: (newPassword: string) => void
) => {
  const length = 12;
  const charset =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let retVal = '';
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  setPassword(retVal);
};

export interface OptionValues extends OptionBase {
  label: string;
  value: string;
}

// used to reset modal state on open/close
export const useResettableState = (initialKey = 0): [number, () => void] => {
  const [key, setKey] = useState(initialKey);

  const reset = useCallback(() => {
    setKey((prevKey) => prevKey + 1);
  }, []);

  return [key, reset] as [number, () => void];
};

interface SubmitUserParams {
  isClientAdmin: boolean;
  loggedInUser: User;
  onClose?: () => void;
}
interface AddUserParams {
  formData: UserFormValues;
  specifiedClient?: Client;
}
export const useSubmitUser = ({
  isClientAdmin,
  loggedInUser,
  onClose,
}: SubmitUserParams) => {
  const [{ fetching: addUserLoading }, add] = useMutation(AddUserMutation);
  const toast = useToast();
  const navigate = useNavigate();

  const csrfToken = getAuthDashMetaCsrfToken();

  const addUser = async ({ formData, specifiedClient }: AddUserParams) => {
    const client = (() => {
      if (specifiedClient?.client_name) {
        return specifiedClient.client_name;
      }

      if (isClientAdmin && typeof loggedInUser.client_name === 'string') {
        return loggedInUser.client_name;
      }

      return formData.client_group.value;
    })();
    const expirationDate = new Date();
    expirationDate.setDate(new Date().getDate() + 30);
    const formattedExpirationDate = expirationDate.toISOString();
    const metadata =
      specifiedClient && !specifiedClient.live
        ? [{ key: MetadataKey.ExpirationDate, value: formattedExpirationDate }]
        : undefined;

    const formatData: AddUserMutationVariables = {
      active: get(formData, 'active', true),
      name: formData.name,
      email: formData.email,
      client_name: client,
      role: formData.role.value,
      password: formData.password,
      metadata,
    };
    copy(clipBoardText(formatData.email, formatData.password));
    return add(formatData).then(async (result) => {
      if (result.error) {
        const errorMessage = result.error?.message;
        if (errorMessage?.includes('seat limit')) {
          toast({
            title: 'User not added',
            description: `The seat limit has been reached${
              isClientAdmin ? '.' : 'for this client group.'
            }`,
            status: 'error',
            duration: 4000,
            isClosable: true,
            position: 'top-right',
          });
        } else if (errorMessage?.includes('code = AlreadyExists')) {
          toast({
            title: 'User not added',
            description: `User ${formData?.email} already exists`,
            status: 'error',
            duration: 10000,
            isClosable: true,
            position: 'top-right',
          });
        } else {
          return;
        }
      } else {
        onClose
          ? (() => {
              onClose();
              toast({
                title: 'User added!',
                status: 'success',
                duration: 4000,
                isClosable: true,
                position: 'top-right',
              });
            })()
          : navigate(isClientAdmin ? '/manage' : '/admin', {
              state: { addUserToast: true },
            });
        try {
          const response = await fetch(
            `${DASH_META_ROOT}/api/users/zoho/newslettersignup`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'x-surf-token-dash-meta': csrfToken || '',
              },
              body: JSON.stringify({
                name: formatData.name,
                email: formatData.email,
              }),
              credentials: 'include',
            }
          );
          const data = await response.json();
          return data;
        } catch (error) {
          return error;
        }
      }
    });
  };

  return { addUser, addUserLoading };
};

export const useAllClientUsersQuery = (clientName: string) => {
  const [result, executeQuery] = useQuery<
    GetAllClientUsersQuery | GetAllUsersQuery,
    GetAllClientUsersQueryVariables | GetAllUsersQueryVariables
  >({
    query: AllClientUsersQuery,
    variables: { clientName },
    pause: !clientName,
    requestPolicy: 'network-only',
  });

  const refetch = () => {
    if (clientName) {
      executeQuery({ requestPolicy: 'network-only' });
    }
  };

  return { result, refetch };
};

export const getMetadataValue = (
  metadata: Maybe<Array<Maybe<KeyValueResp>>> | undefined,
  key: MetadataKey
) => {
  return metadata?.find((metaData) => metaData?.key === key)?.value;
};
