import {
  TalentDiscoveryV1_5Filter,
  TalentDiscoveryV1_5Tags,
} from '@revelio/data-access';
import { SelectionCategories, TDFilterNameOverride } from '@revelio/filtering';
import { FILTER_OPTIONS } from '../filters/filter-configuration-options';
import {
  TalentDiscoveryFilterState,
  TalentDiscoveryRangeFilterState,
  TalentDiscoveryFreeTextState,
  TalentDiscoverySchoolSearchFilterState,
  TalentDiscoveryTreeFilterState,
} from '../filters/td-filter-reducer';
import {
  isTreeFilterState,
  isSchoolSearchFilterState,
  isFreeTextFilterState,
  isCompanySearchFilterState,
  isRangeFilterState,
} from '../filters/types';
import { fetchSubsidiaries } from '../../deliverables/company-selection/subsidiary-mapping/subsidiary-mapping.api';

type TalentDiscoveryRequestData =
  | {
      current: boolean;
      ids: number[];
      non_current: boolean;
    }
  | number[];

export const serialiseTalentDiscoveryFilters = async (
  filters: TalentDiscoveryFilterState['filters'],
  skipSubsidiaries = false
): Promise<TalentDiscoveryV1_5Filter> => {
  const serialisedFiltersWithoutCompany = (
    filters?.filter((f) => !isCompanySearchFilterState(f)) || []
  ).reduce((currentFilters, filter) => {
    if (isTreeFilterState(filter)) {
      if (filter.name === SelectionCategories.SKILL) {
        return serialiseSkillTreeFilter(filter, currentFilters);
      }
      return serialiseGenericTreeFilter(filter, currentFilters);
    }

    if (isSchoolSearchFilterState(filter)) {
      return serialiseSchoolFilter(filter, currentFilters);
    }

    if (isFreeTextFilterState(filter)) {
      return serialiseFreeTextFilter(filter, currentFilters);
    }

    if (isRangeFilterState(filter)) {
      return serialiseRangeFilter(filter, currentFilters);
    }

    return currentFilters;
  }, {} as TalentDiscoveryV1_5Filter);

  const companyFilters = filters?.filter(isCompanySearchFilterState) || [];
  if (companyFilters.length === 0) {
    return serialisedFiltersWithoutCompany;
  }

  const singleCompanyFilter = companyFilters[0];
  const companySelectedIds = Object.keys(
    singleCompanyFilter?.companyResultItems || {}
  ).map(Number);

  const serialisedFiltersWithCompanyNoSubsidiaries = {
    ...serialisedFiltersWithoutCompany,
    company: {
      ids: companySelectedIds,
      current: singleCompanyFilter.isCurrent,
      non_current: !singleCompanyFilter.isCurrent,
    },
  };

  if (skipSubsidiaries) {
    return serialisedFiltersWithCompanyNoSubsidiaries;
  }

  const companyIdsWithSubsidiaries = await Promise.all(
    companySelectedIds.map((id) => fetchCompanySubsidiaries(id))
  );

  const deduplicatedCompanyIdsWithSubsidiaries = Array.from(
    new Set(companyIdsWithSubsidiaries.flat())
  );

  return {
    ...serialisedFiltersWithCompanyNoSubsidiaries,
    company: {
      ...serialisedFiltersWithCompanyNoSubsidiaries.company,
      ids: deduplicatedCompanyIdsWithSubsidiaries,
    },
  };
};

const fetchCompanySubsidiaries = async (companyId: number) => {
  try {
    const subsidiaries = await fetchSubsidiaries(companyId, 'all-subsidiaries');
    const subsidiaryIds = subsidiaries.map((sub) => sub.rcid);
    // Include the parent company ID
    return [companyId, ...subsidiaryIds];
  } catch (error) {
    console.error(
      `Error fetching subsidiaries for company ID ${companyId}:`,
      error
    );
    // Return the parent company ID at least
    return [companyId];
  }
};

const SKILL_GRAULARITY_LABELS = {
  '75': TalentDiscoveryV1_5Tags.SkillsK75,
  '700': TalentDiscoveryV1_5Tags.SkillsK700,
  '3000': TalentDiscoveryV1_5Tags.SkillsK3000,
};
const serialiseSkillTreeFilter = (
  filter: TalentDiscoveryTreeFilterState,
  currentFilters: TalentDiscoveryV1_5Filter
): TalentDiscoveryV1_5Filter => {
  const skillFilters = Object.values(filter.treeItems || {}).map((item) => ({
    dim: SKILL_GRAULARITY_LABELS[
      item.selectionListId.split(
        'skill_k'
      )[1] as keyof typeof SKILL_GRAULARITY_LABELS
    ] as TalentDiscoveryV1_5Tags,
    id: Number(item?.item?.id),
  }));

  return {
    ...currentFilters,
    skills: [...(currentFilters.skills || []), skillFilters],
  };
};

const serialiseGenericTreeFilter = (
  filter: TalentDiscoveryTreeFilterState,
  currentFilters: TalentDiscoveryV1_5Filter
): TalentDiscoveryV1_5Filter => {
  return Object.values(filter.treeItems || {}).reduce((filters, treeItem) => {
    const filterKey =
      TDFilterNameOverride[treeItem.selectionListId] ||
      treeItem.selectionListId;
    const itemId = Number(treeItem.item?.id);

    if (isNaN(itemId)) {
      return filters;
    }

    if (filter.isCurrent === undefined) {
      const existingIds = (filters[filterKey] as number[]) || [];
      return { ...filters, [filterKey]: [...existingIds, itemId] };
    }

    const existingData = (filters[filterKey] as TalentDiscoveryRequestData) || {
      current: filter.isCurrent,
      non_current: !filter.isCurrent,
      ids: [],
    };

    const currentIds = Array.isArray(existingData)
      ? existingData
      : existingData.ids;

    return {
      ...filters,
      [filterKey]: {
        ...existingData,
        ids: [...currentIds, itemId],
      },
    };
  }, currentFilters);
};

const serialiseSchoolFilter = (
  filter: TalentDiscoverySchoolSearchFilterState,
  currentFilters: TalentDiscoveryV1_5Filter
): TalentDiscoveryV1_5Filter => {
  const schoolIds = Object.values(filter.schoolResultItems).map((school) =>
    Number(school.rsid)
  );

  return {
    ...currentFilters,
    rsid: schoolIds,
  };
};

const serialiseFreeTextFilter = (
  filter: TalentDiscoveryFreeTextState,
  currentFilters: TalentDiscoveryV1_5Filter
): TalentDiscoveryV1_5Filter => {
  const filterKey = filter.name === 'keywords' ? 'free_texts' : filter.name;
  const filterOption = FILTER_OPTIONS.find(
    (option) => option.value === filter.name
  );

  if (filterOption?.supportsMultiple) {
    const existingTexts = currentFilters[filterKey] || [];
    return {
      ...currentFilters,
      [filterKey]: [...existingTexts, filter.text],
    };
  }

  return {
    ...currentFilters,
    [filterKey]: filter.text[0],
  };
};

const serialiseRangeFilter = (
  filter: TalentDiscoveryRangeFilterState,
  currentFilters: TalentDiscoveryV1_5Filter
): TalentDiscoveryV1_5Filter => {
  const currentStatus =
    filter.isCurrent !== undefined
      ? {
          current: filter.isCurrent,
          non_current: !filter.isCurrent,
        }
      : {};

  return {
    ...currentFilters,
    [filter.name]: {
      start_value: filter.start_value ?? 0,
      end_value: filter.end_value ?? 36445000,
      ...currentStatus,
    },
  };
};
